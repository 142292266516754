import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Validators, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckboxComponent implements OnInit {
  @Input() field: any;
  @Input() group: any;
  objectKeys = Object.keys;

  constructor(
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this._addValidation(this.field);
  }

  _addValidation = (field) => {
    if (field.required) {
      field['validation'] = { name: 'required', message: (field.localeText && field.localeText.required) ? field.localeText.required : this.translate.instant('REQUIRED_FIELD'), validator: this._validateCheckbox };
    }
  };

  _validateCheckbox = (controller: AbstractControl) => {};

  _trackByFn = (index) =>{
    return index;
  }
}
