<div class="d-flex header">
  <div class="flex-fill img-logo">
    <img src="assets/Icons/bildungs-logo.svg" alt="img" class="logo" />
  </div>
</div>

<section *ngIf="waiting">
  <div class="text-center mt-4">
    <h6 class="header-label">{{ "PROFILE_UPLOAD.REDIRECT" | translate }}</h6>
  </div>
</section>

<div *ngIf="snapNSmileSuccess">
  <div class="text-center mt-4">
    <h6 class="header-label">{{ userDetails.customFields.snap_n_smile_status | translate }}</h6>
  </div>
  <div class="text-center">
    <button class="submit-btn status_btn" type="button" (click)="backToProfile()">
      {{ "PROFILE_UPLOAD.TO_PROFILE" | translate }}
    </button>
  </div>
  <div class="text-center">
    <button class="submit-btn status_btn" type="button" (click)="redirectToAtlassian()">
      {{ "PROFILE_UPLOAD.TO_ATLASSIAN" | translate }}
    </button>
  </div>
</div>

<div *ngIf="snapNSmileFailure">
  <div class="text-center mt-4">
    <h6 class="header-label">{{ userDetails.customFields.snap_n_smile_status | translate }}</h6>
  </div>
  <div class="text-center">
    <button class="submit-btn status_btn" type="button" (click)="reInvoiceValidation()">
      {{ "FOURTH_PAGE.BUTTON_ID_VALIDATOR" | translate }}
    </button>
    <button class="submit-btn status_btn" type="button" (click)="backToProfile()">
      {{ "PROFILE_UPLOAD.TO_PROFILE" | translate }}
    </button>
  </div>
</div>
