<div [formGroup]="group">
  <label>{{field?.localeText?.name}}</label>
  <textarea [formControlName]="field?.fieldKey" [placeholder]="field?.localeText.name"
    [required]="field?.required" [readonly]="field?.readOnly"></textarea>
  <ng-container *ngFor="let validation of field?.validation;" ngProjectAs="span">
    <span class="errMsg ft-12" *ngIf="group.get(field?.fieldKey).hasError(validation?.name)">
      {{validation?.message | translate}}
    </span>
  </ng-container>
</div>
