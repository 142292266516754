<div class="row footer_container">
  <div class="col-lg-10 col-md-6 col-12 ft-12 footer-nav hidden-xs">
    <div *ngIf="lang === 'de'; else englishLinks1">
      <div *ngFor="let data of footerLinksDe" class="cursor">
        <a [href]="data.link" class="footer-color">
          {{ data.label | translate }}
        </a>
      </div>
    </div>
    <ng-template #englishLinks1>
      <ng-container>
        <div>
          <div *ngFor="let data of footerLinksEn" class="cursor">
            <a [href]="data.link" class="footer-color">
              {{ data.label | translate }}
            </a>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </div>

  <div class="col-md-10 col-12 ft-12 footer-nav visible-xs" *ngIf="lang === 'de'; else englishLinks2">
    <div class="row">
      <ng-container *ngFor="let data of footerLinksDe;let i = index;">
        <div
          [ngClass]="{'text-right': i === 0, 'text-center': i != 0 && i != 2, 'text-left': i === 2, 'col-4': i < 3, 'col-12': i >= 3 }"
          style="padding: 4px 0px;">
          <a [href]="data.link" class="footer-color">
            {{ data.label | translate }}
          </a>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-template #englishLinks2>
    <ng-container>
      <div class="col-md-10 col-12 ft-12 footer-nav visible-xs">
        <div class="row">
          <ng-container *ngFor="let data of footerLinksEn;let i = index;">
            <div
              [ngClass]="{'text-right': i === 0, 'text-center': i != 0 && i != 2, 'text-left': i === 2, 'col-4': i < 3, 'col-12': i >= 3 }"
              style="padding: 4px 0px;">
              <a [href]="data.link" class="footer-color">
                {{ data.label | translate }}
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <div class="col-lg-2 pl-0 col-md-5 col-12 footer-lang">
    <app-translate-navigation></app-translate-navigation>
  </div>
</div>