import { Component, OnInit, Input } from '@angular/core';
import { PipeTransform, Pipe } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SharedDataServices } from '../../../services/shared-data.service';
import { RegexUtil } from 'src/app/helpers/regex.helper';

@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
  transform(value, limit): any {
    let keys: any = [];
    for (let key in value) {
      keys.push({ key: key, value: value[key] });
    }
    return keys.splice(0, limit);
  }
}

@Pipe({ name: 'error' })
export class ErrorPipe implements PipeTransform {
  transform(value, name): any {
    return value?.filter(item => item.name == name)[0].message;
  }
}
@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  @Input() field: any;
  @Input() group: any;
  @Input() appearance: any;
  inputType: string = "password";
  isPatternValid: boolean = false;

  constructor(private _sharedDataService: SharedDataServices, private _regex: RegexUtil) { }

  ngOnInit(): void {
    this._sharedDataService._sendFormValue(this.group);
  }

  sendValues(event) {
    this.isPatternValid = this._regex.newEmailPattern.test(event.target.value) ? true : false
    this._sharedDataService._sendFormValue(this.group);

  }

  showPassword() {
    if (this.inputType === 'password') {
      this.inputType = 'text';
    } else {
      this.inputType = 'password';
    }
  }
}
