import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { RegexUtil } from "../../../../helpers/regex.helper";
import { IClientInfo } from "../../../../models/client-info.interface";
import { CidaasService } from "../../../../../service/cidaas.service";
import { SharedDataService } from "../../../../../service/shared-data.service";
import { InputGroup } from "../../../../models/input-group.model";

@Component({
  selector: "app-authenticate",
  templateUrl: "./authenticate.component.html",
  styleUrls: ["./authenticate.component.scss"],
})
export class AuthenticateComponent implements OnInit {
  @Input() options;
  input: InputGroup = {}
  inputGroupOption: any = {input: this.input};
  isLoginBtn: boolean = true;
  password;
  username: string = '';
  requestId: string = '';
  verificationType: string = '';
  verificationType_CamelCase: string = '';
  exchangeId: string = '';
  statusId: string = '';
  push_selected_number: number = 0;
  navigator: any = window.navigator;
  fido2_entity: any;
  usernameType: string = '';
  forgotPwdOption: any = {
    btnText: 'LOGIN.FORGOT_PASSWORD',
    btnColor: 'primary',
  };
  rememberMe: boolean = false;
  isSubmitted: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _dataService: SharedDataService,
    private _cidaasSDK: CidaasService,
    private router: Router,
    private _regex: RegexUtil
  ) { }

  ngOnInit(): void {
    // getting values from query params
    this._initQueryparamValues();
    if (this.options) {
      this._initInputValues(this.options);
    }
    this.getClientInfo();
  }

  // get client information
  getClientInfo(): void {
    this._dataService._getClientInfo().subscribe((data: IClientInfo) => {
      if (data) {
        if (data.is_remember_me_selected) {
          this.rememberMe = true;
        }
      }
    });
  }

  _updateInputValue = (password) => {
    this.password = password;
    this.inputGroupOption.input.error = "";
  };

  _initQueryparamValues = () => {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.username = params['userIdHint'];
      this.requestId = params['requestId'];
      this.usernameType = params['type'];
    });
  };

  _initInputValues = (options: any) => {
    options.subscribe((data) => {
      this.inputGroupOption.input = data["input"];
      this.inputGroupOption.button = data["button"];
      this.isLoginBtn = data["verify"]["exchange_id"] || data["verify"]["status_id"] ? false : true;
      this.verificationType = data["verify"]["method"];
      this.verificationType_CamelCase = data["verify"]["method"].toUpperCase();
      this.exchangeId = data["verify"]["exchange_id"];
      this.statusId = data["verify"]["status_id"];
      this.push_selected_number = data["verify"]["push_selected_number"];
      this.fido2_entity = data["verify"]["fido2_entity"];
      if (
        (!this.exchangeId || this.exchangeId == undefined) &&
        this.verificationType !== "password"
      ) {
        this._verify("AUTHAPP");
      } else if (this.verificationType == 'fido2') {
        this._verify('FIDO2');
      }
    });
  };

  _setCode = (data: any) => {
    if (data && data['value']) this.password = data['value'];
  };

  getDisable() {
    if ((this.inputGroupOption.input.value == '' || this.inputGroupOption.input.value == undefined)) {
      return 'transparent-btn1';
    } else {
      return 'custom_primary_btn1';
    }
  }


  _onSubmit() {

    this.isSubmitted = true;
    if (this.password == "") {
      this.password = this.inputGroupOption.input.value;
    }
    if (this.isSubmitted && this.password) {
      if (this.isLoginBtn) {
        this._login();
      } else {
        if (this.exchangeId) {
          this._verify("CODE");
        } else {
          this._verify("AUTHAPP");
        }
      }
    } else {
      this.inputGroupOption.input.error = 'ERRORS.REQUIRED';
    }
  };

  _login = () => {
    let options = {};
    let usertype = this._checkValueType(this.username);
    if (usertype != "username") {
      options = {
        username: this.username,
        password: this.password,
        requestId: this.requestId,
        rememberMe: this.rememberMe,
      };
    } else {
      options = {
        username: this.username,
        password: this.password,
        requestId: this.requestId,
        rememberMe: this.rememberMe,
        username_type: "custom",
        field_key: "campus_id"
      };
    }
    this._cidaasSDK._loginWithCredentials(options);
  };

  _checkValueType = (value: string) => {
    let mobileRegx = this._regex.mobile;
    let emailRegx = this._regex.email;
    if (mobileRegx.test(value)) {
      return 'mobile';
    } else if (emailRegx.test(value)) {
      return 'email';
    } else {
      return 'username';
    }
  };

  _verify = (type) => {
    if (type == 'CODE') {
      let options = {
        pass_code: this.password,
        requestId: this.requestId,
        exchange_id: this.exchangeId,
        type: this.verificationType,
      };
      this._cidaasSDK
        .authenticateMFAV2(options)
        .then((response) => {
          // console.log(response);
          if (response && response.success) {
            if (
              response.data['exchange_id'] &&
              response.data['sub'] &&
              response.data['status_id']
            ) {
              this._intitiatePasswordlessLogin('CODE', response.data);
            } else {
              this._intitiatePasswordlessLogin('AUTHAPP', response.data);
            }
          } else {
            this.inputGroupOption.input.error = 'ERRORS.INVALID_CODE';
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  _intitiatePasswordlessLogin = (type: string, response: any) => {
    if (type == 'CODE') {
      let options = {
        requestId: this.requestId,
        exchange_id: response['exchange_id']['exchange_id'],
        verificationType: this.verificationType,
        sub: response['sub'],
        status_id: response['status_id'],
        rememberMe: this.rememberMe,
      };
      this._cidaasSDK._passwordlessLogin(options);
    } else if (type == 'AUTHAPP') {
      this._checkStatus(response['status_id']);
    }
  };

  _checkStatus = (status_id: string) => {
    this._cidaasSDK
      ._checkStatusIDStatus(status_id)
      .then((data) => {
        if (data.status != 'AUTHENTICATED') {
          setTimeout(() => {
            this._checkStatus(status_id);
          }, 3000);
        }
        if (data.status == 'AUTHENTICATED') {
          let options = {
            requestId: this.requestId,
            verificationType: this.verificationType,
            sub: data['sub'],
            status_id: status_id,
            rememberMe: this.rememberMe,
          };
          this._cidaasSDK._passwordlessLogin(options);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  backClick() {
    this.router.navigate(['/login'], {
      queryParams: {
        userIdHint: this.username,
        requestId: this.requestId,
        type: this.usernameType
      },
    });
  }

  forgotPassword() {
    this.router.navigate(['password_forgot_init'], {
      queryParams: {
        userIdHint: this.username,
        requestId: this.requestId,
        type: this.usernameType,
      },
    });
  }
}
