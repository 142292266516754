import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subscription } from 'rxjs';
import { SpinnerService } from 'src/service/spinner.service';
import { environment } from '../../../../../../../environments/environment';
import { PictureUploadService } from '../../../../../../../service/picture-upload.service';
import { CompleteRegisterService } from 'src/service/complete-register.service';

@Component({
  selector: 'app-manual-picture-upload',
  templateUrl: './manual-picture-upload.component.html',
  styleUrls: ['./manual-picture-upload.component.scss'],
})
export class ManualPictureUploadComponent implements OnInit, OnDestroy {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  isShowingCropper = false;
  IsImagetriggered = false;
  isPictureUploaded!: boolean;
  pictureTooLarge: boolean = false;
  isShowingPictureUploadedMsg!: boolean;
  isLoading!: boolean;
  requestId: string = '';
  totalHints: number = 7
  campus_card: string = 'false';
  access_token: string;
  imgWidth: number;
  imgHeight: number;
 
  private pictureUploadedSubscription: Subscription = new Subscription();
  private showPictureUploadedMsgSubscription: Subscription = new Subscription();
  private isLoadingSubscription: Subscription = new Subscription();
  constructor(
    private el: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    private pictureUploadService: PictureUploadService,
    private _loader: SpinnerService,
    private complete_register: CompleteRegisterService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.requestId = params['requestId'];
    });

    const urlParams = new URLSearchParams(window.location.hash.substring(1));
    this.access_token = urlParams.get('access_token')
    if(this.access_token){
      this.complete_register.setAccessToken(this.access_token);
    } else{
      this.access_token = localStorage.getItem('access_token');
      this.complete_register.setAccessToken(this.access_token);
    }
    this.campus_card = localStorage.getItem('campus_card'); 
  }

  goToAtlassian() {
    this.uploadPicture();
    setTimeout(() => {
      if (this.isShowingPictureUploadedMsg) {
        window.location.assign(
          environment.atlassian_url
        );
      }
    }, 10);
  }

  goToContinue() {
    localStorage.removeItem('campus_card')
    localStorage.setItem('upload_picture_from_card_lost', 'true');
    this.router.navigate(['/campus-card'], {
      queryParams: {
        requestId: this.requestId,
        token_type: 'token_type',
        access_token: this.access_token
      },
    });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;

    setTimeout(() => {
      const element = this.el.nativeElement.querySelector('.ngx-ic-overlay');

      // Check if the element is found
      if (element) {
        // Get the computed styles
        const styles = window.getComputedStyle(element);

        // Access specific style properties
        let width = styles.getPropertyValue('width');
        let height = styles.getPropertyValue('height');
        width = width.replace("px", "");
        height = height.replace("px", "")
        // this.imgWidth = parseFloat(width);
        this.imgHeight= parseFloat(height);
    }
    }, 10);
  }

  selectPicture(event: Event) {
    this.pictureUploadService.onSelect(event);
    this.imageChangedEvent = event;
  }

  uploadPicture() {
    this._loader._stop();
    this.IsImagetriggered = true;
    var send_email = this.campus_card != 'true';
    this.pictureUploadService.onUpload(this.croppedImage, send_email);
    setTimeout(() => {
      this.getUpdatedProfilePicture();
    },1000);
    
    
  }
  getUpdatedProfilePicture(){
    this.complete_register.getUserInfo().then((response) => {
      if(response && response.sub){
        this.pictureUploadService.getProfilePicture(response.sub).subscribe((image)=>{
          this.createImageFromBlob(image);
        }, (error) => {
          console.log(error)
        });
      }
      
    });
  }
  private createImageFromBlob(image:any) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.croppedImage = reader.result;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }
  imageLoaded() {
    // show cropper
    this.isShowingCropper = true;
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  ngOnInit(): void {
    this.isLoadingSubscription = this.pictureUploadService.isLoading.subscribe(
      (loading) => {
        this.isLoading = loading;
      }
    );

    this.showPictureUploadedMsgSubscription =
      this.pictureUploadService.isShowingUploadedMessage.subscribe(
        (uploadMsg) => {
          this.isShowingPictureUploadedMsg = uploadMsg;
        }
      );

    this.showPictureUploadedMsgSubscription =
      this.pictureUploadService.isShowingCropper.subscribe(
        (uploadMsg) => {
          this.isShowingCropper = uploadMsg;
        }
      );

    this.pictureUploadedSubscription =
      this.pictureUploadService.isPictureUploaded.subscribe((picUploaded) => {
        this.isPictureUploaded = picUploaded;
      });

    this.pictureUploadService.pictureTooLarge.subscribe((picLarge) => {
      this.pictureTooLarge = picLarge;
    });
    if(this.isPictureUploaded){
      this.getUpdatedProfilePicture();
    }
  }
  ngOnDestroy() {
    this.isLoadingSubscription.unsubscribe();
    this.showPictureUploadedMsgSubscription.unsubscribe();
    this.pictureUploadedSubscription.unsubscribe();
  }

  backClick() {
    this.router.navigate(['/complete-register/info-video-ident'], {
      queryParams: {
        requestId: this.requestId,
      },
    });
  }

  getDisable() {
    if (!this.isShowingCropper) {
      return 'transparent-btn1';
    } else {
      return 'custom_primary_btn1';
    }
  }
}
