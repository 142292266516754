import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  view_port: boolean = false;
  constructor(private router: Router) {
    this.onResize();
  }

  ngOnInit(): void {}

  @HostListener('window:resize', ['$event']) onResize(event?) {
    if (window.innerWidth < 768) {
      this.view_port = true;
    } else {
      this.view_port = false;
    }

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }
}
