<div class="input-fields-container"[formGroup]="group">
  <label>{{field.localeText.name}}<span *ngIf="field.required">*</span>:</label>
  <div >
  <div *ngFor="let item of field.localeText.attributes">
    <label>
      <input type="radio" [value]="item.key" [formControlName]="field.fieldKey">
      <span> {{item.value}}</span>
    </label>
  </div>
  <ng-container *ngFor="let validation of field.validation;" ngProjectAs="span">
    <span class="errMsg ft-12" *ngIf="group.get(field.fieldKey).hasError(validation.name) && group.get(field.fieldKey).touched">
      {{validation.message | translate}}
    </span>
  </ng-container>
</div>
</div>