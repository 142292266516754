import { FormGroup, ValidatorFn, AbstractControl, FormArray } from '@angular/forms';

export class CustomValidatorHelper {
  _validateCheckbox(control: AbstractControl) {
    if (!control.value) {
      return { required: true };
    }
    return null;
  }

  _validateCheckboxGroup(minRequired = 1): ValidatorFn {
    return function validate(control: AbstractControl) {
      let formGroup: FormGroup;
      let checked = 0;
      formGroup = control['controls'][0];
      if (formGroup) {
        Object.keys(formGroup['controls']).forEach((key) => {
          const control = formGroup.controls[key];
          if (control.value === true) {
            checked++;
          }
        });
      }
      return checked > 0 ? null : { required: true };
    };
  }

  _validateNoOfDigits(minRequired = 1): ValidatorFn {
    return function validate(control: AbstractControl) {
      let length = control.value.replace(/[^0-9]/g, '').length;
      if (control.value.length >= minRequired) {
        if (length >= minRequired) {
          return null;
        } else {
          return { digitslength: true };
        }
      } else {
        return null;
      }
    };
  }

  _validateNoOfSpecial(minRequired = 1): ValidatorFn {
    return function validate(control: AbstractControl) {
      let length = control.value.replace(/[^!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, '').length;
      if (control.value.length >= minRequired) {
        if (length >= minRequired) {
          return null;
        } else {
          return { speciallength: true };
        }
      } else {
        return null;
      }
    };
  }

  _validateCaseLowerUpper(minRequired = 1): ValidatorFn {
    return function validate(control: AbstractControl) {
      let upperLength = control.value.replace(/[^A-Z]/g, '').length;
      let lowerLength = control.value.replace(/[^a-z]/g, '').length;
      if (control.value.length >= minRequired) {
        if (lowerLength > 0 && upperLength > 0) {
          return null;
        } else {
          return { loweruppercase: true };
        }
      }
      return null;
    };
  }

  _validateMatchField(formcontrolName = 'password'): ValidatorFn {
    return function validate(control: AbstractControl) {
      if (control.parent?.get(formcontrolName)?.value !== control.value) {
        return { matchwith: true };
      }
      return null;
    };
  }
}
