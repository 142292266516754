<div class="mt-3" style="position: relative">
  <div class="has-feedback">
    <p class="input-label">
      {{ options?.input?.label | translate }}
    </p>
    <div class="input-container">
      <input [type]="inputType" class="form-control" [ngClass]="getClass()" name="{{ options?.input?.name }}"
        (keyup)="inputChange($event)" placeholder="{{ options?.input?.placeholder | translate }}" class="form-control"
        [(ngModel)]="options.input.value" (keydown.enter)="emitClick($event)"/>
      <img class="error-icon" *ngIf="options?.input?.error" src="assets/img/icon-error.svg" style="width: 20px;" alt="">
      <button *ngIf="showHidePasswordicon" class="form-cont-feedback show-password-eye" (click)="showPassword()">
        <img class="error-icon" src="assets/img/eye-icon.svg" style="width: 20px;" alt="">
      </button>
    </div>
    <p class="errMsg ft-12">{{ options?.input?.error | translate }}</p>
  </div>
</div>