import { Injectable } from '@angular/core';
import * as CidaasSDK from 'cidaas-javascript-sdk';
import { SharedDataService } from './shared-data.service';
import { ISDKConfig } from '../app/models/sdk-config.interface';
import { IRequestBody } from '../app/models/request-body.interface';
import { IInitiateMFAPayload, IMFAListPayload, IMFAListResponse } from 'src/app/models/mfa.interface';
import { firstTimePwdReset, IHandleResetPwdPayload, ResetPasswordRequest, resetPwdPayload } from 'src/app/models/reset-password-request.interface';

@Injectable({
  providedIn: 'root'
})
export class CidaasService {
  // variable declaration
  private cidaas_sdk: any;
  constructor(private _dataSerivce: SharedDataService) {

  }

  // initialising cidaas sdk
  _initSDK = (options: Pick<ISDKConfig, 'authority'>) => {
    this.cidaas_sdk = new CidaasSDK.WebAuth(options);
  };

  _get() {
    return this.cidaas_sdk;
  }

  // get client info
  _getClientInfo = async (options: Pick<IRequestBody, 'requestId'>) => {
    const response = await this.cidaas_sdk.getClientInfo(options);
    this._dataSerivce._setClientInfo(response.data);
    // this._spinner._stop();
  };

  // get user exist
  _getUserExists = async (options: Partial<IRequestBody>) => {
    const response = await this.cidaas_sdk.userCheckExists(options);
    return response ? response.data : false;
  };

  // get mfa list
  _getMFAList = async (options: Partial<IRequestBody>) => {
    const response = await this.cidaas_sdk.getMFAListV2(options);
    return response.data;
  };

  initiateMFAV2(data: IInitiateMFAPayload): Promise<any> {
    return new Promise((resolve, reject) => {
      this.cidaas_sdk
        .initiateMFAV2(data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (ex) {
          reject(ex);
        });
    });
  }

  initiatePasswordReset(data: ResetPasswordRequest): Promise<any> {
    return new Promise((resolve, reject) => {
      this.cidaas_sdk
        .initiateResetPassword(data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (ex) {
          reject(ex);
        });
    });
  }

  _checkStatusIDStatus = async (status_id: string) => {
    const response = await this.cidaas_sdk.updateSocket(status_id);
    return response.data;
  };

  _loginWithCredentials = async (options) => {
    const response = await this.cidaas_sdk.loginWithCredentials(options);
  };

  logout(options: any) {
    this.cidaas_sdk.logoutUser(options);
  }

  _passwordlessLogin = async (options) => {
    const response = await this.cidaas_sdk.passwordlessLogin(options);
  };


  resetPassword(data: resetPwdPayload) {
    this.cidaas_sdk.resetPassword(data);
  }

  firstTimeChangePassword(data: firstTimePwdReset) {
    this.cidaas_sdk.firstTimeChangePassword(data);
  }

  handleResetPassword(data: IHandleResetPwdPayload) {
    this.cidaas_sdk.handleResetPassword(data);
  }

  // get list of mfas configured
  getMFAList(data: IMFAListPayload): Promise<IMFAListResponse> {
    return new Promise((resolve, reject) => {
      this.cidaas_sdk
        .getMFAListV2(data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (ex) {
          reject(ex);
        });
    });
  }


  authenticateMFAV2(data: any): any {
    return new Promise((resolve, reject) => {
      this.cidaas_sdk
        .authenticateMFAV2(data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (ex) {
          reject(ex);
        });
    });
  }

  cancelMFAV2(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.cidaas_sdk
        .cancelMFAV2(data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (ex) {
          reject(ex);
        });
    });
  }

  _register = async (options, headers): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.cidaas_sdk
        .register(options, headers)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  };

  _progressiveRegister = async (options, headers) => {
    return new Promise((resolve, reject) => {
      this.cidaas_sdk
        .progressiveRegistration(options, headers)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };


  _getRegistrationSetup = async (options) => {
    return new Promise((resolve, reject) => {
      this.cidaas_sdk
        .getRegistrationSetup(options)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  _getMissingFields = async (viewType: string, options: any) => {
    if (viewType != undefined && viewType.toLowerCase() == 'login') {
      return new Promise((resolve, reject) => {
        this.cidaas_sdk
          .getMissingFieldsLogin(options.trackId)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        this.cidaas_sdk
          .getMissingFields(options)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };


  mfaContinue(data: any): any {
    this.cidaas_sdk.mfaContinue(data);
  }

  _loginAfterRegister = async (options) => {
    return new Promise((resolve, reject) => {
      this.cidaas_sdk.loginAfterRegister(options);
    });
  };

}
