import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompleteRegisterService } from '../../../../service/complete-register.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor(public router: Router,
    
    private activatedRoute: ActivatedRoute,
    private complete_register: CompleteRegisterService) {}

  ngOnInit(): void { 
    //TODO to be removed post setting token.
    const urlParams = new URLSearchParams(window.location.hash.substring(1));
    let token = urlParams.get('access_token')
    this.complete_register.setAccessToken(token);
  }

  goToEditProfile(route){
    this.router.navigate([route],{
      relativeTo: this.activatedRoute,
    });
  }
}
