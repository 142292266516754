import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DynamicFormComponent, RemoveFieldsPipe, SearchFieldPipe } from './components/dynamic-form/dynamic-form.component';
import { CheckboxComponent } from './components/util/checkbox/checkbox.component';
import { RadioComponent } from './components/util/radio/radio.component';
import { SelectComponent } from './components/util/select/select.component';
import { InputComponent } from './components/util/input/input.component';
import { TextareaComponent } from './components/util/textarea/textarea.component';
import { DynamicFieldComponent } from './components/dynamic-field/dynamic-field.component';
// import { DateComponent } from './components/util/date/date.component';
import { CustomValidatorHelper } from './helpers/custom-validator.helper';
import { PasswordComponent, KeysPipe, ErrorPipe } from './components/util/password/password.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SharedDataServices } from './services/shared-data.service';
import { ShowHidePasswordDirective } from './directives/show-hide-password.directive';
import { TextAreaComponent } from './components/util/text-area/text-area.component';
import { DynamicFieldRendererComponent } from './components/dynamic-field-renderer/dynamic-field-renderer.component';
import { DateComponent } from './components/util/date/date.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    DynamicFormComponent,
    CheckboxComponent,
    RadioComponent,
    SelectComponent,
    InputComponent,
    TextareaComponent,
    DynamicFieldComponent,
    PasswordComponent,
    KeysPipe,
    ErrorPipe,
    ShowHidePasswordDirective,
    TextAreaComponent,
    DynamicFieldRendererComponent,
    RemoveFieldsPipe,
    SearchFieldPipe,
    DateComponent
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BsDatepickerModule.forRoot()
  ],
  exports: [DynamicFormComponent, DynamicFieldRendererComponent, ShowHidePasswordDirective],
  providers: [CustomValidatorHelper, SharedDataServices],
})
export class DynamicFormModule { }
