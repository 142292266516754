import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit {
  @Input() field: any;
  @Input() group: any;
  
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.field && this.field.fieldDefinition) {
      if (this.field.fieldDefinition.minDate) {
        this.field.fieldDefinition.minDate = new Date(this.field.fieldDefinition.minDate);
      }
      if (this.field.fieldDefinition.maxDate) {
        this.field.fieldDefinition.maxDate = new Date(this.field.fieldDefinition.maxDate);
      }
    }
  }

}
