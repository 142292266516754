<div class="d-flex flex-column col-12 col-lg-6 mx-auto card-content">
  <h4 class="header-label text-title">{{'ACCESS_DETAILS.ACCESS' | translate}}</h4>
  <form>
    <div class="form-row d-flex">
      <div class="form-group col-6 input-section">
        <input type="text" class="form-control" placeholder="{{'ACCESS_DETAILS.OLD_PASSWORD' | translate}}" />
      </div>
    </div>
    <div class="form-row d-flex">
      <div class="form-group col-6 input-section">
        <input type="text" class="form-control" placeholder="{{'ACCESS_DETAILS.NEW_PASSWORD' | translate}}" />
      </div>
      <div class="form-group col-6 input-section">
        <input
          type="text"
          class="form-control"
          placeholder="{{'ACCESS_DETAILS.CONFIRM_NEW_PASSWORD' | translate}}"
        />
      </div>
    </div>
    <div class="d-flex justify-content-end col-12 pr-0">
      <button type="button" class="btn btn-common">{{'ACCESS_DETAILS.CHANGE_PASSWORD' | translate}}</button>
    </div>
  </form>
  <div class="row my-3 px-0">
    <div class="col-12 col-sm-6 col-md-6">
      <ul class="list-unstyled device-content">
        <div class="d-flex justify-content-end col-12">
          <div class="d-flex py-2">
            <i class="fa fa-eye mr-2"></i>
            <i class="fa fa-trash"></i>
          </div>
        </div>
        <li class="media">
          <div class="col-12 d-flex justify-content-between my-0">
            <img
            class="icon-img mr-3 m-auto"
            src="assets/Icons/fido.svg"
            alt="image"
          />
          <div class="media-body m-auto">
            <h6 class="mt-0 mb-1">samsung2.0</h6>
            <p>{{'ACCESS_DETAILS.DEVICE_ID' | translate}} :0815ffe</p>
          </div>
          </div>
         
         
        </li>
      </ul>
    </div>
    <div class="col-12 col-sm-6 col-md-6">
      <ul class="list-unstyled device-content">
        <div class="d-flex justify-content-end col-12">
          <div class="d-flex py-2">
            <i class="fa fa-eye mr-2"></i>
            <i class="fa fa-trash"></i>
          </div>
        </div>
        <li class="media col-12 my-0 d-flex">
          <img
            class="icon-img mr-3 m-auto"
            src="assets/Icons/finger-print.svg"
            alt="image"
          />
          <div class="media-body">
            <h6 class="mt-0 mb-1">lenovo47.11</h6>
            <p>{{'ACCESS_DETAILS.DEVICE_ID' | translate}} :0815ffe</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="d-flex justify-content-end col-12 pr-0">
    <div>
      <button type="button" class="btn btn-common">{{'ACCESS_DETAILS.ADD' | translate}}</button>
    </div>
  </div>
</div>
