<div class="content d-flex flex-column">
  <div *ngIf="inputGroupOption?.input?.type === 'fingerPrint'" class="mx-auto">
    <img src="assets/Icons/finger-print.svg" class="mx-auto middle-image" />
  </div>
  <div *ngIf="inputGroupOption?.input?.type !== 'fingerPrint'">
    <app-input-group [options]="inputGroupOption" (btnEvent)="_onSubmit()" [btnType]="'submit'" [inputType]="'password'"
      (updateUser)="_updateInputValue($event)">
    </app-input-group>
  </div>
  <div class="mb30" style="text-align: end;" *ngIf="verificationType === 'password'">
    <a [routerLink]="" class="pass-link" (click)="forgotPassword()">{{'LOGIN.FORGOT_PASSWORD' |
      translate}}</a>
  </div>

  <div class="bottom-btn">
    <div class="back-btn-align">
      <button type="button" class="navigate-btn" (click)=backClick()>
        <img src="assets/img/left-arrow.svg" alt="">
        {{ "LOGIN.AUTHENTICATION.BACK" | translate }}
      </button>
    </div>
    <div class="back-btn-align">
      <button type="submit" (click)=_onSubmit() [ngClass]="getDisable()"
        [disabled]="(inputGroupOption.input.value == '' || inputGroupOption.input.value == undefined)">
        {{ "LOGIN.AUTHENTICATION.PASSWORD.BUTTON.TEXT" | translate }}
        <img *ngIf="(inputGroupOption.input.value == '' || inputGroupOption.input.value == undefined)"
          src="assets/img/deactive-arrow.svg" alt="">
        <img style="margin-top: 6px;"
          *ngIf="(inputGroupOption.input.value != '' && inputGroupOption.input.value != undefined)"
          src="assets/img/active-arrow.svg" alt="">
      </button>
    </div>
  </div>
</div>