<!-- <app-translate-navigation></app-translate-navigation> -->
<div *ngIf="!showSuccessMessage">
  <h4 class="header-label">{{ "RESET.WELCOME_HEADER" | translate }}</h4>
  <p class="secondary-header-label">
    {{ "RESET.WELCOME_LABEL" | translate }}
  </p>
  <div class="">
    <app-input-group [options]="inputGroupOption" (btnEvent)="onSubmit()" [inputType]="'text'" [btnType]="'submit'"></app-input-group>
  </div>
</div>

<div *ngIf="showSuccessMessage">
  <h4 class="header-label">
    {{ "RESET.RESET_HEADER" | translate }}
  </h4>

  <p class="secondary-header-label" innerHTML="{{'RESET.RESET_HINT1' | translate}}" style="margin-bottom: 15px;">
  </p>

  <p class="secondary-header-label">
    {{ "RESET.RESET_HINT2" | translate }}
  </p>
</div>

<div class="bottom-btn">
  <div class="back-btn-align" *ngIf="!showSuccessMessage; else successpage">
    <button type="button" class="navigate-btn" (click)=backClick()>
      <img src="assets/img/left-arrow.svg" alt="">
      {{ "LOGIN.AUTHENTICATION.BACK" | translate }}
    </button>
  </div>
  <ng-template #successpage>
    <ng-container>
      <div class="back-btn-align">
        <button type="button" class="navigate-btn" (click)=backClick()>
          <img src="assets/img/left-arrow.svg" alt="">
          {{ "LOGIN.AUTHENTICATION.BACK_TO_LOGIN" | translate }}
        </button>
      </div>
    </ng-container>
  </ng-template>
  <div class="back-btn-align" *ngIf="!showSuccessMessage">
    
    <button type="submit" (click)=onSubmit() [ngClass]="getDisable()"
      [disabled]="(inputGroupOption.input.value == '' || inputGroupOption.input.value == undefined)">
      {{ "LOGIN.SUBMIT" | translate }} 
      <img *ngIf="(inputGroupOption.input.value == '' || inputGroupOption.input.value == undefined)"
        src="assets/img/deactive-arrow.svg" alt="">
      <img *ngIf="(inputGroupOption.input.value != '' && inputGroupOption.input.value != undefined)"
        src="assets/img/active-arrow.svg" alt="">

    </button>
  </div>
</div>