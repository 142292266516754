import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedDataService } from 'src/service/shared-data.service';

@Component({
  selector: 'app-translate-navigation',
  templateUrl: './translate-navigation.component.html',
  styleUrls: ['./translate-navigation.component.scss'],
})
export class TranslateNavigationComponent implements OnInit {
  isSelected!: boolean;
  defaultLang: string = 'en';
  @Output() updateLanguage = new EventEmitter();
  selectedLanguage: string = '';
  localnavigator: any = <any>window.navigator;
  language: string;

  constructor(private translate: TranslateService, private dataService: SharedDataService) { }

  selectLang(lang: string) {
    lang === 'de' ? (this.isSelected = true) : (this.isSelected = false);
  }

  toggleSelected() {
    this.isSelected = !this.isSelected;
  }

  ngOnInit(): void {
    this.language = (this.translate.getBrowserLang().includes('de') || localStorage.getItem("lang") == 'de'  ? 'de' : 'en');

    if (this.language) {
      this.selectedLanguage = this.language;
      this.translate.use(this.language); 
      this.defaultLang = this.language;
      this.selectLang(this.defaultLang);
      this.updateLanguage.emit(this.language);
      this.dataService.setLanguage(this.language);
    }
  }
  useLanguage(language: string): void {
    if (this.selectedLanguage !== language) {
      this.selectedLanguage = language;
      this.toggleSelected();
      this.translate.use(language);
      this.updateLanguage.emit(language);
      this.dataService.setLanguage(language);
    }
  }
}
