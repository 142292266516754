<!-- <ng-container> -->
<div class="row login-content">
  <div class="col-lg-8 col-md-6 app_banner hidden-xs">
    <div class="m-auto logo hidden-xs">
      <app-logo></app-logo>
    </div>
  </div>

  <div class="visible-xs">
    <a href="https://bildungscampus.life"><img class="mobile-img" src="assets/img/logo-new.svg" alt="img" /></a>
    
  </div>

  <div class="col-lg-4 col-md-6 col-12 right-side">
    <div class="text-para">
      <router-outlet></router-outlet>
    </div>
    <div class="footer" style="margin-top: 20px;">
      <app-footer></app-footer>
    </div>
  </div>

</div>
<!-- </ng-container> -->