import { Mandator } from "../../../models/mandator.model";


export const EmailList: Mandator[] = [
  { value: '1', name: 'MANDATOR_OPTION15' , email:"campuscard@mail.schwarz"},
  { value: '3', name: 'MANDATOR_OPTION6' , email:"campuscard@mail.schwarz"},
  { value: '4', name: 'MANDATOR_OPTION5', email:"Campuscard@heilbronn.dhbw.de" },
  { value: '5', name: 'MANDATOR_OPTION2', email:"campuscard@mail.schwarz" },
  {
    value: '6',
    name: 'MANDATOR_OPTION3', email:"studierendenausweis@cas.dhbw.de"
  },
  { value: '7', name: 'MANDATOR_OPTION10', email:"studierendensekretariat@lists.hs-heilbronn.de" },
  { value: '8', name: 'MANDATOR_OPTION14' , email:"campuscard@mail.schwarz"},
  { value: '9', name: 'MANDATOR_OPTION13' , email:"campuscard_verloren@tumheilbronn-ggmbh.de"},
  { value: '10', name: 'MANDATOR_OPTION4', email:"campuscard@mail.schwarz" },
  { value: '11', name: 'MANDATOR_OPTION11' , email:"campuscard@mail.schwarz"},
  {
    value: '12',
    name: 'MANDATOR_OPTION9', email:"campuscard@mail.schwarz"
  },
  {
    value: '13',
    name: 'MANDATOR_OPTION8', email:"campuscard@mail.schwarz"
  },
  { value: '14', name: 'MANDATOR_OPTION1', email:"hello@42heilbronn.de" },
  { value: '15', name: 'MANDATOR_OPTION16' , email:""},
  { value: '16', name: 'MANDATOR_OPTION17' , email:"campuscard@mail.schwarz"},
  { value: '17', name: 'MANDATOR_OPTION12', email:"" },
  { value: '18', name: 'MANDATOR_OPTION7', email:"campuscard@mail.schwarz" },
  { value: '20', name: 'MANDATOR_OPTION20' , email:"campuscard@mail.schwarz"},
  { value: '99', name: 'MANDATOR_OPTION18' , email:"campuscard@mail.schwarz"},
];

