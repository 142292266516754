import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SharedDataService } from './shared-data.service';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private dataService: SharedDataService) { }

  getRedirectedByRequestIdWithQueryParams(userIdHint?: string): void {
    this.dataService._getRequestId().subscribe((requestId) => {
      if (requestId == '' || requestId == null || requestId == 'undefined') {
        requestId = localStorage.getItem("requestId");
      }
      let redirectUrl = environment.base_url + '/authz-srv/authz/resolve_redirect/' + requestId;
      if (userIdHint) {
        redirectUrl = redirectUrl + '?userIdHint=' + userIdHint;
      }
      location.href = redirectUrl
    });
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return "";
    }
  }
}
