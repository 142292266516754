<ng-container>
    <h4 class="header-label">{{ "PASSWORD_SET.SET_NEW_PASSWORD" | translate }}</h4>
    <p class="secondary-header-label">
        {{ "PASSWORD_SET.WELCOME_LABEL" | translate }}
    </p>

    <dynamic-form [fields]=fields [options]=dynamicFormOptions [isSubmitted]=isSubmitBtnClicked
        (btnId)="_getFormId($event)" (onSubmit)="sendResetPassword($event)">
    </dynamic-form>

    <div class="text-danger">{{errorMessage | translate}} </div>

    <div>
        <label class="secondary-header-label">
            {{ "PASSWORD_SET.HINT" | translate }}
        </label>
    </div>

    <div class="row">
        <div class="py-3 back-btn-align col-6">
        </div>
        <div class="py-3 back-btn-align col-6">
            <button type="submit" (click)=sendResetPassword($event) [ngClass]="getDisable()"
                [disabled]="passwordSetFormValue.invalid">
                {{ "PASSWORD_SET.AUTHENTICATION.PASSWORD.BUTTON.TEXT" | translate }}
                <img style="margin-top: 6px;" *ngIf="passwordSetFormValue.valid" src="assets/img/active-arrow.svg"
                    alt="">
                <img *ngIf="passwordSetFormValue.invalid" src="assets/img/deactive-arrow.svg" alt="">
            </button>
        </div>
    </div>

</ng-container>