<div class="d-flex flex-column col-12 col-lg-12 mx-auto card-content pt-0 px-0">
    <div class="firststep" *ngIf="first_step; else secondStep">
        <div id="reset-btn">
            <button class="transparent-back-btn" type="button" (click)="redirectToAtlassian()">
                <span class="fa fa-arrow-left pr-9"></span>
                {{ "ACCOUNT.PERSONAL.ATLASSIAN.ATLASSIAN_BTN" | translate }}
            </button>
        </div>
        <div>
            <h1 class="header-label pt-4">
                {{ "CAMPUS_CARD.HEADER_LABEL" | translate }}
            </h1>
        </div>
        <div class="personal-part">
            <p class="info-text">
                {{ "CAMPUS_CARD.INFO_TEXT" | translate }}
            </p>
            <div class="btn-align-left" *ngIf="this.card_status === 'card_active'">
                <button class="custom-primary-btn" type="submit" (click)="proceedToBlock()">
                    {{ "CAMPUS_CARD.BUTTON_TEXT" | translate }}<span class="fa fa-arrow-right pl-9"></span>
                </button>
            </div>
            <div class="hint-status-progress row" *ngIf="this.card_status === 'card_ready_to_print' || this.card_status === 'card_sent_to_print' || this.card_status === 'card_lost'">
                <img class="hint-icon col-1" src="assets/Icons/icon-left.svg">
                <div class="col-10">
                    <h3 class="status-progress-header">{{ "CAMPUS_CARD.STATUS_IN_PROGRESS_HEADING" | translate }}</h3>
                    <p class="status-text">{{ "CAMPUS_CARD.STATUS_IN_PROGRESS_INFO" | translate }}</p>
                </div>
            </div>
            <div class="hint-status-printed row" *ngIf="this.card_status === 'card_printed'">
                <img class="hint-icon col-1" src="assets/Icons/icon-left-success.svg">
                <div class="col-10">
                    <h3 class="status-printed-header">{{ "CAMPUS_CARD.STATUS_PRINTED_HEADING" | translate }}</h3>
                    <p class="status-text">{{ "CAMPUS_CARD.STATUS_PRINTED_INFO" | translate }}</p>
                </div>
            </div>
        </div>
        <hr>
        <h2 class="sub-heading">
            {{ "CAMPUS_CARD.SUB_HEADER_2" | translate }}
        </h2>
        <p class="ft-16 info-text">
            {{ "CAMPUS_CARD.INFO_TEXT_2" | translate }}</p>
        <a class="custom-link l-height  ft-16" href="https://work.bildungscampus.life/servicedesk/customer/portals">
            {{ "CAMPUS_CARD.CAMPUS_CARD_SERVICEDESK" | translate }}
            <img src="assets/img/external-link-blue.svg">
        </a>
    </div>
    <ng-template #secondStep>
        <ng-container>
            <!--Header for Locking Process-->
            <div class="secondstep">
                <div class="header-content pt-2">
                    <h1 class="header-label text-title heading-bold ft-24">
                        {{ "CAMPUS_CARD.HEADER_LABEL_2Step" | translate }}
                    </h1>
                </div>
            </div>
            <!--Ask user if locking should be done-->
            <div class="secondstep" *ngIf="show_confirmation_popup && is_processed_to_lock">
                <p class="header-label heading-bold ft-18">{{
                    "CAMPUS_CARD.POPUP_AGREE_HINT" | translate
                    }}</p>
                <div class="info_container">
                    <p class="info-text">
                        {{ "CAMPUS_CARD.Step2_INFO_TEXT1" | translate }}
                    </p>
                    <p class="info-text">
                        {{ "CAMPUS_CARD.Step2_INFO_TEXT2" | translate }}
                        <a href="https://bildungscampus.life">
                            {{ "CAMPUS_CARD.Step2_INFO_TEXT2.1" | translate }}
                        </a>
                        {{ "CAMPUS_CARD.Step2_INFO_TEXT2.2" | translate }}
                    </p>
                    <p class="info-text text-bold">
                        {{ "CAMPUS_CARD.Step2_INFO_TEXT3" | translate }}
                    </p>
                </div>
                <div class="input-section mt-5">
                    <div class="align-btn left-btn">
                        <button class="transparent-back-btn" type="button" (click)="cancelLostCard()">
                            <span class="fa fa-arrow-left pr-9"></span> {{ "CAMPUS_CARD.CANCEL_BTN" | translate }}
                        </button>
                    </div>
                    <div class="align-btn right-btn">
                        <button class="custom-primary-btn" type="button" (click)="confirmLostCard()">
                            {{ "CAMPUS_CARD.AGREE_CONFIRM_BTN" | translate }}<span class="fa fa-arrow-right pl-9"></span>
                        </button>
                    </div>
                </div>
            </div>
            <!--Inform user about costs-->
            <div class="msg-hint" *ngIf="show_confirmation_popup && !showkost_terms && !picture_upload_required && !is_processed_to_lock">
                <p class="info-text ft-16">
                    {{ "CAMPUS_CARD.POPUP_PAY_HINT" | translate: {price: price} }}
                </p>
                <div class="align-btn" *ngIf="showkost_terms">
                    <input class="input-checkbox" id="createaccount" type="checkbox" name="kost_terms"
                        [checked]="showkost_terms" [(ngModel)]="kost_terms" />
                    <label class="consent-text">{{ "CAMPUS_CARD.POPUP_CONSENT_HINT" | translate }} </label>
                </div>
                <div class="input-section">

                    <div class="align-btn left-btn">
                        <button class="transparent-back-btn" type="button" (click)="cancelPay()">
                            <span class="fa fa-arrow-left pr-9"></span>{{ "CAMPUS_CARD.CANCEL_BTN" | translate }}
                        </button>

                    </div>
                    <div class="align-btn right-btn">
                        <button class="custom-primary-btn" type="button" (click)="confirmPay()">
                            {{ "CAMPUS_CARD.AGREE_CONFIRM_BTN" | translate }}<span
                                class="fa fa-arrow-right pl-9"></span>
                        </button>
                    </div>
                </div>
            </div>
            <!--Ask user to accept kost_terms-->
            <div class="msg-hint" *ngIf="showkost_terms">
                <p class="ft-16">{{
                    "CAMPUS_CARD.POPUP_CONSENT_HINT" | translate
                    }}</p>
                <div class="align-btn" *ngIf="showkost_terms">
                    <input class="input-checkbox" id="createaccount" type="checkbox" name="kost_terms"
                        [checked]="showkost_terms" [(ngModel)]="kost_terms" />
                    <label class="consent-text">
                        <a href="https://bildungscampus.hn/leben-am-campus/campuscard/nutzungsbedingungen">
                            {{ "CAMPUS_CARD.KOST_TERMS_SUB_HEADING" | translate }}
                        </a>
                    </label>
                </div>
                <div class="input-section">
                    <div class="align-btn left-btn">
                        <button class="transparent-back-btn" type="button" (click)="cancelkost_terms()">
                            <span class="fa fa-arrow-left pr-9"></span>{{ "CAMPUS_CARD.CANCEL_BTN" | translate }}
                        </button>
                    </div>
                    <div class="align-btn right-btn">
                        <button class="custom-primary-btn" type="button" (click)="confirmkost_terms()"
                            [disabled]="!kost_terms">
                            {{ "CAMPUS_CARD.AGREE_CONFIRM_BTN" | translate }}<span
                                class="fa fa-arrow-right pl-9"></span>
                        </button>
                    </div>
                </div>
            </div>
            <!--Upload picture-->
            <div class="msg-hint" *ngIf="picture_upload_required">
                <p>{{ "CAMPUS_CARD.UPLOAD_PROFILEPIC_HINT" | translate }}</p>
                <div class="align-btn left-btn">
                    <button (click)="pictureUpload()" class="custom-primary-btn" type="submit">
                        {{ "CAMPUS_CARD.UPLOAD_PICTURE" | translate }}<span class="fa fa-arrow-right pl-9"></span>
                    </button>
                </div>
                <form role="form" name="loginAfterRegisterForm">
                    <input name="rememberMe" type="checkbox" checked="true" value="true"
                        [style.visibility]="'hidden'" />
                </form>
                <div class="align-btn left-btn pt-4">
                    <button class="transparent-back-btn" type="button" (click)="cancelPictureUpload()">
                        <span class="fa fa-arrow-left pr-9"></span>{{ "CAMPUS_CARD.CANCEL_BTN" | translate }}
                    </button>
                </div>
            </div>
        </ng-container>
    </ng-template>
</div>
