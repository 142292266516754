import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dynamic-field-renderer',
  templateUrl: './dynamic-field-renderer.component.html',
  styleUrls: ['./dynamic-field-renderer.component.scss']
})
export class DynamicFieldRendererComponent implements OnInit {
  @Input() fields;
  @Input() group;
  @Input() appearance;

  constructor() { }

  ngOnInit(): void {
  }

}
