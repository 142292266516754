import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from 'src/service/spinner.service';
import { CompleteRegisterService } from '../../../../../../service/complete-register.service';
import { environment } from 'src/environments/environment';
import { RegexUtil } from 'src/app/helpers/regex.helper';
@Component({
  selector: 'app-second-page',
  templateUrl: './second-page.component.html',
  styleUrls: ['./second-page.component.scss'],
})
export class SecondPageComponent implements OnInit {
  currentEmail!: string;
  setPassword!: string;
  confirmPassword!: string;
  newEmailAdress!: string;
  acceptConsent!: boolean;
  userDetails: any;
  passIds: any = [];
  passDetails: any;
  passStatus: boolean = false;


  secondPageForm = this.formBuilder.group({
    set_password: [
      '',
      [
        Validators.required,
        Validators.pattern(
          this._regex.newEmailPattern
        ),
        Validators.minLength(8),
      ],
    ],
    confirm_password: ['', Validators.required],
    email: [''],
    accept_consent: [false, Validators.requiredTrue],
  });
  // [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]
  passwordNotMatch!: boolean;
  password: any;
  conPassword: any;
  show = false;
  confpasswordshow = false;
  requestId: string = '';
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private complete_register: CompleteRegisterService,
    private _loader: SpinnerService,
    private _regex: RegexUtil
  ) {
    this.route.queryParams.subscribe((params) => {
      this.requestId = params['requestId'];
    });
  }

  get f() {
    return this.secondPageForm;
  }

  onFormSubmit() {
    this.setValues();
    this.resetForm();
    this._loader._start();
    this.complete_register.changePwdEmail();
    this._loader._stop();
    if (this.complete_register.showNextPage) {
      this.goToNextPage();
    }
    this.goToNextPage();
  }
  comparePasswords() {
    const { value: set_password } = this.f.get('set_password');
    const { value: confirm_password } = this.f.get('confirm_password');

    return set_password === confirm_password
      ? (this.passwordNotMatch = false)
      : (this.passwordNotMatch = true);
  }
  setValues() {
    this.complete_register.setPassword = this.f.get('set_password')?.value;
    this.complete_register.confirmPassword =
      this.f.get('confirm_password')?.value;
    this.complete_register.acceptConsent = this.f.get('accept_consent')?.value;
    this.complete_register.newEmailAdress = this.f.get('email')?.value;
  }

  resetForm() {
    this.f.reset();
  }

  goToNextPage() {
    this.router.navigate(['video-preview'], { relativeTo: this.route.parent });
  }

  async ngOnInit() {
    this.complete_register.resetShowNextPage();
    this.getUserInfo();
    this.currentEmail = this.complete_register.currentEmail;
    this.password = 'password';
    this.conPassword = 'password';
  }

  getUserInfo() {
    this._loader._start();
    this.complete_register.getUserInfo().then(data => {
      this._loader._stop();
      if (data) {
        this.userDetails = data;
        this.currentEmail = data.email;
        if (this.userDetails && this.userDetails.customFields && this.userDetails.customFields.passes) {
          this.passIds = this.userDetails.customFields.passes.split(',');
          this.passIds.forEach(passId => {
            if (!this.passStatus)
              this.checkPassIdvalidation(passId)
            else return
          });
        }
        this.complete_register.setUserInfo(data.sub, data.last_used_identity_id, data.email, data.secondary_email, data.roles)
      }
    }, error => {
      this._loader._stop();
    })
  }

  checkPassIdvalidation(id): any {
    this._loader._start();
    this.complete_register.getPassId(id).then((data: any) => {
      this._loader._stop();
      if (data.data && data.success) {
        this.passDetails = data.data;
        if (this.passDetails.pass.state == "card_active" || (this.passDetails.pass.customFields && this.passDetails.pass.customFields.card_csn_hex)) {
          this.passStatus = true
        } else this.passStatus = false;
      }
    }, error => {
      this._loader._stop();
    })
  }

  showPassword() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }

  }
  showConfirmPassword() {
    if (this.conPassword === 'password') {
      this.conPassword = 'text';
      this.confpasswordshow = true;
    }
    else {
      this.conPassword = 'password';
      this.confpasswordshow = false;
    }
  }

  backClick() {
    this.router.navigate(['/complete-register'], {
      queryParams: {
        requestId: this.requestId,
      },
    });
  }

  getDisable() {
    if (this.f.invalid) {
      return 'transparent-btn1';
    } else {
      return 'custom_primary_btn1';
    }
  }
  redirectToAtlassian(){
    window.location.assign(
      environment.atlassian_url
    );
  }
}
