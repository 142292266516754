import { Pipe, PipeTransform } from '@angular/core';
import { Mandator } from '../models/mandator.model';

@Pipe({
  name: 'sortOptions'
})
export class SortOptionsPipe implements PipeTransform {
  transform(options: Mandator[]): any[] {
    return options.sort((a, b) => a.name.localeCompare(b.name));
  }
}