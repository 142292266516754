import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RegexUtil } from 'src/app/helpers/regex.helper';
import { CidaasService } from 'src/service/cidaas.service';
import { SharedDataService } from 'src/service/shared-data.service';
import { SpinnerService } from 'src/service/spinner.service';
import { InputGroup } from '../../../models/input-group.model';

@Component({
  selector: 'app-forgot-password-init',
  templateUrl: './forgot-password-init.component.html',
  styleUrls: ['./forgot-password-init.component.scss']
})
export class ForgotPasswordInitComponent implements OnInit {
  username: string = '';
  requestId: string = '';

  input: InputGroup =  {
    label: 'RESET.USER_ID',
    hint: 'RESET.USER_ID',
    placeholder: 'RESET.USER_ID_PLACEHOLDER',
    type: 'username',
    name: 'username',
    autocomplete: 'username',
    value: this.username ? this.username : '',
    error: '',
    allowWith: '',
  };
  inputGroupOption: any = {input: this.input};
  isValid: boolean;
  resetInitiatePayload: any = {};
  type = '';
  resetMedium = '';
  configuredMFAList: any = [];
  rprq: string = '';
  exchangeId: string = '';
  showSuccessMessage: boolean = false;
  showErrorMessage: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _regex: RegexUtil,
    private cidaasService: CidaasService,
    private dataService: SharedDataService,
    public router: Router,
    private translate: TranslateService,
    private _loader: SpinnerService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      let username = params['userIdHint'] ? params['userIdHint'] : '';
      this.requestId = params['requestId'] ? params['requestId'] : '';
      this.type = params['type'];
      this.resetMedium = params['resetMedium'];
      this.rprq = params['rprq'];
      this.exchangeId = params['exchangeid'] ? params['exchangeid'] : '';

      if (this.rprq && this.exchangeId) {
        this.router.navigate(['password_set'], {
          queryParams: {
            rprq: this.rprq,
            requestId: this.requestId,
            exchangeId: this.exchangeId,
            resetMedium: this.resetMedium,
            type: this.type
          },
        })
      }

      this.updateUsername(username);
    });

    this.dataService._getMFAList().subscribe((data) => {
      this.configuredMFAList = data.configured_list;
    });
  }

  updateUsername(username) {
    this.username = username && this.checkValueType(username) === "email" ? username : '';
    this.inputGroupOption.input.value = this.username;
    this.inputGroupOption.input.error = '';
  };

  onSubmit() {
    if (this.inputGroupOption.input.value) {
      let usernameType = this.checkValueType(this.inputGroupOption.input.value);
      let options = {};
      if (this.requestId) {
        this.username = this.inputGroupOption.input.value;
        options['requestId'] = this.requestId;
        this.initiateReset();
      }
    } else {
      this.inputGroupOption.input.error = 'ERRORS.REQUIRED';
    }
  };

  checkValueType(value: string) {
    let emailRegx = this._regex.email;
    if (emailRegx.test(value)) {
      return 'email';
    } else {
      return 'username';
    }
  };

  backClick() {
    this.router.navigate(['/login'], {
      queryParams: {
        userIdHint: this.username,
        requestId: this.requestId,
        type: this.type
      },
    });
  }

  getDisable() {
    if ((this.inputGroupOption.input.value == '' || this.inputGroupOption.input.value == undefined)) {
      return 'transparent-btn1';
    } else {
      return 'custom_primary_btn1';
    }
  }

  // on click of reset button, initiates reset for username/mobile/email
  initiateReset(): void {
    this.resetInitiatePayload = {
      requestId: this.requestId,
      processingType: 'LINK',
    };
    if (this.type !== 'link') {
      this.populateResetPayload(this.type);
    } else {
      this.populateResetPayload(this.resetMedium);
    }
    this._loader._start();
    this.cidaasService
      .initiatePasswordReset(this.resetInitiatePayload)
      .then((data) => {
        this._loader._stop();
        if (data && data.success && data.status === 200 && data.data.reset_initiated) {
          this.showSuccessMessage = true;
          this.showErrorMessage = false;
          this.inputGroupOption.input.error = ""
        } else {
          this.inputGroupOption.input.error = "COMPLETE_REGISTER.ERROR_MESSAGE"
          this.showErrorMessage = true;
        }
      })
      .catch((e) => {
        console.log(e);
        this.inputGroupOption.input.error = "COMPLETE_REGISTER.ERROR_MESSAGE"
        this.showErrorMessage = true;
        this._loader._stop();
      });
  }

  // populate payload fields based on resetMedium
  populateResetPayload(type): void {
    switch (type) {
      case 'username':
        this.resetInitiatePayload.username = this.username;
        if (this.configuredMFAList.length > 0) {
          this.resetInitiatePayload.resetMedium = this.getResetMedium();
        } else {
          this.resetInitiatePayload.resetMedium = 'email';
        }
        break;
      case 'email':
        this.resetInitiatePayload.email = this.username;
        this.resetInitiatePayload.resetMedium = 'email';
        break;
      case 'mobile':
        this.resetInitiatePayload.mobile = this.username;
        this.resetInitiatePayload.resetMedium = 'sms';
        break;
    }
  }

  getResetMedium(): string {
    let resetMedium = '';
    if (this.configuredMFAList.findIndex((x) => x.type === 'EMAIL') > -1) {
      resetMedium = 'email';
    } else if (this.configuredMFAList.findIndex((x) => x.type === 'SMS') > -1) {
      resetMedium = 'sms';
    } else if (this.configuredMFAList.findIndex((x) => x.type === 'IVR') > -1) {
      resetMedium = 'ivr';
    }
    return resetMedium;
  }

}
