<div class="input-fields-container" [formGroup]="group">
  <label>{{field?.localeText?.name}}:</label>
  <div>
    <input class="input-field" [formControlName]="field.fieldKey" [name]="field.fieldKey" [readonly]="field?.readOnly"
      [placeholder]="field.localeText?.name" [type]="type" [required]="field.required">
    <ng-container *ngFor="let validation of field.validation;" ngProjectAs="span">
      <span class="errMsg ft-12"
        *ngIf="group.get(field.fieldKey).hasError(validation.name) && group.get(field.fieldKey).touched">
        {{validation.message | translate}}
      </span>
    </ng-container>
  </div>
</div>