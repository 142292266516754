<section>
  <div class="main_container">
    <h4 class="header-label">{{ "THIRD_PAGE_MODAL.CLOSE_REG" | translate }}</h4>
    <div class="secondary-header-label">
      <p>
        {{ "REG_WITHOUT_VIDEO_IDENT.FIRST_PARAGRAPH" | translate }}
      </p>
      <br>
      <p class="subtitle">
        {{ "REG_WITHOUT_VIDEO_IDENT.SUBTITLE" | translate }}
      </p>

      <ul class="basic-theme" style="list-style-type:square">
        <li class="list-item">
          <span innerHTML="{{ 'REG_WITHOUT_VIDEO_IDENT.FIRST_STEP' | translate }}"></span>
        </li>
        <li class="list-item">
          <span innerHTML="{{ 'REG_WITHOUT_VIDEO_IDENT.SECOND_STEP' | translate }}"></span>
        </li>
        <li class="list-item">
          <span innerHTML="{{ 'REG_WITHOUT_VIDEO_IDENT.THIRTH_STEP' | translate }}"></span>
        </li>
      </ul>
    </div>


    <div class="bottom-btn">
      <div class="back-btn-align">
        <button type="button" class="navigate-btn" (click)=backClick()>
          <img src="assets/img/left-arrow.svg" alt="">
          {{ "LOGIN.AUTHENTICATION.BACK" | translate }}
        </button>
      </div>
      <div class="back-btn-align">
        <button type="submit" class="custom_primary_btn1" (click)="goWithNoIdentification()">
          {{ "REG_WITHOUT_VIDEO_IDENT.PICTURE_UPLOAD_BTN" | translate }}
          <img style="margin-top: 6px;" src="assets/img/active-arrow.svg" alt="">
        </button>
      </div>
    </div>

    <p class="bottom_link_container basic-theme">
      <span (click)="startVideoIdent()" style="color: #276D98;">{{
        "REG_WITHOUT_VIDEO_IDENT.START_VIDEO_IDENT" | translate
        }}</span>
    </p>
  </div>
  <form role="form" name="loginAfterRegisterForm">
    <input name="rememberMe" type="checkbox" checked="true" value="true"
        [style.visibility]="'hidden'" />
</form>
</section>