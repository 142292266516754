<h4 class="header-label">
  {{ "LOGIN.AUTHENTICATION.WELCOME_AUTH_HEADER" | translate }}
</h4>

<p class="secondary-header-label">
  {{ "LOGIN.AUTHENTICATION.WELCOME_LABEL_" + selectedType | translate }}
</p>

<app-authenticate *ngIf="isInitiated" [options]="verifyInputOption"></app-authenticate>

<p class="mb30 row" *ngIf="!isInitiated">
  <a [routerLink]="" class="pass-link" (click)="forgotPassword()" style="margin-left: 3px;">{{'LOGIN.FORGOT_PASSWORD' |
    translate}}</a>
</p>

<div *ngIf="selectedType === 'PASSWORD'">
  <div class="" *ngFor="let item of allowedMFA; let i = index">
    <div class="" style="text-align: end; cursor: pointer;" *ngIf="item != 'password'"
      (click)="_initiateAuthentication(item, $event, i)">
      <p class="alternative-way">{{"LOGIN.AUTHENTICATION.LOGIN_VIA_" + item.toUpperCase() | translate}}</p>
    </div>
  </div>
</div>
