import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SpinnerService } from 'src/service/spinner.service';
import { CompleteRegisterService } from '../../../../../../service/complete-register.service';
import { Employed, Students } from '../../mandator';
import { Mandator } from 'src/app/models/mandator.model';
import { TranslateService } from '../../../../../../service/translate.service';
import { SharedDataService } from 'src/service/shared-data.service';

@Component({
  selector: 'app-first-step',
  templateUrl: './first-step.component.html',
  styleUrls: ['./first-step.component.scss'],
})
export class FirstStepComponent implements OnInit, OnDestroy {
  isShowError!: boolean;
  isShowNumberError!: boolean;
  invalidCredentials = false;

  private showErrorSub: Subscription = new Subscription();
  private showNumberErrorSub: Subscription = new Subscription();
  requestId: string = '';
  mandatorList = Employed;

  functions = [
    { id: 1, function: 'COMPLETE_REGISTER.FUNCTION_OPTION1' },
    { id: 2, function: 'COMPLETE_REGISTER.FUNCTION_OPTION2' },
  ];

  selectedFunction = '';
  selectedMandator: any = {};
  selectedNumber!: string;
  selectedPassword!: string;
  mandatorOptions: any[];

  firstPageForm = this.formBuilder.group({
    function: [this.selectedFunction ? this.selectedFunction : null, Validators.required],
    mandator: [this.selectedMandator.name ? this.selectedMandator.name : null, Validators.required],
    number: ['', [Validators.required, Validators.maxLength(7)]],
    family_name: ['', Validators.required],
  });
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private dataservice: SharedDataService,
    private complete_register: CompleteRegisterService, public loader: SpinnerService
  ) {
    let language = localStorage.getItem("lang");
    if(language){
      this.mandatorOptions = this.translateOptions();
      if (this.selectedMandator && Object.keys(this.selectedMandator).length > 0) {
        let value = this.mandatorOptions.filter(option => option.value == this.selectedMandator.value)
        this.f.get('mandator')?.setValue(value.length > 0 ? this.translate.getTranslatedMessage(value[0].name.toString()): '');
      }
    }
    else{
      this.dataservice.getLanguage().subscribe((data) => {
        if (data) {
          this.mandatorOptions = this.translateOptions();
          if (this.selectedMandator && Object.keys(this.selectedMandator).length > 0) {
            let value = this.mandatorOptions.filter(option => option.value == this.selectedMandator.value)
            this.f.get('mandator')?.setValue(value.length > 0 ? this.translate.getTranslatedMessage(value[0].name.toString()): '');
          }
        }
      }, (error: any) => {
      });
      this.mandatorOptions = this.translateOptions();
    }

  }

  private translateOptions(): Mandator[] {
    let mandatorOptions = [];
    this.mandatorList.forEach(mandator => {
      let temp = {
        name: this.translate.getTranslatedMessage(mandator.name),
        value: mandator.value
      }
      mandatorOptions.push(temp)
    });
    return mandatorOptions;
  }

  get f() {
    return this.firstPageForm;
  }
  setFunction(event: string) {
    this.selectedFunction = event.toString();
    this.f.get('mandator')?.setValue('');
    this.setMandatorList();
  }
  setMandator(event: any) {
    this.f.get('mandator')?.setValue(this.translate.getTranslatedMessage(event.name.toString()));
    this.selectedMandator = event;
    let emp = [];
    Employed.forEach((item) => {
      emp.push(item.value);
    });
    let stud = [];
    Students.forEach((item) => {
      stud.push(item.value);
    });
  }

  setMandatorList() {
    if (this.selectedFunction === '1') {
      this.setStudents();
    }

    if (this.selectedFunction === '2') {
      this.setEmployers();
    }
  }
  setStudents() {
    this.mandatorList = Employed;
    this.mandatorOptions = this.translateOptions();
  }
  setEmployers() {
    this.mandatorList = Students;
    this.mandatorOptions = this.translateOptions();
  }

  getMandatorValueByName(name) {
    for (var i = 0; i < Employed.length; i++) {
      let filterName = this.translate.getTranslatedMessage(Employed[i].name);
      if (filterName == name) {
        return Employed[i].value;
      }
    }
    return ""
  }
  setValues() {
    let mandator_value = this.getMandatorValueByName(this.f.get('mandator')?.value);
    this.complete_register.selectedMandator = mandator_value;
    this.complete_register.selectedNumber = this.f.get('number')?.value;
    this.complete_register.selectedPassword = this.f.get('family_name')?.value;
  }
  resetForm() {
    this.f.reset();
  }
  async onFormSubmit() {
    this.setValues();
    this.loader._start();
    await this.complete_register.checkLogin();
    this.loader._stop();
    this.loader._start();
    let login_success = this.complete_register.getShowNextPage();
    this.loader._stop();
    if (login_success) {
      this.goToNextPage();
    } else {
      this.invalidCredentials = true;
    }
  }
  goToNextPage() {
    this.router.navigate(['second-step'], { relativeTo: this.route });
  }
  onClose() {
    this.resetForm();
    this.complete_register.setShowError();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.requestId = params['requestId']
        ? params['requestId']
        : this.complete_register.getRequestId();
    });

    this.complete_register.setRequestId(this.requestId);

    this.showErrorSub = this.complete_register.showError.subscribe((error) => {
      this.isShowError = error;
    });
    this.showNumberErrorSub = this.complete_register.showNumberError.subscribe(
      (nrError) => {
        this.isShowNumberError = nrError;
      }
    );
  }
  ngOnDestroy(): void {
    this.showErrorSub.unsubscribe();
    this.showNumberErrorSub.unsubscribe();
  }

  getDisable() {
    if (!this.f.valid) {
      return 'transparent-btn';
    } else {
      return 'custom-primary-btn';
    }
  }

}
