import {
  Component,
  OnInit,
  Input,
  ComponentFactoryResolver,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'dynamicField',
  templateUrl: './dynamic-field.component.html',
  styleUrls: ['./dynamic-field.component.scss'],
})
export class DynamicFieldComponent implements OnInit {
  @Input() field;
  @Input() group;
  @Input() appearance;

  constructor() {}

  ngOnInit(): void {}
}
