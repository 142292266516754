<div class="form-container" [ngSwitch]="field.dataType">
  <div *ngSwitchDefault>{{field.fieldKey}}</div>
  <app-input *ngSwitchCase="'TEXT'" [group]="group" [field]="field" [type]="'input'" [appearance]="appearance">
  </app-input>
  <app-input *ngSwitchCase="'JSON_STRING'" [group]="group" [field]="field" [type]="'input'" [appearance]="appearance">
  </app-input>
  <app-input *ngSwitchCase="'USERNAME'" [group]="group" [field]="field" [type]="'input'" [appearance]="appearance">
  </app-input>
  <app-select *ngSwitchCase="'SELECT'" [group]="group" [field]="field" [ismulti]=false [appearance]="appearance">
  </app-select>
  <app-select *ngSwitchCase="'MULTISELECT'" [group]="group" [field]="field" [ismulti]=true [appearance]="appearance">
  </app-select>
  <app-checkbox *ngSwitchCase="'CHECKBOX'" [group]="group" [field]="field"></app-checkbox>
  <app-checkbox *ngSwitchCase="'CONSENT'" [group]="group" [field]="field"></app-checkbox>
  <app-password *ngSwitchCase="'PASSWORD'" [group]="group" [field]="field" [appearance]="appearance"> </app-password>
  <app-input *ngSwitchCase="'EMAIL'" [group]="group" [field]="field" [type]="'email'" [appearance]="appearance">
  </app-input>
  <app-input *ngSwitchCase="'MOBILE'" [group]="group" [field]="field" [type]="'input'" [appearance]="appearance">
  </app-input>
  <app-input *ngSwitchCase="'NUMBER'" [group]="group" [field]="field" [type]="'number'" [appearance]="appearance">
  </app-input>
  <app-input *ngSwitchCase="'URL'" [group]="group" [field]="field" [type]="'url'" [appearance]="appearance">
  </app-input>
  <app-radio *ngSwitchCase="'RADIO'" [group]="group" [field]="field"> </app-radio>
  <app-date *ngSwitchCase="'DATE'" [group]="group" [field]="field"> </app-date>
  <app-text-area *ngSwitchCase="'TEXTAREA'" [group]="group" [field]="field" [appearance]="appearance"></app-text-area>
</div>