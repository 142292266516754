import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { RegexUtil } from "../../../../helpers/regex.helper";
import { SharedDataService } from "../../../../../service/shared-data.service";
import { environment } from "src/environments/environment";
import { SpinnerService } from "src/service/spinner.service";
import { InputGroup } from "../../../../models/input-group.model";

@Component({
  selector: "app-initiate-login",
  templateUrl: "./initiate-login.component.html",
  styleUrls: ["./initiate-login.component.scss"],
})
export class InitiateLoginComponent implements OnInit {
  username: string = "";
  requestId: string = "";
  input: InputGroup = {
    label: 'LOGIN.USER_ID',
    hint: 'LOGIN.USER_ID',
    placeholder: 'LOGIN.USER_ID_PLACEHOLDER',
    type: 'username',
    name: 'username',
    autocomplete: 'username',
    value: this.username ? this.username : '',
    error: '',
    allowWith: ''
  };
  inputGroupOption = {input: this.input}
  isValid: boolean;
  isSubmitted: boolean = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _dataService: SharedDataService,
    private _regex: RegexUtil,
    private _loader: SpinnerService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      let username = params['userIdHint'] ? params['userIdHint'] : params['username'] ? params['username'] : '';
      this.requestId = params['requestId'] ? params['requestId'] : '';
      this.inputGroupOption.input.error = params['error'] ? params['error'].toUpperCase() : '';
      this._updateUsername(username);
    });
  }

  _updateUsername = (username) => {
    this.username = username ? username : '';
    this.inputGroupOption.input.value = this.username;
  };

  getDisable() {
    if (this.inputGroupOption.input.value == '' && !this.isSubmitted) {
      return 'transparent-btn';
    } else {
      return 'custom-primary-btn';
    }
  }

  _onSubmit() {
    if (this.username == "")
      this.username = this.inputGroupOption.input.value;
    this.isSubmitted = true;
    if (this.isSubmitted && this.username) {
      this._loader._start();
      let usernameType = this._checkValueType(this.username);
      if (this.requestId) {
        this._dataService._setUser(this.username);
        this._loader._stop();
        this.router.navigate(["initiate"], {
          relativeTo: this.activatedRoute,
          queryParams: {
            userIdHint: this.username,
            requestId: this.requestId,
            type: usernameType,
          },
        });
      }
      this._loader._stop();
    } else {
      this.inputGroupOption.input.error = 'ERRORS.REQUIRED';
      this._loader._stop();
    }
  };

  _checkValueType = (value: string) => {
    let mobileRegx = this._regex.mobile;
    let emailRegx = this._regex.email;
    if (mobileRegx.test(value)) {
      return 'mobile';
    } else if (emailRegx.test(value)) {
      return 'email';
    } else {
      return 'username';
    }
  };

  _openRegistration = (event: any) => {
    location.href = environment.base_url + '/authz-srv/authz?response_type=token&client_id=' + environment.client_registration_flow + '&viewtype=login&redirect_uri=' + environment.atlassian_url;
  }
}
