<section>
  <div>
    <h4 class="header-label" *ngIf="isShowingCropper && !isPictureUploaded">{{ "MANUAL_PICTURE_UPLOAD.TITLE" | translate
      }}</h4>
    <h4 class="header-label" *ngIf="!isShowingCropper && !isPictureUploaded ">{{ "MANUAL_PICTURE_UPLOAD.UPLOAD_PICTURE"
      |
      translate }}</h4>
    <h4 class="header-label" *ngIf="isPictureUploaded">{{ "MANUAL_PICTURE_UPLOAD.THANKS_LABEL_HEADER" | translate }}</h4>

    <div class="profile-part" *ngIf="!isShowingCropper && !IsImagetriggered">
      <p class="tip-header">{{ "THIRD_PAGE.HINT" | translate }}</p>
      <ul class="manual-upload-hint-list">
        <li *ngFor="let e of [].constructor(totalHints); let i = index">
          <span>{{"ACCOUNT.PERSONAL.PROFILE.PROFILE_PICTURE_REQUIREMENTS_" + (i+1)| translate}}</span>
        </li>
      </ul>
    </div>

    <div class="red-alert" *ngIf="isShowingPictureUploadedMsg && !isPictureUploaded">
      <img src="assets/img/error-icon.svg" alt="">
      <div class="error-hints">
        <p class="error-header"> {{
          "MANUAL_PICTURE_UPLOAD.ERROR_UPLOADING" | translate
          }}</p>
        <p class="error-label">{{ "MANUAL_PICTURE_UPLOAD.ERROR_UPLOADING_HINT" | translate }} </p>
      </div>
    </div>
    <br>

    <div class="lbl-container" *ngIf="!isPictureUploaded">
      <input type="file" id="imgInput" name="imgInput" accept="image/*" enctype="multipart/form-data"
        (change)="selectPicture($event)" />
      <label class="lbl" for="imgInput"><img class="img-photo" src="assets/img/picture.svg" alt="iconUpload" /><span>{{
          isShowingCropper
          ? ("MANUAL_PICTURE_UPLOAD.SELECT_ANOTHER_PICTURE" | translate)
          : ("MANUAL_PICTURE_UPLOAD.DROP_PICTURE" | translate)
          }}</span>
      </label>
      <div class="text-right">
        <label class="picture-upload-text-button" for="imgInput"><img src="assets/img/folder.svg" alt="iconUpload" />
          <span class="folder-upload-label">{{"MANUAL_PICTURE_UPLOAD.SELECT_PICTURE" | translate}}</span>
        </label>
      </div>
    </div>

    <div [style.display]="isShowingCropper ? null : 'none'">
      <article class="cropper-img-container">
        <div #cropContainer class="cropper-container1">
          <image-cropper [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true" [aspectRatio]="3 / 4" [resizeToWidth]="imgWidth"
            [resizeToHeight]="imgHeight" format="png" (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"></image-cropper>
          <label class="pic-uplod-hint">{{"MANUAL_PICTURE_UPLOAD.TITLE" | translate}}</label>
        </div>
        <div class="crp-img-container">
          <img class="img-item" [src]="croppedImage" [style.width.px]="imgWidth" [style.height.px]="imgHeight" />
          <label class="pic-uplod-hint mt-2">{{"MANUAL_PICTURE_UPLOAD.PREVIEW" | translate}}</label>
        </div>
      </article>
    </div>

    <div *ngIf="isPictureUploaded && croppedImage">
      <div class="cropped-img-container" style="display: flex;">
        <img class="cropped-img" [src]="croppedImage" />
        <p class="cropped-label">{{ "MANUAL_PICTURE_UPLOAD.THANKS_LABEL" | translate }}</p>
      </div>

      <div class="tip-container">
        <div>
          <img src="assets/img/error-icon-blue.svg" alt="">
        </div>
        <div class="tip-hint">
          <p class="tip-header">{{ "MANUAL_PICTURE_UPLOAD.TIP_HEADER" | translate }}</p>
          <p class="tip-label" innerHTML="{{ 'MANUAL_PICTURE_UPLOAD.TIP_HINT' | translate }}"></p>
          <a class="tip-link" href="https://bildungscampus.cards/ppSSO">{{ "MANUAL_PICTURE_UPLOAD.TO_PAYMENT_PORTAL" | translate }}<img src="assets/img/right-arrow.svg" alt=""></a>
        </div>
      </div>
    </div>
  </div>

  <div class="bottom-btn">
    <div class="back-btn-align">
      <button type="button" class="navigate-btn" (click)=backClick() *ngIf="!campus_card">
        <img src="assets/img/left-arrow.svg" alt="">
        {{ "LOGIN.AUTHENTICATION.BACK" | translate }}
      </button>
    </div>
    <div class="back-btn-align" *ngIf="!isPictureUploaded">
      <button type="submit" [ngClass]="getDisable()" (click)="uploadPicture()" [disabled]="!isShowingCropper">
        {{ "MANUAL_PICTURE_UPLOAD.UPLOAD_PICTURE" | translate }}
        <img style="margin-top: 5px;" src="assets/img/active-arrow.svg" alt="" *ngIf="isShowingCropper">
        <img src="assets/img/deactive-arrow.svg" alt="" *ngIf="!isShowingCropper">
      </button>
    </div>

    <div class="back-btn-align" *ngIf="isPictureUploaded && campus_card != 'true'">
      <button type="submit" class="custom_primary_btn1" (click)="goToAtlassian()">
        {{ "MANUAL_PICTURE_UPLOAD.BUTTON_ATLASSIAN" | translate }}
        <img style="margin-top: 5px;" src="assets/img/active-arrow.svg" alt="">
      </button>
    </div>

    <div class="back-btn-align" *ngIf="isPictureUploaded && campus_card == 'true'">
      <button type="submit" class="custom_primary_btn1" (click)="goToContinue()">
        {{ "MANUAL_PICTURE_UPLOAD.BUTTON_GOON" | translate }}
        <img style="margin-top: 5px;" src="assets/img/active-arrow.svg" alt="">
      </button>
    </div>

  </div>
</section>
