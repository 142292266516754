<div>
  <!-- <div class="modal-text"> -->
  <h4 class="header-label">{{ "THIRD_PAGE_MODAL.INFO_VIDEO_TITLE" | translate }}</h4>
  <p class="secondary-header-label" innerHTML="{{ 'THIRD_PAGE_MODAL.FIRST_PARAGRAPH' | translate }}"></p>

  <div class="containers mt-3">
    <div id="accordionOne">
      <div class="card">
        <div class="accordion-header card-header">
          <button class="accordion-button collapsed" data-toggle="collapse" href="#collapseOne">
            {{ "THIRD_PAGE_MODAL.FIRST_SUBTITLE" | translate }}
          </button>
        </div>
        <div id="collapseOne" class="collapse" data-parent="#accordionOne">
          <div class="card-body">
            <p class="basic-theme">{{ "THIRD_PAGE_MODAL.TAKES" | translate }}</p>
            <ul class="basic-theme video-detection-hints">
              <li>
                <span>{{ "THIRD_PAGE_MODAL.FIRST_LIST.FIRST_STEP" | translate }}</span>
              </li>
              <li>
                <span>{{ "THIRD_PAGE_MODAL.FIRST_LIST.SECOND_STEP" | translate }}</span>
              </li>
              <li>
                <span>{{ "THIRD_PAGE_MODAL.FIRST_LIST.THIRTH_STEP" | translate }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div id="accordionTwo">
      <div class="card">
        <div class="accordion-header card-header">
          <button class="accordion-button collapsed" data-toggle="collapse" href="#collapseTwo">
            {{ "THIRD_PAGE_MODAL.SECOND_SUBTITLE" | translate }}
          </button>
        </div>
        <div id="collapseTwo" class="collapse" data-parent="#accordionTwo">
          <div class="card-body">
            <p class="basic-theme">{{ "THIRD_PAGE_MODAL.THIRTH_PARAGRAPH" | translate }}</p>
            <ul class="basic-theme" style="list-style-type:square">
              <li>
                <span>{{
                  "THIRD_PAGE_MODAL.SECOND_LIST.FIRST_BENEFIT" | translate
                  }}</span>
              </li>
              <li>
                <span>{{
                  "THIRD_PAGE_MODAL.SECOND_LIST.SECOND_BENEFIT" | translate
                  }}</span>
              </li>
              <li>
                <span>{{
                  "THIRD_PAGE_MODAL.SECOND_LIST.THIRTH_BENEFIT" | translate
                  }}</span>
              </li>
              <li>
                <span>{{
                  "THIRD_PAGE_MODAL.SECOND_LIST.FOURTH_BENEFIT" | translate
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bottom-btn">
    <div class="back-btn-align">
      <button type="button" class="navigate-btn" (click)=backClick()>
        <img src="assets/img/left-arrow.svg" alt="">
        {{ "LOGIN.AUTHENTICATION.BACK" | translate }}
      </button>
    </div>
    <div class="back-btn-align">
      <button type="submit" class="custom_primary_btn1" (click)="startVideoIdent()">
        {{ "THIRD_PAGE.IDENT_BUTTON" | translate }}
        <img style="margin-top: 6px;" src="assets/img/active-arrow.svg" alt="">
      </button>
    </div>
  </div>
  <p class="bottom_link_container basic-theme">
    <span (click)="goWithNoIdentification()" style="color: #276D98;">{{
      "THIRD_PAGE_MODAL.CLOSE_REG" | translate
      }}</span>
  </p>
  <form role="form" name="loginAfterRegisterForm">
    <input name="rememberMe" type="checkbox" checked="true" value="true"
        [style.visibility]="'hidden'" />
</form>
</div>