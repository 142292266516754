import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  public isloading = new BehaviorSubject(false);
  spinnerStatus = this.isloading.asObservable();

  constructor() { }

  _start = () => {
    this.isloading.next(true);
  };

  _stop = () => {
    this.isloading.next(false);
  };
}
