import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CustomValidatorHelper } from 'src/app/helpers/custom-validator.helper';
import { IClientInfoRequest } from 'src/app/models/client-info.interface';
import { environment } from 'src/environments/environment';
import { CidaasService } from 'src/service/cidaas.service';
import { IdValidatorService } from 'src/service/idvalidator.service';
import { SharedDataService } from 'src/service/shared-data.service';
import { SpinnerService } from 'src/service/spinner.service';
import { CompleteRegisterService } from '../../../../service/complete-register.service';
import { PictureUploadService } from '../../../../service/picture-upload.service';
import { Subscription } from 'rxjs';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { CommonService } from 'src/service/common.service';
import { RegexUtil } from 'src/app/helpers/regex.helper';
@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss']
})
export class UserAccountComponent implements OnInit {

  email_checked: boolean = false;
  primary_email: string
  alternative_email_checked: boolean = false;
  alternative_email: string;
  mobile_number: string;
  old_password: string = '';
  new_password: string = '';
  confirm_password: string = '';
  access_token: string = '';
  passwordPolicy: any;
  passwordPolicyError = { status: false, errors: [] };

  userDetails: any = {};
  requestId: string;
  // reset form
  resetForm: FormGroup;
  togglePasswords: any = [
    { id: 'old', inputType: 'password', show: false },
    { id: 'new', inputType: 'password', show: false },
    { id: 'confirm', inputType: 'password', show: false }
  ]
  imageChangedEvent: any = '';
  croppedImage: any = '';
  isShowingCropper = false;

  isPictureUploaded!: boolean;
  pictureTooLarge: boolean = false;
  isShowingPictureUploadedMsg!: boolean;
  isLoading!: boolean;
  campusSub!: string;
  campusCard: any = {};
  showQR: boolean = false;
  imgSrc: string = '';
  profilePicture: any;
  @ViewChild('myInput') myInputVariable: ElementRef;
  imgWidth: number;
  imgHeight: number;

  selectedEmail: any;
  private pictureUploadedSubscription: Subscription = new Subscription();
  private showPictureUploadedMsgSubscription: Subscription = new Subscription();
  private isLoadingSubscription: Subscription = new Subscription();
  constructor(private complete_register: CompleteRegisterService,private el: ElementRef, private router: Router, private toastr: ToastrService, private dataService: SharedDataService, private cidaasSDK: CidaasService, private formBuilder: FormBuilder, private translate: TranslateService, private _loader: SpinnerService, private pictureUploadService: PictureUploadService, private commonService: CommonService,private _regex: RegexUtil) {
  }

  ngOnInit(): void {
    this.requestId = localStorage.getItem('requestId')
    const urlParams = new URLSearchParams(window.location.hash.substring(1));
    let token = urlParams.get('access_token')
    if (token == '' || token == 'undefined' || token == null) {
      token = localStorage.getItem('access_token');
    }
    let decodedTokenSub = this.commonService.getDecodedAccessToken(token).sub;
    this.campusSub = decodedTokenSub ? decodedTokenSub : "";
    this.complete_register.setAccessToken(token);
    this.createResetPasswordForm();
    this.getClientInfo({ requestId: this.requestId });
    this._getPasswordPolicy();
    this.getUserInfo();
    this.getPassInfo();

    this.isLoadingSubscription = this.pictureUploadService.isLoading.subscribe(
      (loading) => {
        this.isLoading = loading;
      }
    );

    this.showPictureUploadedMsgSubscription =
      this.pictureUploadService.isShowingUploadedMessage.subscribe(
        (uploadMsg) => {
          this.isShowingPictureUploadedMsg = uploadMsg;
        }
      );

    this.pictureUploadedSubscription =
      this.pictureUploadService.isPictureUploaded.subscribe((picUploaded) => {
        this.isPictureUploaded = picUploaded;
      });

    this.pictureUploadService.pictureTooLarge.subscribe((picLarge) => {
      this.pictureTooLarge = picLarge;
    });
  }
  public getProfilePicture(sub:any) {
    this.pictureUploadService.getProfilePicture(sub).subscribe((image) => {
      this.createImageFromBlob(image);
    }, (error) => {
      console.log(error)
    });
  }

  private createImageFromBlob(image:any) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.profilePicture = reader.result;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }
  getUserInfo() {
    this._loader._start();
    this.complete_register.getUserInfo().then(data => {
      this._loader._stop();
      if (data) {
        this.userDetails = data;
        this.getProfilePicture(data.sub);
        this.mobile_number = this.userDetails.identities.length > 0 && this.userDetails.identities[0].mobile_number ? this.userDetails.identities[0].mobile_number : '';

        if (this.userDetails.customFields && this.userDetails.customFields.secondary_email && (this.userDetails.email != this.userDetails.customFields.secondary_email)) {

          this.primary_email = this.userDetails.email;
          this.alternative_email = this.userDetails.customFields && this.userDetails.customFields.secondary_email ? this.userDetails.customFields.secondary_email : '';
          this.alternative_email_checked = true;
          this.email_checked = false;
          this.selectedEmail = this.primary_email;
        }

        if (this.userDetails.customFields && (this.userDetails.customFields.secondary_email == 'undefined' || this.userDetails.customFields.secondary_email == null)) {

          this.primary_email = this.userDetails.email
          this.alternative_email = '';
          this.alternative_email_checked = false;
          this.email_checked = true;
          this.selectedEmail = "";
        }

        if (this.userDetails.customFields && this.userDetails.customFields.secondary_email && (this.userDetails.email == this.userDetails.customFields.secondary_email)) {
          this.primary_email = this.userDetails.email
          this.alternative_email = '';
          this.alternative_email_checked = false;
          this.email_checked = true;
          this.selectedEmail = "";
        }
      } else {
        this.toastr.error('Error Occured');
      }
    }, error => {
      this.toastr.error('Error Occured');
      this._loader._stop();
    })
  }

  getPassInfo() {
    this._loader._start();
    this.showQR = false;
    this.complete_register.getPassStatus(this.campusSub).subscribe((data: any) => {
      this._loader._stop();
      if (data && data.data.passes && data.data.passes.length > 0) {
        let passes = data.data.passes;
        for (let passDetail of passes) {
          if (passDetail && passDetail.pass && passDetail.pass.customFields) {
            let customFields = passDetail.pass.customFields;
            let passInfo = passDetail.pass;
            if (passInfo.productId == "campus_card" && passInfo.state === "card_self_service_pending_printing" && customFields.qr_code && customFields.task_id) {
              this.showQR = true;
              this.campusCard = passDetail;
              this.imgSrc = environment.base_url + "/kms-srv/qr?qr=" + customFields.qr_code + "&size=200";
              return;
            }
          }
        }
      }
      else {
        this.toastr.error('Error Occured');
      }
    }, (error: any) => {
      this._loader._stop();
      if (error) {
        this.toastr.error('Error Occured');
      }
    })
  }

  createResetPasswordForm() {
    this.resetForm = this.formBuilder.group({
      'old_password': ['', [Validators.required]],
      'new_password': [
        '',
        [
          Validators.required,
          Validators.pattern(
            this._regex.newEmailPattern
          ),
          Validators.minLength(8),
        ],
      ],
      'confirm_password': ['', [Validators.required]]
    });
  }

  getClientInfo(options: IClientInfoRequest): void {
    this.cidaasSDK._getClientInfo(options);
  }

  inputAlternativeEmail(event: any) {
    this.alternative_email = event.target.value
  }

  inputMobilenumber(event: any) {
    this.mobile_number = event.target.value
  }

  onChangeEmail(value: string) {
    if (value == "alternate") {
      this.alternative_email_checked = !this.alternative_email_checked;
      this.email_checked = false;

      this.selectedEmail = this.userDetails.email;
    }
    if (value == "email") {
      this.email_checked = !this.email_checked;
      this.alternative_email_checked = false;
      this.selectedEmail = this.userDetails.customFields.secondary_email;
    }

  }
  savePersonal() {
    if (this.alternative_email && (this.userDetails.email != this.alternative_email)) {
      this.userDetails.email = this.alternative_email;
      this.userDetails.email_verified = false;
      this.userDetails.customFields.secondary_email = this.primary_email;
    }
    this.userDetails.mobile_number = this.mobile_number ? this.mobile_number.replace(' ', '') : '';
    this._loader._start();
    this.complete_register.updateProfile(this.userDetails).subscribe(data => {
      this._loader._stop();
      if (data) {
        this.toastr.success('Updated Successfully');
        this.getUserInfo();
      } else {
        this.toastr.error('Error Occured');
      }
    }, error => {
      this.toastr.error('Error Occured');
      this._loader._stop();
    })
  }

  resetPassword() {

    if (this.resetForm.invalid) {
      const controls = this.resetForm.controls;
      Object.keys(controls).forEach(element => {
        if (this.resetForm.get(element).invalid) {
          this.resetForm.get(element).markAsDirty({ onlySelf: true });
        }
      });
      return;
    }

    let resetObject: any = {};
    resetObject.old_password = this.resetForm.get('old_password').value;
    resetObject.new_password = this.resetForm.get('new_password').value;
    resetObject.confirm_password = this.resetForm.get('confirm_password').value;
    resetObject.sub = this.userDetails.sub;
    resetObject.identityId = this.userDetails.last_used_identity_id;
    this._loader._start();
    this.complete_register.resetPassword(resetObject).subscribe(data => {
      this._loader._stop();
      if (data && data.success) {

        this.translate
          .get('ACCOUNT.PERSONAL.SUCCESSMSG')
          .subscribe((res) => {
            this.toastr.success(res);
          });
        this.old_password = '';
        this.new_password = '';
        this.confirm_password = '';
        this.redirectToLogin()
      } else {
        this.toastr.error('Error Occured');
      }
    }, error => {
      this.toastr.error('Error Occured'); this._loader._stop();
    })
  }

  redirectToLogin() {
    this.router.navigate(['/login'], {
      queryParams: {
        requestId: this.requestId
      },
    });
  }


  checkEmptyObject() {
    if (Object.keys(this.userDetails).length > 0 && this.userDetails) {
      return true;
    } else {
      return false
    }
  }
  redirectToAtlassian() {
    window.location.assign(
      environment.atlassian_url
    );
  }

  _getPasswordPolicy = () => {
    this.dataService._getClientInfo().subscribe((data) => {
      if (data && data.password_policy) {
        this.passwordPolicy = data.password_policy
      }
    });
  };


  get newPassword() {
    return this.resetForm.get('new_password');
  }
  get oldPassword() {
    return this.resetForm.get('old_password');
  }
  get confirmPassword() {
    return this.resetForm.get('confirm_password');
  }

  validatePassWord(event: any) {
    const password = event.target.value;
    let status = false;
    this.passwordPolicyError.errors = [];
    const error = [];
    if (this.passwordPolicy) {
      if (password.length < this.passwordPolicy.minimumLength && password.length !== 0) {

        this.translate
          .get('FORM.VALIDATION.PASSWORD.POLICY.CHAR_MIN', { count: this.passwordPolicy.minimumLength })
          .subscribe((res) => {
            error.push({ string: res });
          });

        status = true;
      } else if (password.length >= this.passwordPolicy.minimumLength && password.length <= this.passwordPolicy.maximumLength) {
        if (password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g) < this.passwordPolicy.noOfSpecialChars) {

          this.translate
            .get('FORM.VALIDATION.PASSWORD.POLICY.CHAR_SPECIAL', { count: this.passwordPolicy.noOfSpecialChars })
            .subscribe((res) => {
              error.push({ string: res });
            });
          status = true;
        } else if (password.match(/[0-9]/g) < this.passwordPolicy.noOfDigits) {

          this.translate
            .get('FORM.VALIDATION.PASSWORD.POLICY.CHAR_DIGIT', { count: this.passwordPolicy.noOfDigits })
            .subscribe((res) => {
              error.push({ string: res });
            });
          status = true;
        } else if (this.passwordPolicy.lowerAndUpperCase && password.match(/[a-z].*[A-Z]|[A-Z].*[a-z]/g) < 1) {

          this.translate
            .get('FORM.VALIDATION.PASSWORD.POLICY.CHAR_LOWER_UPPER', { count: 1 })
            .subscribe((res) => {
              error.push({ string: res });
            });

          status = true;
        } else {
          status = false;
        }
      } else if (password.length >= this.passwordPolicy.maximumLength) {
        this.translate
          .get('FORM.VALIDATION.PASSWORD.POLICY.CHAR_MAX', { count: this.passwordPolicy.maximumLength })
          .subscribe((res) => {
            error.push({ string: res });
          });
        status = true;
      } else {
        status = false;
      }

    } else {
      if (password.length > 20) {

        this.translate
          .get('FORM.VALIDATION.PASSWORD.POLICY.CHAR_MAX', { count: 20 })
          .subscribe((res) => {
            error.push({ string: res });
          });


        status = true;
      }
    }
    this.passwordPolicyError.errors = error;
    this.passwordPolicyError.status = status;
  }

  showPassword(id) {
    let field = this.togglePasswords.find(value => value.id == id);

    if (field.inputType === 'password') {
      field.inputType = 'text';
      field.show = true;
    } else {
      field.inputType = 'password';
      field.show = false;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    setTimeout(() => {
      const element = this.el.nativeElement.querySelector('.ngx-ic-overlay');

      // Check if the element is found
      if (element) {
        // Get the computed styles
        const styles = window.getComputedStyle(element);

        // Access specific style properties
        let width = styles.getPropertyValue('width');
        let height = styles.getPropertyValue('height');
        width = width.replace("px", "");
        height = height.replace("px", "")
        // this.imgWidth = parseFloat(width);
        this.imgHeight= parseFloat(height);
    }
    }, 10);
  }

  selectPicture(event: Event) {
    this.pictureUploadService.onSelect(event);
    this.imageChangedEvent = event;
  }

  uploadPicture() {
    this._loader._stop();
    this.userDetails.picture = this.croppedImage;
    this.pictureUploadService.onUpload(this.croppedImage, false);
    this.isShowingCropper = false;
    this.croppedImage = '';
    this._loader._start();
    this.reset();
    setTimeout(() => {
      this.getUserInfo();
    }, 5000);
  }

  imageLoaded() {
    // show cropper
    this.isShowingCropper = true;
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  cancelPhotoUpload() {
    this.isShowingCropper = false;
    this.imageChangedEvent ="";
    this.reset()
  }

  reset() {
    this.myInputVariable.nativeElement.value = "";
  }
  
  deletAltEmail() {
    if (this.alternative_email_checked && this.alternative_email && (this.userDetails.email != this.alternative_email)) {
      this.userDetails.email = this.userDetails.customFields.secondary_email;
      this.userDetails.email_verified = false;
      this.userDetails.customFields.secondary_email = null;
    }
    this.userDetails.mobile_number = this.mobile_number ? this.mobile_number.replace(' ', '') : '';
    this._loader._start();
    this.complete_register.updateProfile(this.userDetails).subscribe(data => {
      this._loader._stop();
      if (data) {
        this.toastr.success('Updated Successfully');
        this.getUserInfo();
      } else {
        this.toastr.error('Error Occured');
      }
    }, error => {
      this.toastr.error('Error Occured');
      this._loader._stop();
    })
  }
  ngOnDestroy() {
    this.isLoadingSubscription.unsubscribe();
    this.showPictureUploadedMsgSubscription.unsubscribe();
    this.pictureUploadedSubscription.unsubscribe();
  }

  generateNewQR() {
    this._loader._start();
    let requestBody =
    {
      "person": {
        "pass_id": this.campusCard.pass.passId,
        "task_id": this.campusCard.pass.customFields.task_id,
        "campus_id": this.campusCard.pass.customFields.campus_id
      }
    }
    this.complete_register.generateNewQR(requestBody).subscribe(data => {
      this._loader._stop();
      if (data && data.data && data.success) {
        let qrResponse = data.data.person;
        this.imgSrc = environment.base_url + "/kms-srv/qr?qr=" + qrResponse.qr_code + "&size=200";
      } else {
        this.toastr.error('Error Occured');
      }
    }, error => {
      this.toastr.error('Error Occured');
      this._loader._stop();
    })

  }

}
