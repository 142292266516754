<ng-container>
  <div class="d-flex login-content">
    <div class="col-md-6 d-flex left-side">
      <div class="logo">
        <app-logo></app-logo>
      </div>
    </div>

    <div class="col-md-6 d-flex flex-column right-side">
      <!-- screen 8 -->
      <div class="m-auto text-para">
        <h4 class="header-label">
          {{'Attention, here we are missing data' | translate}}</h4>
        <p>{{'To use this service, please add the missing required data.' | translate}}</p>
        <p class="font-weight-bold"></p>
        <form>
       
          <div class="d-flex justify-content-end col-12 pr-0">
            <button type="button" class="btn btn-common">{{'EXTRA_INFO.NEXT' | translate}}</button>
          </div>
        </form>
      </div>
      <div class="fixed-footer">
        <div class="justify-content-end d-flex col-12 col-lg-12 mx-auto">
          <div class="mr-auto d-flex ft-12 footer-nav">
            <div class="cursor">{{'FOOTER.IMPRINT' | translate}}</div>
            <div class="cursor">{{'FOOTER.TERMS_OF_USE_1' | translate}}</div>
            <div class="cursor">{{'FOOTER.TERMS_OF_USE_2' | translate}}</div>
            <div class="cursor">{{'FOOTER.POLICY' | translate}}</div>
            <div class="cursor">{{'FOOTER.LIABILITY' | translate}}</div>
          </div>

          <div class="d-flex flex-column">
            <span class="power-label">Powered by</span>
            <img src="assets/Icons/default-logo.png" alt="img" style="width: 80px; height: 35px" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>