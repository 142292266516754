import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/pages/login/login.component';
import { InitiateAuthenticationComponent } from './components/util/login/initiate-authentication/initiate-authentication.component';
import { InitiateLoginComponent } from './components/util/login/initiate-login/initiate-login.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { AccessDetailsComponent } from './components/pages/access-details/access-details.component';
import { LogoutComponent } from './components/pages/logout/logout.component';
import { DevicesComponent } from './components/pages/devices/devices.component';
import { ConsentComponent } from './components/pages/consent/consent.component';
import { CompleteRegisterComponent } from './components/util/complete-register/complete-register/complete-register.component';
import { FirstStepComponent } from './components/util/complete-register/pages/first-step/first-step.component';
import { SecondPageComponent } from './components/util/complete-register/pages/second-page/second-page.component';
import { ThirdStepComponent } from './components/util/complete-register/pages/third-step/third-step.component';
import { FourthStepComponent } from './components/util/complete-register/pages/fourth-step/fourth-step.component';
import { UserAccountComponent } from './components/pages/user-account/user-account.component';
import { ManageLoginComponent } from './components/pages/manage-login/manage-login.component';
import { InfoVideoIdentComponent } from './components/util/complete-register/pages/third-step/info-video-ident/info-video-ident.component';
import { AdditionalInfoComponent } from './components/util/complete-register/pages/third-step/additional-info/additional-info.component';
import { RegisterWithoutVideoIdentComponent } from './components/util/complete-register/pages/third-step/register-without-video-ident/register-without-video-ident.component';
import { ManualPictureUploadComponent } from './components/util/complete-register/pages/third-step/manual-picture-upload/manual-picture-upload.component';
import { ForgotPasswordInitComponent } from './components/pages/forgot-password-init/forgot-password-init.component';
import { PasswordSetComponent } from './components/pages/password-set/password-set.component';
import { PasswordSetSuccessComponent } from './components/pages/password-set-success/password-set-success.component';
import { ProfileUploadComponent } from './components/util/complete-register/pages/profile-upload/profile-upload.component';
import { LayoutComponent } from './layout/layout.component';
import { TranslateModule } from '@ngx-translate/core';
import { CampusCardComponent } from './components/pages/profile/campus-card/campus-card.component';
import { AuthGuard } from 'src/service/auth.guard';
import { VideoPreviewComponent } from './components/util/complete-register/pages/video-preview/video-preview.component';
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: '', component: LayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
        children: [
          { path: '', component: InitiateLoginComponent },
          { path: 'initiate', component: InitiateAuthenticationComponent },
        ],
      },
      { path: 'password_forgot_init', component: ForgotPasswordInitComponent },
      { path: 'password_set', component: PasswordSetComponent },
      { path: 'password_set_success', component: PasswordSetSuccessComponent },
      { path: 'register_additional_info', component: RegisterComponent },
      {
        path: 'complete-register',
        component: CompleteRegisterComponent,
        children: [
          { path: '', component: FirstStepComponent },
          { path: 'second-step', component: SecondPageComponent },
          { path: 'video-preview', component: VideoPreviewComponent },
          {
            path: 'third-step',
            component: ThirdStepComponent,
          },
          {
            path: 'info-video-ident',
            component: InfoVideoIdentComponent,
          },
          {
            path: 'additional-info',
            component: AdditionalInfoComponent,
          },
          {
            path: 'reg-without-video',
            component: RegisterWithoutVideoIdentComponent,
          },
          {
            path: 'manual-picture-upload',
            component: ManualPictureUploadComponent,
          },
          { path: 'fourth-step', component: FourthStepComponent }
        ],
      },
      { path: 'logout', component: LogoutComponent },
      {
        path: 'dashboard',
        component: DashboardComponent, canActivate: [AuthGuard]
      },
      { path: 'editprofile', component: ProfileComponent, canActivate: [AuthGuard] },
      { path: 'user-account', component: UserAccountComponent, canActivate: [AuthGuard] },
      { path: 'user-login', component: ManageLoginComponent, canActivate: [AuthGuard] },
      { path: 'access-details', component: AccessDetailsComponent, canActivate: [AuthGuard] },
      { path: 'devices-details', component: DevicesComponent },
      { path: 'profile-upload', component: ProfileUploadComponent, canActivate: [AuthGuard] },
      { path: 'profile-upload-status', component: ProfileUploadComponent, canActivate: [AuthGuard] },
      { path: 'consent', component: ConsentComponent },
      { path: 'campus-card', component: CampusCardComponent, canActivate: [AuthGuard] },
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false }), TranslateModule],
  exports: [RouterModule],
})
export class AppRoutingModule { }
