import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataServices {
  prefixArray: any = [];
  private country_prefix = new BehaviorSubject([]);
  prefix = this.country_prefix.asObservable();
  private formValue = new BehaviorSubject<any>({});

  constructor() { }

  _setCountryPrefix = (prefix) => {
    this.country_prefix.next(prefix);
  }

  _sendFormValue = (data: Partial<any>) => {
    this.formValue.next(data);
  };

  _getFormValue = () => {
    return this.formValue.asObservable();
  };

}
