<div>
  <h4 class="header-label">{{ "SECOND_PAGE.WELCOME_HEADER" | translate }}</h4>
  <p class="secondary-header-label">
    {{ "SECOND_PAGE.PAGE_LABEL" | translate }}
  </p>
  <div class="form-group mt-4" style="position: relative">
    <div class="form-group has-feedback">
      <div *ngIf="!passStatus">
        <form [formGroup]="f" (ngSubmit)="onFormSubmit()">
          <div class="second-form-container">
            <div class="input-fields-container">
              <label for="set_password" class="input-label">{{
                "SECOND_PAGE.SET_PASSWORD" | translate
                }}</label>
              <div class="form-field">
                <div class="set_Password">
                  <input class="password_input-field" id="set_password" formControlName="set_password" [type]="password"
                    placeholder="{{'SECOND_PAGE.PLACEHOLDER' | translate}}"
                    [ngClass]="{'error-form-control': f.get('set_password').invalid && (f.get('set_password').touched || f.get('set_password').dirty)}" />
                  <img class="pass-error-icon"
                    *ngIf="f.get('set_password').invalid && (f.get('set_password').touched || f.get('set_password').dirty)"
                    src="assets/img/icon-error.svg" style="width: 20px;" alt="">
                  <span class="form-cont-feedback show_Password_icon" (click)="showPassword()">
                    <img src="assets/img/eye-icon.svg" style="width: 20px;" alt="">
                    <!-- <i class="fa fa-eye" aria-hidden="true" *ngIf="!show"></i>
                    <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="show"></i> -->
                  </span>
                </div>
                <div
                  *ngIf="f.get('set_password').errors?.required && (f.get('set_password').touched || f.get('set_password').dirty)"
                  class="errMsg ft-12">
                  {{'REQUIRED_FIELD' | translate }}
                </div>
                <div *ngIf="
                    !f.get('set_password').valid &&
                    f.get('set_password').value.length > 0
                  " class="errMsg ft-12">
                  {{ "SECOND_PAGE.WRONG_PASSWORD_MESSAGE" | translate }}
                </div>
              </div>
            </div>

            <div class="input-fields-container">
              <label for="confirm_password" class="input-label">{{
                "SECOND_PAGE.REPEAT_PASSWORD" | translate
                }}</label>
              <div class="form-field">
                <div class="set_Password">
                  <input (keyup)="comparePasswords()" class="password_input-field" id="confirm_password"
                    formControlName="confirm_password" [type]="conPassword"
                    [ngClass]="{'error-form-control': (f.get('confirm_password').invalid || passwordNotMatch) && (f.get('confirm_password').touched || f.get('confirm_password').dirty)}"
                    placeholder="{{'SECOND_PAGE.PLACEHOLDER' | translate}}" />
                  <img class="pass-error-icon"
                    *ngIf="(f.get('confirm_password').invalid || passwordNotMatch) && (f.get('confirm_password').touched || f.get('confirm_password').dirty)"
                    src="assets/img/icon-error.svg" style="width: 20px;" alt="">
                  <span class="form-cont-feedback show_Password_icon" (click)="showConfirmPassword()">
                    <img src="assets/img/eye-icon.svg" style="width: 20px;" alt="">
                    <!-- <i class="fa fa-eye" aria-hidden="true" *ngIf="!confpasswordshow"></i>
                    <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="confpasswordshow"></i> -->
                  </span>
                </div>
                <div
                  *ngIf="f.get('confirm_password').errors?.required && f.get('confirm_password').value =='' && (f.get('confirm_password').touched || f.get('confirm_password').dirty)"
                  class="errMsg ft-12">
                  {{'REQUIRED_FIELD' | translate }}
                </div>
                <div *ngIf="passwordNotMatch && f.get('confirm_password').value" class="errMsg ft-12">
                  {{ "SECOND_PAGE.INVALID_PASSWORD" | translate }}
                </div>
              </div>
            </div>

            <div class="secondary-header-label">
              <span innerHTML="{{'SECOND_PAGE.NEW_EMAIL_HINT1' | translate}}"></span>
              <span style="color: #2B78A7;">{{currentEmail}}</span>
              <span innerHTML="{{'SECOND_PAGE.NEW_EMAIL_HINT3' | translate}}"></span>
            </div>

            <div class="input-fields-container">
              <label for="email" class="input-label">{{
                "SECOND_PAGE.PRIVATE_EMAIL" | translate
                }}</label>
              <div class="form-field">
                <input class="input-field" id="email" formControlName="email" type="email"
                  placeholder="{{'SECOND_PAGE.PLACEHOLDER' | translate}}" />
                <div *ngIf="f.get('email').value > 0 && f.get('email').invalid" class="errMsg ft-12">
                  {{ "SECOND_PAGE.INVALID_EMAIL" | translate }}
                </div>
              </div>
            </div>


            <div class="consent first-consent">
              <input class="consent-check" formControlName="accept_consent" type="checkbox" />
              <label class="consent-text" for="accept_consent">
                {{ "SECOND_PAGE.ACCEPT_CONSENT_1" | translate }}
                <a href="https://bildungscampus.hn/nutzungsbedingungen-bildungscampuslife?hidebanner=true"
                  class="custom-link link-intext">
                  {{ "SECOND_PAGE.CONSENT_LINK_1" | translate }}
                </a>
                {{ "SECOND_PAGE.ACCEPT_CONSENT_2" | translate }}
                <a href="https://bildungscampus.hn/leben-am-campus/campuscard/nutzungsbedingungen-campuscard-allgemein?hidebanner=true"
                  class="custom-link link-intext">{{ "SECOND_PAGE.CONSENT_LINK_2" | translate }}</a>{{ "SECOND_PAGE.DATA_POLICY" | translate }}
                <a href="https://bildungscampus.hn/datenschutzhinweise-bildungscampus-services?hidebanner=true" class="custom-link link-intext">
                  {{ "SECOND_PAGE.DATA_POLICY_LINK" | translate }}
                </a>
              </label>
            </div>
          </div>
          <br />

          <div class="bottom-btn">
            <div class="back-btn-align">
              <button type="button" class="navigate-btn" (click)=backClick()>
                <img src="assets/img/left-arrow.svg" alt="">
                {{ "LOGIN.AUTHENTICATION.BACK" | translate }}
              </button>
            </div>
            <div class="back-btn-align">
              <button type="submit" [ngClass]="getDisable()" [disabled]="f.invalid">
                {{ "COMPLETE_REGISTER.BUTTON_GOON" | translate }}
                <img *ngIf="f.invalid" src="assets/img/deactive-arrow.svg" alt="">
                <img style="margin-top: 6px;" *ngIf="!f.invalid" src="assets/img/active-arrow.svg" alt="">
              </button>
            </div>
          </div>

        </form>
      </div>
      <div *ngIf="passStatus">
        <h4>{{ "SECOND_PAGE.CARD_ACTIVE_MESSAGE" | translate }}</h4>
      </div>
      <div class="mt-5" *ngIf="passStatus">
        <div id="reset-btn">
          <button class="transparent-back-btn" type="button" (click)="redirectToAtlassian()">
             <span class="fa fa-arrow-left pr-9"></span>  {{ "ACCOUNT.PERSONAL.ATLASSIAN.ATLASSIAN_BTN" | translate }}
          </button>
      </div>
      </div>
    </div>
  </div>
</div>
