import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IMFAConfigList } from './../app/models/mfa.interface';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  private requestId = new BehaviorSubject<string>('');
  private baseDomain = new BehaviorSubject<string>('');
  private clientInfo = new BehaviorSubject<any>({});
  private language = new BehaviorSubject<string>('');
  private user = new BehaviorSubject<string>('');
  private mfaList = new BehaviorSubject<any>({});

  constructor() { }

  _getRequestId = () => {
    return this.requestId.asObservable();
  };

  // set requestId
  _setRequestId = (request_id: string) => {
    this.requestId.next(request_id);
  };

  // get baseURL
  _getBaseURL = () => {
    return this.baseDomain.asObservable();
  };

  // set baseURL
  _setBaseURL = (url: string) => {
    this.baseDomain.next(url);
  };

  // get ClientInfo
  _getClientInfo = () => {
    return this.clientInfo.asObservable();
  };

  // set ClientInfo
  _setClientInfo = (clientInfo: any) => {
    this.clientInfo.next(clientInfo);
  };

  // get language
  getLanguage() {
    return this.language.asObservable();
  };

  // set language
  setLanguage(language: string) {
    this.language.next(language);
    localStorage.setItem("lang", language);
  };

  _getUser = () => {
    return this.user.asObservable();
  };

  // set User
  _setUser = (user: string) => {
    this.user.next(user);
  };

  // get mfaList
  _getMFAList = () => {
    return this.mfaList.asObservable();
  };

  // set mfaList
  _setMFAList = (data: Partial<IMFAConfigList>) => {
    this.mfaList = new BehaviorSubject<any>({});
    this.mfaList.next(data);
  };
}
