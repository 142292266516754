<div class="input-fields-container" [formGroup]="group">
  <label>{{field?.localeText?.name}}:</label>
  <div *ngIf="field && field.fieldKey">
    <div class="date-input-container">
      <input #dpYMD="bsDatepicker" class="input-field date-input" [formControlName]="field.fieldKey" bsDatepicker
        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY',containerClass: 'theme-dark-blue'}" [name]="field.fieldKey"
        [readonly]="field?.readOnly" [placeholder]="field.localeText?.name" [minDate]="field.fieldDefinition.minDate"
        [maxDate]="field.fieldDefinition.maxDate" [required]="field.required">
      <div class="input-group-addon naked-grpAddon">
        <button (click)="dpYMD.toggle()" [attr.aria-expanded]="dpYMD.isOpen"
          class="form-cont-feedback show-password-calendar"><i class="fa fa-calendar"></i></button>
      </div>
    </div>
    <ng-container *ngFor="let validation of field.validation;" ngProjectAs="span">
      <span class="errMsg ft-12"
        *ngIf="group.get(field.fieldKey).hasError(validation.name) && group.get(field.fieldKey).touched">
        {{validation.message | translate}}
      </span>
    </ng-container>
  </div>
</div>