import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CompleteRegisterService {
  requestId!: string;
  accessToken!: string;
  identityId!: string;
  sub!: string;
  secondaryEmail!: string;
  baseURL: string = environment.base_url;

  //values from first page
  selectedFunction!: string;
  selectedMandator!: string;
  selectedNumber!: string;
  selectedPassword!: string;

  //values from second page

  setPassword!: string;
  confirmPassword!: string;
  newEmailAdress!: string;
  acceptConsent!: boolean;

  campusIdArray: string[] = [];
  campusId: string;
  emailProvided!: boolean;

  showNextPage = new BehaviorSubject<boolean>(false);
  showError = new BehaviorSubject<boolean>(false);
  showNumberError = new BehaviorSubject<boolean>(false);
  currentEmail = '';
  cidaasRoles: string[];

  constructor(
    private router: Router,
    private http: HttpClient
  ) { }

  setRequestId(request_id: string) {
    localStorage.setItem('request_id', request_id);
  }
  getRequestId() {
    let request_id = localStorage.getItem('request_id');
    return (this.requestId = request_id);
  }

  setProfile() {
    if (this.selectedMandator.length === 1) {
      return '0' + this.selectedMandator;
    } else {
      return this.selectedMandator;
    }
  }
  setNumber() {
    let leadingZero = '0';
    if (this.selectedNumber.length === 7) {
      return this.selectedNumber;
    } else {
      for (let i = 0; i < 6 - this.selectedNumber.length; i++) {
        leadingZero = leadingZero + '0';
      }
      return leadingZero + this.selectedNumber;
    }
  }
  setCampusId() {
    let letters = ['A', 'B', 'C', 'D', 'F'];
    letters.forEach((element) => {
      return this.campusIdArray.push(
        this.setProfile() + element + this.setNumber()
      );
    });
  }
  async checkLogin() {
    this.setCampusId();
    for (let i = 0; i < this.campusIdArray.length; i++) {
      this.campusId = this.campusIdArray[i];
      let response_status;
      try {
        await this.login()
          .toPromise()
          .then((response) => {
            response_status = response.status;
            this.accessToken = response.data.access_token;
          });
        if (response_status == 200) {
          localStorage.setItem('access_token', this.accessToken);
          this.setShowNextPage();
          break;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  login(): Observable<any> {
    const login_url = this.baseURL + '/login-srv/login/sdk';
    return this.http.post<any>(
      login_url,
      {
        username: this.campusId,
        password: this.selectedPassword,
        requestId: this.getRequestId(),
        username_type: 'custom',
        field_key: 'campus_id',
      },
      { headers: this.getDefaultHeader() }
    );
  }

  async changePwdEmail() {
    let user_update_success = true;
    try {
      await this.changeEmail()
        .toPromise()
        .then((result) => {
          if (result.status != 200) {
            user_update_success = false;
          }
        });
    } catch (error) {
      user_update_success = false;
      console.log(error);
    }
    try {
      await this.changePassword()
        .toPromise()
        .then((result) => {
          if (result.status != 200) {
            user_update_success = false;
          } else {
            this.selectedPassword = this.setPassword;
          }
        });
    } catch (error) {
      user_update_success = false;
      console.log(error);
    }
    await this.checkLogin();
    if (user_update_success) {
      this.setShowNextPage();
    }
  }

 getUserInfo(): Promise<any> {
    const userinfo_url = this.baseURL + '/users-srv/userinfo';
    return this.http.get<any>(userinfo_url, {
      headers: this.getUserInfoHeader(),
    }).toPromise();
  }

  async getPassId(id): Promise<any> {
    const pass_url = this.baseURL + '/pass-srv/pass/' + id;
    return await this.http.get<any>(pass_url, {
      headers: this.getUserAuthHeader(),
    }).toPromise();
  }

  setUserInfo(sub, identityId, currentEmail, secondaryEmail, cidaasRoles) {

    this.sub = sub;
    this.identityId = identityId;
    this.currentEmail = currentEmail;
    this.secondaryEmail = secondaryEmail;
    this.cidaasRoles = cidaasRoles;
  }

  changePassword(): Observable<any> {
    const change_psw_url = this.baseURL + '/users-srv/changepassword';
    return this.http.post<any>(
      change_psw_url,
      {
        new_password: this.setPassword,
        confirm_password: this.confirmPassword,
        old_password: this.selectedPassword,
        sub: this.sub,
        identityId: this.identityId,
      },
      { headers: this.getUserAuthHeader() }
    );
  }

  changeEmail(): Observable<any> {
    const email_url = this.baseURL + '/users-srv/user/profile/' + this.getSub();
    let email_data!: Object;
    console.log('New email', this.newEmailAdress);
    if (this.newEmailAdress) {
      if (this.secondaryEmail) {
        email_data = {
          provider: 'self',
          sub: this.getSub(),
          email: this.newEmailAdress,
          email_verified: false,
          customFields: {
            bildungscampus_terms: true,
            kost_terms: false,
            status: 'user_registered',
          }
        };
      } else {
        email_data = {
          provider: 'self',
          sub: this.getSub(),
          email: this.newEmailAdress,
          email_verified: false,
          customFields: {
            secondary_email: this.currentEmail,
            bildungscampus_terms: true,
            kost_terms: false,
            status: 'user_registered',
          }
        };
      }
    } else {
      email_data = {
        provider: 'self',
        sub: this.getSub(),
        customFields: {
          secondary_email: this.currentEmail,
          bildungscampus_terms: true,
          kost_terms: false,
          status: 'user_registered',
        }
      };
    }
    return this.http.put<any>(email_url, email_data, {
      headers: this.getUserAuthHeader(),
    });
  }

  updateUserStatus() {
    const status_url = this.baseURL + '/users-srv/user/' + this.getSub();
    let status_data!: Object;
    status_data = {
      provider: 'self',
      sub: this.getSub(),
      customFields: {
        user_status: 'user_ready_to_print'
      },
    };
    return this.http.put<any>(status_url, status_data, {
      headers: this.getUserAuthHeader(),
    });
  }

  updatePassStatus(passId, sub) {
    const status_url = this.baseURL + '/pass-srv/pass/card_ready_to_print/' + passId;
    return this.http.put<any>(status_url, {}, {
      headers: this.getUserAuthHeader(),
    });
  }
  getPassStatus(sub) {
    const status_url = this.baseURL + '/pass-srv/pass/sub/' + sub;

    return this.http.get<any>(status_url, {
      headers: this.getUserAuthHeader(),
    });
  }

  setCampusCardLost(passId) {
    const status_url = this.baseURL + '/pass-srv/pass/card_lost/' + passId;

    return this.http.put<any>(status_url, {}, {
      headers: this.getUserAuthHeader(),
    });
  }

  sendEmail(options: any): Observable<any> {
    const sendEmailUrl = this.baseURL + '/notification-srv/email/send';

    let emailData!: Object;
    emailData = {
      to: [options.toEmail],
      from: "noreply@bildungscampus.life",
      fromname: 'Bildungscampus',
      templateKey: options.template,
      userEmailObject: [
        {
          to: options.toEmail,
          emailObject: {
            content: {
              name: options.name,
              campus_id: options.campus_id, 
              email: options.toEmail,
              qr_code: options.qr_code ? options.qr_code: ''
            },
          },
        },
      ],
    };
    return this.http.post<any>(sendEmailUrl, emailData, {
      headers: this.getSendMailHeader(),
    });
  }

  getUserInfoProfile() {
    const status_url = this.baseURL + '/users-srv/internal/userinfo/profile';

    return this.http.get<any>(status_url, {
      headers: this.getUserAuthHeader(),
    });
  }
  updatekost_terms(sub, payLoad) {
    const status_url = this.baseURL + '/users-srv/user/profile/' + sub;

    return this.http.put<any>(status_url, payLoad, {
      headers: this.getUserAuthHeader(),
    });
  }
  saveTransaction(payLoad) {
    const status_url = this.baseURL + '/kost-srv/transaction';
    this.router.navigate(['/campus-card']);
    return this.http.post<any>(status_url, payLoad, {
      headers: this.getUserAuthHeader(),
    });
  }

  generateNewQR(payLoad) {
    return this.http.post<any>( this.baseURL + '/kms-srv/generateqr', payLoad, {
      headers: this.getUserAuthHeader(),
    });
  }

  getDefaultHeader() {
    return new HttpHeaders().set('content-type', 'application/json');
  }
  getEncodedHeader() {
    return new HttpHeaders().set('content-type', 'x-www-form-urlencoded');
  }
  getUserInfoHeader() {
    return new HttpHeaders()
      .set('content-type', 'x-www-form-urlencoded')
      .set('Authorization', 'Bearer ' + this.getAccessToken());
  }
  getUserAuthHeader() {
    return new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', 'Bearer ' + this.getAccessToken());
  }
  getAccessToken() {
    return localStorage.getItem('access_token')!;
  }

  setAccessToken(token) {
    localStorage.setItem('access_token', token);
  }

  getSendMailHeader() {
    return new HttpHeaders()
      .set('accept', 'application/json')
      .set('Authorization', 'Bearer ' + this.getAccessToken());
  }

  getSub() {
    return this.sub;
  }
  setShowError() {
    this.showError.next(false);
    this.showNumberError.next(false);
  }
  setShowNextPage() {
    this.showNextPage.next(true);
  }
  resetShowNextPage() {
    this.showNextPage.next(false);
  }
  getShowNextPage() {
    let value;
    this.showNextPage.subscribe((e) => (value = e));
    return value;
  }

  updateProfile(data: any): Observable<any> {
    let sub = ''
    if (data && data.sub != '' && data.sub && data.sub != 'undefined') {
      sub = data.sub;
    } else {
      sub = this.getSub();
    }
    const email_url = this.baseURL + '/users-srv/user/profile/' + sub;

    return this.http.put<any>(email_url, data, {
      headers: this.getUserAuthHeader(),
    });
  }

  revokeKostTerm(data: any): Observable<any> {
    const revoke_term_url = this.baseURL + '/kost-srv/revoke-terms';
    return this.http.post<any>(
      revoke_term_url, data,
      { headers: this.getUserAuthHeader() }
    );
  }

  resetPassword(data: any): Observable<any> {
    const change_psw_url = this.baseURL + '/users-srv/changepassword';
    return this.http.post<any>(
      change_psw_url, data,
      { headers: this.getUserAuthHeader() }
    );
  }
}
function lastValueFrom(arg0: Observable<any>): any {
  throw new Error('Function not implemented.');
}

