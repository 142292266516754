import { Injectable } from '@angular/core';
import * as data from '../../src/assets/i18n/de.json';
import * as dataen from '../../src/assets/i18n/en.json';
import {SharedDataService} from './shared-data.service';
@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  language: string;
  localnavigator: any = <any>window.navigator;
  dataDe: any = data;
  dataEn: any = dataen;

  constructor(private dataservice: SharedDataService) {
    this.language = navigator.language;
    if (!this.language) {
      this.language = this.localnavigator.systemLanguage;
    }
    if (this.language !== 'de' && this.language !== 'en') {
      this.language = 'en';
    }

    this.dataservice.getLanguage().subscribe((data) => {
      if (data) {
        this.language = data;
      }
      else{
        this.language = this.localnavigator.language; 
      }
    },(error: any) => {
      this.language = this.localnavigator.language; 
    });
   }
   getTranslatedMessage(message: string) {
    let dataToReturn: any;
    if (this.language.indexOf('de') >= 0) {
      dataToReturn = this.dataDe[message];
    } else if (this.language.indexOf('en') >= 0) {
      dataToReturn = this.dataEn[message];
    } else {
      dataToReturn = this.dataDe[message];
    }

    if (dataToReturn == null) {
      return message;
    }
    // if (typeof dataToReturn == 'string') {
    //   dataToReturn = ' ' + dataToReturn + ' ';
    // }
    return dataToReturn;
  }

  getLanguage() {
    return this.language;
  }
}
