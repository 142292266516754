<div class="d-flex flex-column col-12 col-lg-12 mx-auto card-content pl-0 pr-0 pt-0">
    <div class="form-content">
        <div *ngIf="!isAgree && !isAgreeGingcoTerms">
            <div class="">
                <div id="reset-btn">
                    <button class="transparent-back-btn" type="button" (click)="redirectToAtlassian()">
                       <span class="fa fa-arrow-left pr-9"></span>  {{ "ACCOUNT.PERSONAL.ATLASSIAN.ATLASSIAN_BTN" | translate }}
                    </button>
                </div>
            </div>
            <div class="header-content">
                <h1 class="header-label pt-4">{{
                    "LOGIN_SERVICE.HEADING_LABEL" | translate
                    }}</h1>
            </div>
            <div class="personal-part row">
                <div class="col-lg-8 col-md-6 col-sm-6 pr-0">
                    <h2 class="header-label text-title pt-2 mt-0 mb-0">{{
                        "LOGIN_SERVICE.BUILDUNGSCAMPUS_TERMS_HEADING" | translate
                        }}</h2>
                        <p class="ft-16">
                            {{ "LOGIN_SERVICE.BUILDUNGSCAMPUS_TERMS_SUB_HEADING_1" | translate }}
                            <a class="custom-link" href="https://bildungscampus.hn/leben-am-campus/campuscard/nutzungsbedingungen-campuscard-allgemein">
                                {{ "LOGIN_SERVICE.BUILDUNGSCAMPUS_TERMS_SUB_HEADING_LINK_1" | translate }}
                            </a>
                            <br>
                            <a class="custom-link" href="https://bildungscampus.hn/nutzungsbedingungen-bildungscampuslife" >
                                {{ "LOGIN_SERVICE.BUILDUNGSCAMPUS_TERMS_SUB_HEADING_LINK_2" | translate }}
                            </a>
                        </p>
                </div>
                <div class="d-flex col-lg-4 col-md-6 col-sm-6 pr-0 mt-4">
                    <div class="round">
                        <input class="input-checkbox custom-checkbox" id="checkbox" type="checkbox" name="term"
                        (change)="checkTerms($event)" [checked]="true" disabled>
                        <label for="checkbox" class="custom-control-gender-label custom-checkbox-label"></label>

                    </div>
                    <label class="custom-control-gender-label ml-5 ft-14 checkbox-label">{{'LOGIN_SERVICE.YES' | translate}}</label>
                </div>

            </div>
            <div>
                <p class="custom-text">
                    {{"LOGIN_SERVICE.KOST_TERMS_INFO" | translate}}
                    <a href="mailto:campuscard@mail.schwarz" class="custom-link">{{"LOGIN_SERVICE.KOST_MAILTO" | translate}} </a>
                    {{"LOGIN_SERVICE.KOST_TERM_PART1" | translate}}
                </p>
            </div>
            <hr>
            <div class="personal-part row custom-margin">
                <div class="col-lg-8 col-md-6 col-sm-6 pr-0">
                    <h2 class="header-label text-title pt-2 mt-0 mb-3">{{
                        "LOGIN_SERVICE.KOST_TERMS_HEADING" | translate
                        }}</h2>
                        <a *ngIf="kost_terms" class="custom-link l-height pt-5 ft-16" href="https://bildungscampus.hn/leben-am-campus/campuscard/nutzungsbedingungen" >
                            {{ "LOGIN_SERVICE.BUILDUNGSCAMPUS_TERMS_SUB_HEADING_LINK_PAYMENT" | translate }}
                        </a>
                        <div *ngIf="kost_terms" class="revoke-consent-btn mt-4" (click)="handleChange(true)"><img src="assets/img/delete.svg" class="delete-icon">{{ "LOGIN_SERVICE.REVOKECONSENT_BTN" | translate }}</div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 pr-0" *ngIf="kost_terms">
                    <div class="d-flex mt-3">
                        <div class="round">
                            <input class="input-checkbox" type="checkbox" id="test1" name="radio-group" [checked]="true" disabled
                                 >
                            <label for="test1" class="custom-control-gender-label custom-checkbox-label"></label>
                        </div>
                        <label class="custom-control-gender-label ml-4 ft-14 checkbox-label">{{'LOGIN_SERVICE.YES' | translate}}</label>
                    </div>
                </div>
                <div class="mx-2" *ngIf="!kost_terms">
                    <div class="personal-part row pl-3">
                        <div class="consent-email">
                            <div class="empty-cont"></div>
                            <label class="checkBox_container mt-1">
                                <input class="consent-check" type="checkbox" name="kost_terms"
                                (change)="onRevokeConsentClick($event, 'kost_terms')" />
                                <span class="checkmark"></span>
                                <label class="consent-text normal-text" >
                                    {{ "LOGIN_SERVICE.ACCEPT_PAYMENT_KOST_TERMS" | translate }}
                                    <a class="custom-link link-intext" href="https://bildungscampus.hn/leben-am-campus/campuscard/nutzungsbedingungen" >
                                        {{ "LOGIN_SERVICE.BUILDUNGSCAMPUS_TERMS_SUB_HEADING_LINK_PAYMENT" | translate }}
                                    </a>
                                </label>
                                
                            </label>
                        </div>
                    </div>
                    <div class="form-group input-section mt-3">
                        <div class="left-btn">
                            <button [ngClass]="{'transparent-btn1 top-0': isrevokeConsentContinueBtnDisabled, 'custom-primary-btn' : !isrevokeConsentContinueBtnDisabled }" type="button" [disabled]="isrevokeConsentContinueBtnDisabled" (click)="setConsent(true, true, 'kost_terms')">
                                {{ "LOGIN_SERVICE.AGREE_CONFIRM_BTN" | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <hr>
            <div class="personal-part row custom-margin">
                <div class="col-lg-8 col-md-6 col-sm-6 pr-0">
                    <h2 class="header-label text-title pt-2 mt-0 mb-3">{{
                        "LOGIN_SERVICE.GINGCO_TERMS_HEADING" | translate
                        }}</h2>
                        <a *ngIf="gingco_terms" class="custom-link l-height pt-5 ft-16" href="https://bildungscampus.hn/leben-am-campus/campuscard/nutzungsbedingungen" >
                            {{ "LOGIN_SERVICE.BUILDUNGSCAMPUS_GINGCO_TERMS_SUB_HEADING_LINK_PAYMENT" | translate }}
                        </a>
                        <div *ngIf="gingco_terms" class="revoke-consent-btn mt-4" (click)="handleGingcoTermsChange(true)"><img src="assets/img/delete.svg" class="delete-icon">{{ "LOGIN_SERVICE.REVOKECONSENT_BTN" | translate }}</div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 pr-0" *ngIf="gingco_terms">
                    <div class="d-flex mt-3">
                        <div class="round">
                            <input class="input-checkbox" type="checkbox" id="gingco_term" name="radio-group_gingco" [checked]="true" disabled
                                 >
                            <label for="gingco_term" class="custom-control-gender-label custom-checkbox-label"></label>
                        </div>
                        <label class="custom-control-gender-label ml-4 ft-14 checkbox-label">{{'LOGIN_SERVICE.YES' | translate}}</label>
                    </div>
                </div>
                <div class="mx-2" *ngIf="!gingco_terms">
                    <div class="personal-part row pl-3">
                        <div class="consent-email">
                            <div class="empty-cont"></div>
                            <label class="checkBox_container mt-1">
                                <input class="consent-check" type="checkbox" name="gingco_terms"
                                (change)="onRevokeConsentClick($event, 'gingco_terms')" />
                                <span class="checkmark"></span>
                                <label class="consent-text normal-text" >
                                    {{ "LOGIN_SERVICE.ACCEPT_PAYMENT_GINGCO_TERMS" | translate }}
                                    <a class="custom-link link-intext" href="https://bildungscampus.hn/leben-am-campus/campuscard/nutzungsbedingungen" >
                                        {{ "LOGIN_SERVICE.BUILDUNGSCAMPUS_GINGCO_TERMS_SUB_HEADING_LINK_PAYMENT" | translate }}
                                    </a>
                                </label>
                                
                            </label>
                        </div>
                    </div>
                    <div class="form-group input-section mt-3">
                        <div class="left-btn">
                            <button [ngClass]="{'transparent-btn1 top-0': isrevokeGingcoConsentContinueBtnDisabled, 'custom-primary-btn' : !isrevokeGingcoConsentContinueBtnDisabled }" type="button" [disabled]="isrevokeGingcoConsentContinueBtnDisabled" (click)="setConsent(true, true, 'gingco_terms')">
                                {{ "LOGIN_SERVICE.AGREE_CONFIRM_BTN" | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="isAgree">
            <div class="revokeconsent" >
                <div class="header-content  pt-4">
                    <h1 class="header-label text-title heading-bold ft-24 pb-0">{{
                        "LOGIN_SERVICE.REVOKECONSENT_HEADING" | translate
                        }}</h1>
                </div>
                <div class="contact">
                    <h1 class="sub-heading header-label ft-18">{{
                        "LOGIN_SERVICE.REVOKECONSENT_SUBHEADING" | translate
                        }}</h1>
                </div>
                <div class="personal-part">
                    <div class="pr-0 normal-text">
                        <p class="ft-16">{{
                            "LOGIN_SERVICE.KOST_REVOKE1" | translate
                            }}</p>
    
                        <p class="ft-16">{{
                            "LOGIN_SERVICE.KOST_REVOKE2" | translate
                            }}
                            <b>
                                {{ "LOGIN_SERVICE.KOST_REVOKE3" | translate }}
                            </b>
                        </p>
                    </div>
    
                </div>
                <div class="personal-part row pt-4">
                    <div class="col-lg-12 col-md-12 col-sm-12 pr-0 normal-text">
                        <a class="custom-primary-btn" href="https://bildungscampus.cards/ppSSO/">
                            {{ "LOGIN_SERVICE.PAYMENTPORTAL_BTN" | translate }} <img class="save-btn pl-1"  src="assets/img/external-link.svg">
                        </a>
    
                    </div>
    
                </div>
                <div class="personal-part row pl-3 consent-text">
                    <div>
                        <div class="empty-cont"></div>
                        <label class="checkBox_container mt-5">
                            <input class="consent-check" type="checkbox"
                            (change)="onConsentClick($event)" />
                            <span class="checkmark"></span>
                            <label class="normal-text ml-3">
                                {{ "LOGIN_SERVICE.PAYMENT_KOST_TERMS_1" | translate }}
                                <a class="link-intext" href="https://bildungscampus.hn/leben-am-campus/campuscard/nutzungsbedingungen">
                                    {{ "LOGIN_SERVICE.PAYMENT_KOST_TERMS_LINK" | translate }}
                                </a>
                                <label class="normal-text">
                                    {{ "LOGIN_SERVICE.PAYMENT_KOST_TERMS_2" | translate }}
                                </label>
                            </label>
                            
                        </label>
                      </div>
                </div>
                <div class="form-group input-section mt-5">
                    <div class="align-btn left-btn">
                        <button class="transparent-back-btn" type="button" (click)="setConsent(false, false, 'kost_terms')">
                            <span class="fa fa-arrow-left pr-9"></span> {{ "LOGIN_SERVICE.CANCEL_BTN" | translate }}
                        </button>
                    </div>
                    <div class="align-btn right-btn">
                        <button [ngClass]="{'transparent-btn1 top-0': isContinueBtnDisabled, 'custom-primary-btn' : !isContinueBtnDisabled }" type="button" [disabled]="isContinueBtnDisabled" (click)="setConsent(true, false, 'kost_terms')">
                            {{ "LOGIN_SERVICE.REVOKE_BTN" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="isAgreeGingcoTerms">
            <div class="revokeconsent" >
                <div class="header-content  pt-4">
                    <h1 class="header-label text-title heading-bold ft-24 pb-0">{{
                        "LOGIN_SERVICE.REVOKECONSENT_HEADING" | translate
                        }}</h1>
                </div>
                <div class="contact">
                    <h1 class="sub-heading header-label ft-18">{{
                        "LOGIN_SERVICE.REVOKE_GINGCO_TERMS_SUBHEADING" | translate
                        }}</h1>
                </div>
                <div class="personal-part">
                    <div class="pr-0 normal-text">
                        <p class="ft-16">{{
                            "LOGIN_SERVICE.GINGCO_REVOKE1" | translate
                            }}</p>
    
                        <p class="ft-16">{{
                            "LOGIN_SERVICE.GINGCO_REVOKE2" | translate
                            }}
                            <b>
                                {{ "LOGIN_SERVICE.GINGCO_REVOKE3" | translate }}
                            </b>
                        </p>
                    </div>
                </div>
                <div class="personal-part row pt-4">
                    <div class="col-lg-12 col-md-12 col-sm-12 pr-0 normal-text">
                        <a class="custom-primary-btn" href="https://bildungscampus.cards/ppSSO/">
                            {{ "LOGIN_SERVICE.PAYMENTPORTAL_BTN" | translate }} <img class="save-btn pl-1"  src="assets/img/external-link.svg">
                        </a>
                    </div>
                </div>
                <div class="personal-part row pl-3 consent-text">
                    <div>
                        <div class="empty-cont"></div>
                        <label class="checkBox_container mt-5">
                            <input class="consent-check" type="checkbox"
                            (change)="onGingcoTermsConsentClick($event)" />
                            <span class="checkmark"></span>
                            <label class="normal-text ml-3">
                                {{ "LOGIN_SERVICE.PAYMENT_GINGCO_TERMS_1" | translate }}
                                <a class="link-intext" href="https://bildungscampus.hn/leben-am-campus/campuscard/nutzungsbedingungen">
                                    {{ "LOGIN_SERVICE.PAYMENT_GINGCO_TERMS_LINK" | translate }}
                                </a>
                                <label class="normal-text">
                                    {{ "LOGIN_SERVICE.PAYMENT_GINGCO_TERMS_2" | translate }}
                                </label>
                            </label>
                            
                        </label>
                      </div>
                </div>
                <div class="form-group input-section mt-5">
                    <div class="align-btn left-btn">
                        <button class="transparent-back-btn" type="button" (click)="setConsent(false, false, 'gingco_terms')">
                            <span class="fa fa-arrow-left pr-9"></span> {{ "LOGIN_SERVICE.CANCEL_BTN" | translate }}
                        </button>
                    </div>
                    <div class="align-btn right-btn">
                        <button [ngClass]="{'transparent-btn1 top-0': isContinueGingcoBtnDisabled, 'custom-primary-btn' : !isContinueGingcoBtnDisabled }" type="button" [disabled]="isContinueGingcoBtnDisabled" (click)="setConsent(true, false, 'gingco_terms')">
                            {{ "LOGIN_SERVICE.REVOKE_BTN" | translate }}
                        </button>
                    </div>
                </div>
    
            </div>
        </div>
    </div>
</div>
