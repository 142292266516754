import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InputGroup } from '../../../../models/input-group.model';

@Component({
  selector: 'app-input-group',
  inputs: [
    'controlPath: model',
    'inputLabel: label',
    'inputName: name',
    'inputPlaceholder: placeholder',
    'inputReadonly: readonly',
  ],
  templateUrl: './input-group.component.html',
  styleUrls: ['./input-group.component.scss'],
})
export class InputGroupComponent implements OnInit {
  @Input() widthChange: boolean;
  inputName: string;

  @Output() updateUser = new EventEmitter();
  @Output() onSubmit = new EventEmitter();
  @Input('btnType') buttonType: string;
  @Input() inputType: string;
  input: InputGroup = {
    value: "" 
  }
  @Input() options = { input: this.input};
  userId: string;
  errorMsg: string;
  @Output() btnEvent = new EventEmitter<boolean>();
  @Output() inputEvent = new EventEmitter<string>();
  showHidePasswordicon
  constructor() { }


  show = false;
  ngOnInit() {
    if (this.inputType == 'text') {
      this.showHidePasswordicon = false;
    }
    else {
      this.showHidePasswordicon = true;
    }
  }

  getClass() {
    if (this.options?.input?.error) {
      return 'error-form-control';
    }
  }

  inputChange(event) {
    this.userId = event.target.value.trim();
    if (this.userId) {
      this.updateUser.emit(this.userId);
    }
  }

  onButtonClick() {
    if (this.userId) {
      this.onSubmit.emit(true);
    } else {
      this.onSubmit.emit(true);
    }
  }
  emitClick(e): void {
    e.preventDefault();
    e.stopPropagation();
    this.btnEvent.emit(true);
  }
  
  showPassword() {
    if (this.inputType === 'password') {
      this.inputType = 'text';
      this.show = true;
    } else {
      this.inputType = 'password';
      this.show = false;
    }
  }

}
