import { Injectable } from "@angular/core";
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpResponse
} from "@angular/common/http";
import { SpinnerService } from "./spinner.service";
import { tap } from "rxjs/operators";
@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
    constructor(private _loaderService: SpinnerService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler) {
        this._loaderService._start();
        return next.handle(request).pipe(
            tap(
                req => {
                    if (req instanceof HttpResponse) {
                        this._loaderService._stop();
                    }
                },
                err => {
                    this._loaderService._stop();
                }
            )
        );
    }
}
