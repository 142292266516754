<div class="d-flex flex-column col-12 col-lg-6 mx-auto card-content">
  <h4 class="header-label text-title py-4"> {{'CONSENT.HEADING' | translate}}</h4>
  <ul class="list-unstyled">
    <li class="media">
      <img
        class="mr-3"
        class="icon-img"
        src="../../../../../assets/Icons/newsletter.svg"
        alt="image"
      />
      <div class="media-body">
        <h6 class="mt-0 mb-1 color">Newsletterv2.0</h6>
        <p class="ft-12">
          bestatige deine identitat mit einum zweitein faktor.
        </p>
        <div class="d-flex justify-content-between col-12 border-bottom">
          <p>Zustimmungen am:01.01.2021 um 7:43 uhr</p>
          <i class="fa fa-ban"></i>
        </div>
      </div>
    </li>

    <li class="media my-4">
      <img
        class="mr-3"
        class="icon-img"
        src="../../../../../assets/Icons/data.svg"
        alt="image"
      />
      <div class="media-body">
        <h6 class="mt-0 mb-1 color">Datenschutzv47.11</h6>
        <p class="ft-12">Ipsum dolor sit amet,consecutor adiplicit elit,</p>
        <div class="d-flex justify-content-between col-12">
          <p>Zustimmungen am:01.01.2021 um 7:43 uhr</p>
          <i class="fa fa-ban"></i>
        </div>
      </div>
    </li>
  </ul>
</div>
