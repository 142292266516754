<div class="d-flex flex-column col-12 col-lg-6 mx-auto card-content">
  <div class="form-content">
    <div class="header-content">
      <h4 class="header-label text-title">User Data</h4>
    </div>
    <form>
      <div class="form-row">
        <div class="form-group col-md-6">
          <input type="text" class="form-control" placeholder="max" />
        </div>
        <div class="form-group col-md-6">
          <input type="text" class="form-control" placeholder="max" />
        </div>
        <div class="form-group col-md-6">
          <input type="text" class="form-control" placeholder="max" />
        </div>
        <div class="form-group col-md-6">
          <input type="text" class="form-control" placeholder="max" />
        </div>
        <div class="form-group col-md-6">
          <input type="text" class="form-control" placeholder="max" />
        </div>
        <div class="form-group col-md-6">
          <input type="text" class="form-control" placeholder="max" />
        </div>
      </div>
    </form>
  </div>
</div>
