import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  menus: any = [
    {
      "menuLabel":"Payment Portal",
      "link":"https://e.bildungscampus.cards/ppSSO/",
      "icon":"assets/Icons/payment.svg"
    },
    {
      "menuLabel":"Atlassian RocketChat",
      "link":"https://chat-test.bildungscampus.life",
      "icon":"https://www.atlasteam.de/files/13467680/13468379/1/1575886425521/Atlassian-Schulungen-Seminare-und-Trainings.png"
    },
    {
      "menuLabel":"Atlassian Jira",
      "link":"https://work-test.bildungscampus.life",
      "icon":"https://www.atlasteam.de/files/13467680/13468379/1/1575886425521/Atlassian-Schulungen-Seminare-und-Trainings.png"
    },
    {
      "menuLabel":"Atlassian Confluence",
      "link":"https://bildungscampus.life",
      "icon":"https://www.atlasteam.de/files/13467680/13468379/1/1575886425521/Atlassian-Schulungen-Seminare-und-Trainings.png"
    }
  ]
  constructor() { }

  ngOnInit(): void {

  }

}
