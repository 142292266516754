<h6 class="trans-btns-container">
  <span
    [ngClass]="isSelected ? 'trans-btn-ger selected-lang' : 'trans-btn-ger  '"
    (click)="useLanguage('de')"
    >DE</span
  ><span
    [ngClass]="!isSelected ? 'trans-btn-en selected-lang' : 'trans-btn-en  '"
    (click)="useLanguage('en')"
    >EN</span
  >
</h6>
