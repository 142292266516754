import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SpinnerService } from 'src/service/spinner.service';
import { CidaasService } from '../../../../../service/cidaas.service';
import { SharedDataService } from '../../../../../service/shared-data.service';

@Component({
  selector: 'app-initiate-authentication',
  templateUrl: './initiate-authentication.component.html',
  styleUrls: ['./initiate-authentication.component.scss']
})
export class InitiateAuthenticationComponent implements OnInit {
  selectIndex: null;
  verifyInputOption = new BehaviorSubject({});
  isInitiated: boolean = false;

  //default host page
  @Input() showText: boolean = true;
  @ViewChildren('li') liMFA: QueryList<any> | undefined;
  username: string = '';
  requestId: any = '';
  allowedMFA: any[] = [];
  usernameType: string = '';
  usage_type: string = 'PASSWORDLESS_AUTHENTICATION';
  passwordless_enabled: boolean = false;
  authMethods: string[] = ['email', 'sms', 'totp', 'ivr', 'backupcode', 'touchid', 'push', 'pattern', 'fido2'];
  selectedType: string = '';

  constructor(private route: ActivatedRoute,
    private _dataService: SharedDataService,
    private _cidaasSDK: CidaasService,
    private _router: Router,
    private _loader: SpinnerService
  ) { }


  ngOnInit(): void {
    this._getUserHint();
    this._init();
    this._initQueryparamValues();
    this._initiateAuthentication('password', '', 0)
  }

  _init = () => {
    this._getMFAList();
  };

  _initQueryparamValues = () => {
    this.route.queryParams.subscribe((params) => {
      this.username = params['userIdHint'];
      this.requestId = params['requestId'];
      this.usernameType = params['type'];
    });
  };

  _assignMFAValues(response: any) {
    this._dataService._getClientInfo().subscribe((res) => {
      this.allowedMFA = [];
      if (res['passwordless_enabled']) {
        try {
          response['configured_list'].map((item) => {
            let checkExists = this.allowedMFA.find(list => list == item.type.toLowerCase())
            if (!checkExists) {
              this.allowedMFA.push(item.type.toLowerCase());
            }
          });
        }
        catch (error) {
          console.log("we update mfa", error)
          this.allowedMFA = ['password']
        }
      }
    });
  }

  _getUserHint = () => {
    let user_hint = this.route.snapshot.queryParamMap.get('userIdHint') ? this.route.snapshot.queryParamMap.get('userIdHint') : '';

    let usernameType = this.route.snapshot.queryParamMap.get('type') ? this.route.snapshot.queryParamMap.get('type') : '';

    this.username = user_hint ? user_hint : '';
    this.usernameType = usernameType ? usernameType : '';
  };

  _getRequestId = () => {
    this._dataService._getRequestId().subscribe((requestid) => {
      this.requestId = requestid;
    });
  };

  _getMFAList = () => {
    let options = {};
    this._dataService._getRequestId().subscribe((id) => {
      this.requestId = id;
      options['request_id'] = id;
      options[this.usernameType == 'mobile' ? 'mobile_number' : this.usernameType] = this.username;
    });
    this._loader._start();
    this._cidaasSDK
      ._getMFAList(options)
      .then((response) => {
        this._loader._stop();
        this._dataService._setMFAList(response);
        this._assignMFAValues(response);
      })
      .catch((error) => {
        console.log(error);
        this._loader._stop();
      });
  };

  _initiateAuthentication = (type: string, event, index) => {
    // adding selection class to the mfa list
    this.selectIndex = index;
    // this._changeSelection(event);
    this._dataService._getMFAList().subscribe(async (data) => {
      let mfaItem = ""
      try {
        mfaItem = data['configured_list'].filter((mfa) => {
          return mfa.type.toLowerCase() === type;
        });
      }
      catch (error) {
        console.log(error)
      }

      this.selectedType = type.toUpperCase();

      if (this.authMethods.includes(type)) {
        let payload = this.setPayload(mfaItem, type);
        const response = await this._cidaasSDK.initiateMFAV2(payload);
        if (response.error) {
          console.error(response);
        }
        this.isInitiated = true;
        let options = this.setOptionsByType(type);
        if (response) {
          this.setOptionsVerify(response, options);
          this.verifyInputOption.next(options);
        }
      } else {
        this._initiatePasswordField();
      }
    });
  };

  _initiatePasswordField = () => {
    this.isInitiated = true;
    this.verifyInputOption.next({
      input: {
        label: 'LOGIN.AUTHENTICATION.PASSWORD.INPUT.LABEL',
        placeholder: 'LOGIN.AUTHENTICATION.PASSWORD.INPUT.PLACEHOLDER',
        hint: 'LOGIN.AUTHENTICATION.PASSWORD.INPUT.HINT',
        type: 'password',
      },
      button: {
        btnText: 'LOGIN.AUTHENTICATION.PASSWORD.BUTTON.TEXT',
        btnColor: 'primary',
      },
      verify: {
        method: 'password',
      },
    });
  };

  _changeSelection = (event: any) => {
    this.liMFA?.forEach((liv) => liv.nativeElement.classList.remove('active'));
    if (event.target.tagName !== 'LI') {
      event.target.parentElement.classList.add('active');
    }
    if (event.target.tagName === 'LI') {
      event.target.classList.add('active');
    }
  };

  private setPayload(mfaItem: any, type: string) {
    let payload = {};
    let medium_id = mfaItem[0]['mediums'] && mfaItem[0]['mediums'].length > 0 ? mfaItem[0]['mediums'][0]['id'] : mfaItem[0]['type'];
    payload['usage_type'] = this.usage_type;
    payload['request_id'] = this.requestId;
    payload['medium_id'] = medium_id;
    payload['type'] = type;
    payload[this.usernameType == 'mobile' ? 'mobile_number' : this.usernameType] = this.username;
    return payload;
  }

  private setOptionsVerify(response: any, options: any) {
    options.verify['exchange_id'] =
      response.data['exchange_id'] && response.data['exchange_id']['exchange_id'] ? response.data['exchange_id']['exchange_id'] : undefined;
    options.verify['sub'] = response.data['sub'] || undefined;
    options.verify['status_id'] = response.data['status_id'] || undefined;
    options.verify['push_selected_number'] = response.data['push_selected_number'] || undefined;
    options.verify['fido2_entity'] = response.data['fido2_entity'] || undefined;
  }

  private setOptionsByType(type: string) {
    return {
      input: {
        label: ('login.authentication.' + type + '.input.label').toUpperCase(),
        placeholder: ('login.authentication.' + type + '.input.placeholder').toUpperCase(),
        hint: ('login.authentication.' + type + '.input.hint').toUpperCase(),
        type: 'password',
      },
      button: {
        btnText: ('login.authentication.' + type + '.button.text').toUpperCase(),
        btnColor: 'primary',
      },
      verify: {
        method: type,
      },
    };
  }

  forgotPassword() {
    this._router.navigate(['password_forgot_init'], {
      queryParams: {
        userIdHint: this.username,
        requestId: this.requestId,
        type: this.usernameType,
      },
    });
  }

  backClick() {
    this._router.navigate(['/login'], {
      queryParams: {
        userIdHint: this.username,
        requestId: this.requestId,
        type: this.usernameType
      },
    });
  }

}
