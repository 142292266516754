<!-- TODO change here -->
  <ul class="list mx-auto container">
    <li class="list-item" *ngFor="let menu of menus">
     <a class="dashboardLinks" target="_blank" href="{{menu.link}}">
      <div class="list-content">
        <div class="icon-circle">
          <img
          src="{{menu.icon}}"
          alt=""
          class="img-icon"
          />
        </div>
        <p class="ft-12"> {{menu.menuLabel}}</p>
      </div>
    </a>
    </li>

  </ul>
  
