<div class="input-fields-container" [formGroup]="group">
  <label>{{field.localeText?.name}}:</label>
  <div>
    <select class="form-select input-field" [formControlName]="field.fieldKey" [multiple]="ismulti"
      [required]="field.required">
      <option style="display: none" selected [value]="" *ngIf="!ismulti">None</option>
      <option *ngFor="let option of field.localeText.attributes" [value]="option.key">
        {{option.value}}
      </option>
    </select>
    <ng-container *ngFor="let validation of field.validation;" ngProjectAs="span">
      <span class="errMsg ft-12" *ngIf="group.get(field.fieldKey).hasError(validation.name) && group.get(field.fieldKey).touched">
        {{validation.message | translate}}
      </span>
    </ng-container>
  </div>
</div>