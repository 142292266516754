export class Fido2Helper {

    chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
    lookup = new Uint8Array(256)
    constructor() {
        for (let i = 0; i < this.chars.length; i++) {
            this.lookup[this.chars.charCodeAt(i)] = i
        }
    }

    encode(arraybuffer) {
        let bytes = new Uint8Array(arraybuffer);
        let i;
        let len = bytes.length;
        let base64url = ''

        for (i = 0; i < len; i += 3) {
            base64url += this.chars[bytes[i] >> 2]
            base64url += this.chars[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)]
            base64url += this.chars[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)]
            base64url += this.chars[bytes[i + 2] & 63]
        }

        if ((len % 3) === 2) {
            base64url = base64url.substring(0, base64url.length - 1)
        } else if (len % 3 === 1) {
            base64url = base64url.substring(0, base64url.length - 2)
        }

        return base64url
    }


    decode(base64string) {
        let bufferLength = base64string.length * 0.75;
        let len = base64string.length;
        let i;
        let p = 0;

        let encoded1;
        let encoded2;
        let encoded3;
        let encoded4

        let bytes = new Uint8Array(bufferLength)

        for (i = 0; i < len; i += 4) {
            encoded1 = this.lookup[base64string.charCodeAt(i)]
            encoded2 = this.lookup[base64string.charCodeAt(i + 1)]
            encoded3 = this.lookup[base64string.charCodeAt(i + 2)]
            encoded4 = this.lookup[base64string.charCodeAt(i + 3)]

            bytes[p++] = (encoded1 << 2) | (encoded2 >> 4)
            bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2)
            bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63)
        }

        return bytes.buffer
    }

    publicKeyCredentialToJSON(cred) {
        let pubKeyCred = cred;
        if (pubKeyCred instanceof Array) {
            let arr: any = [];
            for (let i of pubKeyCred) { arr.push(this.publicKeyCredentialToJSON(i)) }
            return arr;
        }

        if (pubKeyCred instanceof ArrayBuffer) {
            return this.encode(pubKeyCred);
        }

        if (pubKeyCred instanceof Object) {
            let obj = {};
            for (let key in pubKeyCred) {
                ;
                obj[key] = this.publicKeyCredentialToJSON(pubKeyCred[key]);
            }
            return obj;
        }



        return pubKeyCred;
    }

    generateRandomBuffer(len) {
        len = len || 32;
        let randomBuffer = new Uint8Array(len);
        window.crypto.getRandomValues(randomBuffer);
        return randomBuffer;
    }

    preformatMakeCredReq(makeCredReq: any) {
        let credReq: any = makeCredReq;
        credReq.challenge = this.decode(credReq.challenge);
        return credReq;
    }

    preformatGetAssertReq(assert) {
        let getAssert = assert;
        getAssert.challenge = this.decode(getAssert.challenge);
        for (let allowCred of getAssert.allowCredentials) {
            allowCred.id = this.decode(allowCred.id);
        }

        return getAssert;
    }
}