import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-devices-details',
  templateUrl: './devices-details.component.html',
  styleUrls: ['./devices-details.component.scss']
})
export class DevicesDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
