import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompleteRegisterService } from '../../../../../service/complete-register.service';
import{IUserData} from './../../../../models/client-info.interface'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private complete_register: CompleteRegisterService) { }

    user : IUserData;
  ngOnInit(): void {
    this.getUsers();
  }

  getUsers():any{
    this.complete_register.getUserInfo().then((data:any) => {
      if (data ) {
        this.user=data;
      } 
    });
  }

  _changeTab(route){
    this.router.navigate([route], {
      // relativeTo: this.activatedRoute,
      // queryParams: {
      //   userIdHint: this.username,
      //   requestId: this.requestId,
      //   type: usernameType,
      // },
    });
  }
}
