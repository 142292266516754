<ng-container [formGroup]="group">
  <input class="consent-check" type="checkbox" [formControlName]="field.fieldKey"><span
      [innerHTML]="field.dataType == 'CHECKBOX' ? field.localeText.name: field.localeText.consentLabel.label" style="margin-left: 10px;"></span><span
      *ngIf="field.required">*</span>
</ng-container>
<ng-container *ngIf="(
  group.get(field.fieldKey).dirty ||
  group.get(field.fieldKey).touched
  )" ngProjectAs="mat-error">
  <div class="mat-form-field">
    <div class="mat-form-field-subscript-wrapper"
      [ngClass]="{'slideInDown': group.get(field.fieldKey).hasError(field.validation.name)}">
      <span class="errMsg ft-12" *ngIf="group.get(field.fieldKey).hasError(field.validation.name)">
        {{field.validation.message? field.validation.message: 'REQUIRED_FIELD' | translate}}
      </span>
    </div>
  </div>
</ng-container>