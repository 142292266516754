import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SharedDataService } from '../../../../../service/shared-data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  footerLinksDe: any[] = [
    { label: 'FOOTER.IMPRINT', link: 'https://bildungscampus.hn/impressum-bildungscampuslife?hidebanner=true' },
    { label: 'FOOTER.POLICY', link: 'https://bildungscampus.hn/datenschutzhinweise-bildungscampus-services?hidebanner=true' },
    { label: 'FOOTER.CONTACT', link: 'https://bildungscampus.hn/faq-bildungscampuslife#c2670' },
    { label: 'FOOTER.FAQ', link: 'https://bildungscampus.hn/faq-bildungscampuslife' }, 
    { label: 'FOOTER.TERMS_OF_USE_1', link: 'https://bildungscampus.hn/leben-am-campus/campuscard/nutzungsbedingungen-campuscard-allgemein' },
    { label: 'FOOTER.TERMS_OF_USE_2', link: 'https://bildungscampus.hn/nutzungsbedingungen-bildungscampuslife' },
  ]
  footerLinksEn: any[] = [
    { label: 'FOOTER.IMPRINT', link: 'https://bildungscampus.hn/en/legal-notice-bildungscampuslife?hidebanner=true' },
    { label: 'FOOTER.POLICY', link: 'https://bildungscampus.hn/datenschutzhinweise-bildungscampus-services' },
    { label: 'FOOTER.CONTACT', link: 'https://bildungscampus.hn/faq-bildungscampuslife#c2670' },
    { label: 'FOOTER.FAQ', link: 'https://bildungscampus.hn/faq-bildungscampuslife' }, 
    { label: 'FOOTER.TERMS_OF_USE_1', link: 'https://bildungscampus.hn/datenschutzhinweise-bildungscampus-services?hidebanner=true#c2150' },
    { label: 'FOOTER.TERMS_OF_USE_2', link: 'https://bildungscampus.hn/nutzungsbedingungen-bildungscampuslife' },
 
  ]
  public lang: string = "en";
  
  constructor(
    private cdRef : ChangeDetectorRef,
    private dataService: SharedDataService
  ) { }

  ngOnInit(): void {
    this.dataService.getLanguage().subscribe((data) => {
      this.lang = data;
    })
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }
}
