import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompleteRegisterService } from 'src/service/complete-register.service';
import { environment } from '../../../../../../environments/environment';
import { IdValidatorService } from '../../../../../../service/idvalidator.service';
import jwt_decode from "jwt-decode";
import { SpinnerService } from 'src/service/spinner.service';

@Component({
  selector: 'app-profile-upload',
  templateUrl: './profile-upload.component.html',
  styleUrls: ['./profile-upload.component.scss'],
})
export class ProfileUploadComponent implements OnInit {

  public accessToken: string;
  public internalAccessToken: string;
  waiting: boolean;
  userDetails: any = {};
  access_token: string = '';
  public snapNSmileSuccess: boolean = false;
  public snapNSmileFailure: boolean = false;
  constructor(
    private route: Router, private complete_register: CompleteRegisterService,
    private idvalidatorService: IdValidatorService, private activatedRoute: ActivatedRoute,
    private _loader: SpinnerService
  ) {
    const urlParams = new URLSearchParams(window.location.hash.substring(1));
    let token = urlParams.get('access_token');
    if (token == '' || token == 'undefined' || token == null) {
      token = localStorage.getItem('idval_access_token');
    }
    this.idvalidatorService.setAccessToken(token);
  }

  submit() {
    this.idvalidatorService.setAccessToken(this.accessToken)
    this.internalAccessToken = localStorage.getItem('access_token');
    let internalSub = this.getDecodedAccessToken(this.internalAccessToken).sub ? this.getDecodedAccessToken(this.internalAccessToken).sub : "";
    let externalSub = this.getDecodedAccessToken(this.accessToken).sub ? this.getDecodedAccessToken(this.accessToken).sub : "";
    this.idvalidatorService.updateUserWithNewSub(internalSub, externalSub, "picture_change_started").then((response) => {
      let redirectUri = environment.base_url + '/user-ui-bc/profile-upload-status';
      this.idvalidatorService.invokeIdValidationCase(redirectUri)
    })
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return "";
    }
  }

  parseFragment(fragment: string): any {
    var paresedFragment = {};
    var vals = fragment.split("&");
    for (var i = 0; i < vals.length; i++) {
      var values = vals[i].split("=");
      if (values.length == 2) {
        paresedFragment[values[0]] = values[1];
      }
    }
    return paresedFragment;
  }

  findValueInFragment(fragment: string, key: string, key1: string = ""): string {
    if (fragment.charAt(0) === '?' || fragment.charAt(0) === '#') {
      fragment = fragment.substr(1);
    }
    if (this.parseFragment(fragment)[key]) {
      return this.parseFragment(fragment)[key];
    }
    else if (this.parseFragment(fragment)[key1]) {
      return this.parseFragment(fragment)[key1];
    }
    else {
      return undefined;
    }
  }

  ngOnInit(): void {
    let token_url = location.search == "" ? location.hash : location.search;
    this.accessToken = this.findValueInFragment(
      token_url,
      "access_token"
    );
    if (this.activatedRoute.snapshot.url[0] && this.activatedRoute.snapshot.url[0].path) {
      if (this.activatedRoute.snapshot.url[0].path === 'profile-upload') {
        this.waiting = true;
        this.submit();
      } else {
        this.waiting = false;
        setTimeout(() => {
          this.getUserInfo();
        }, 5000);
      }
    }

  }
  getUserInfo() {
    this._loader._start();
    this.complete_register.getUserInfo().then(response => {
      this._loader._stop();
      if (response) {
        this.userDetails = response;
        if (response.customFields.snap_n_smile_status && response.customFields.snap_n_smile_status == "success") {
          this.snapNSmileFailure = false;
          this.snapNSmileSuccess = true;
          this.waiting = false;
        }
        else {
          this.snapNSmileFailure = true;
          this.snapNSmileSuccess = false;
          this.waiting = false;
        }
      }
    }, error => {
      this._loader._stop();
    })
  }

  reInvoiceValidation() {
    this.idvalidatorService.setAccessToken(this.idvalidatorService.getAccessToken())
    let redirectUri = environment.base_url + '/user-ui-bc/profile-upload-status';
    this.idvalidatorService.invokeIdValidationCase(redirectUri)
  }

  backToProfile() {
    this.route.navigate(['/user-account'])
  }

  redirectToAtlassian() {
    window.location.assign(
      environment.atlassian_url
    );
  }
}


