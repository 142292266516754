import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-complete-register',
  templateUrl: './complete-register.component.html',
  styleUrls: ['./complete-register.component.scss'],
})
export class CompleteRegisterComponent implements OnInit {
  ngOnInit(): void {}
  onActivate(event) {
    setTimeout(function () {
      window.scroll({ 
        top: 0, 
        left: 0, 
        behavior: 'smooth' 
 });
    }, 100);
    
 }
}
