import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { invokeCase } from "@cidaas-public-devkits/id-validator-ui-sdk";
import { v4 as uuidv4 } from 'uuid';
import { IUserRegistationBody } from "../app/models/user-registration-body.interface";
import { environment } from "../environments/environment";
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from "./spinner.service";
import { TranslateService } from "./translate.service";
import { CidaasService } from "./cidaas.service";
@Injectable({
    providedIn: 'root',
})
export class IdValidatorService {
    internalBaseUr = environment.base_url;
    externalBaseUrl = environment.base_url_id_validation_instance;

    externalRequestId: string;
    externalTrackId: string;
    externalAccessToken: string;
    externalSub: string;

    constructor(private http: HttpClient, private toastr: ToastrService, private _loader: SpinnerService, private translate: TranslateService, private cidaasService: CidaasService) { }

    async startIdValidation(internalSub: string, flow: string, user: IUserRegistationBody, redirectUri: string) {
        this._loader._start();
        await this.generateRequestId(redirectUri).then((responseRequestId) => {
            this.externalRequestId = responseRequestId.data.requestId;
            this.registerUser(user).then((responseRegister) => {
                this._loader._stop();
                console.log("responseRegister ", responseRegister)
                this.externalTrackId = responseRegister.data.trackId;
                this.loginAfterRegister();
            }, (error: any) => {
                this._loader._stop();
                if (error) {
                    this.toastr.error(this.translate.getTranslatedMessage('IDVALIDATION_ERROR_MSG'));
                }
            })
        }, (error: any) => {
            this._loader._stop();
            if (error) {
                this.toastr.error(this.translate.getTranslatedMessage('IDVALIDATION_ERROR_MSG'));
            }
        });
    }

    async generateRequestId(redirectUri: string): Promise<any> {
        const requestIdUrl = this.externalBaseUrl + '/authz-srv/authrequest/authz/generate';
        let requestIdBody!: Object;
        let nonce = uuidv4();
        requestIdBody = {
            client_id: environment.client_id_validation_instance,
            redirect_uri: redirectUri,
            response_type: "token",
            nonce: nonce
        }
        return await this.http.post<any>(requestIdUrl, requestIdBody, {
            headers: this.getDefaultHeader(),
        }).toPromise();
    }

    async registerUser(user: IUserRegistationBody): Promise<any> {
        const registrationUrl = this.externalBaseUrl + '/users-srv/register';
        return await this.http.post<any>(registrationUrl, user, {
            headers: this.getRegistrationHeader(),
        }).toPromise();
    }

    loginAfterRegister() {
        // This is a dirty hack to handle the problem Angular has with XHR Requests that return 302 redirect
        // Since we can't use a normal XHR Request we submit a Form and use a hidden input rememberMe with the value true
        // That way we can send a body with the request and the redirect is being handled correctly
        var loginAfterRegisterFormElement = document.getElementsByName(
            'loginAfterRegisterForm'
        )[0] as HTMLFormElement;
        let postLoginURL = this.externalBaseUrl + '/login-srv/login/handle/afterregister/' + this.externalTrackId;
        loginAfterRegisterFormElement.action = postLoginURL;
        loginAfterRegisterFormElement.method = 'post';
        loginAfterRegisterFormElement.submit();
    }

    async updateUserWithNewSub(internalSub: string, externalSub: string, flow: string): Promise<any> {
        const updateUrl = this.internalBaseUr + '/users-srv/user/profile/' + internalSub;
        let updateBody!: Object;
        updateBody = {
            provider: 'self',
            sub: internalSub,
            customFields: {
                snap_n_smile_sub: externalSub,
                snap_n_smile_status: flow
            },
        };
        return await this.http.put<any>(updateUrl, updateBody, {
            headers: this.getUserAuthHeader(),
        }).toPromise();
    }

    setAccessToken(accessToken: string) {
        this.externalAccessToken = accessToken;
        localStorage.setItem("idval_access_token", accessToken)
    }

    getAccessToken(): string {
        if (localStorage.getItem("idval_access_token")) {
            return localStorage.getItem("idval_access_token")
        }
        else if (this.externalAccessToken) {
            return this.externalAccessToken
        }
        else {
            return ""
        }
    }

    invokeIdValidationCase(redirectUri: string) {
        let idval_access_token = this.getAccessToken();
        invokeCase(
            idval_access_token,
            "fe1440b0-2a7f-4b45-bed4-b3d29debfc1d",
            this.externalBaseUrl,
            redirectUri
        );
    }

    getInternalAccessToken() {
        return localStorage.getItem('access_token')!;
    }

    getUserAuthHeader() {
        return new HttpHeaders()
            .set('content-type', 'application/json')
            .set('Authorization', 'Bearer ' + this.getInternalAccessToken());
    }

    getDefaultHeader() {
        return new HttpHeaders()
            .set('content-type', 'application/json')
    }

    getRegistrationHeader() {
        return new HttpHeaders()
            .set('content-type', 'application/json')
            .set('requestId', this.externalRequestId)
    }
}

