import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from 'src/service/spinner.service';
import { environment } from '../../../../../../environments/environment';
import { CompleteRegisterService } from '../../../../../../service/complete-register.service';
import { IdValidatorService } from '../../../../../../service/idvalidator.service';

@Component({
  selector: 'app-fourth-step',
  templateUrl: './fourth-step.component.html',
  styleUrls: ['./fourth-step.component.scss'],
})
export class FourthStepComponent implements OnInit {

  public waiting: boolean = true;
  public snapNSmileSuccess: boolean = false;
  public snapNSmileFailure: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private complete_register: CompleteRegisterService,
    private idvalidatorService: IdValidatorService,
    private _loader: SpinnerService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this._loader._start();
      this.complete_register.getUserInfo().then((response) => {
        this._loader._stop();
        console.log(response)
        if (response.customFields.snap_n_smile_status && response.customFields.snap_n_smile_status == "successful") {
          this.snapNSmileFailure = false;
          this.snapNSmileSuccess = true;
          this.waiting = false;
        }
        else {
          this.snapNSmileFailure = true;
          this.snapNSmileSuccess = false;
          this.waiting = false;
        }
      })
        .catch(() => {
          this._loader._stop();
        })
    }, 5000)
    this._loader._stop();

  }
  goToIdValidator() {
    let redirectUri = environment.base_url + '/user-ui-bc/complete-register/fourth-step'
    this.idvalidatorService.invokeIdValidationCase(redirectUri)
  }

  goToPicutreUpload() {
    this.router.navigate(['manual-picture-upload'], {
      relativeTo: this.route.parent,
    });
  }

  goToNextPage() {
    window.location.assign(
      environment.atlassian_url
    );
  }
}
