import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from 'src/service/spinner.service';
import { environment } from '../../../../../../../environments/environment';
import { CompleteRegisterService } from '../../../../../../../service/complete-register.service';
import { IdValidatorService } from '../../../../../../../service/idvalidator.service';
import { IUserRegistationBody } from '../../../../../../models/user-registration-body.interface';

@Component({
  selector: 'app-info-video-ident',
  templateUrl: './info-video-ident.component.html',
  styleUrls: ['./info-video-ident.component.scss'],
})
export class InfoVideoIdentComponent implements OnInit {
  panelOpenState: boolean = true;
  requestId: string = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private completeRegisterService: CompleteRegisterService,
    private idvalidatorService: IdValidatorService,
    private _loader: SpinnerService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.requestId = params['requestId'];
    });
  }

  async startVideoIdent() {
    let user: IUserRegistationBody;
    let internalSub: string;
    let redirectUri: string;
    this._loader._start();
    await this.completeRegisterService.getUserInfo().then((response) => {
      this._loader._stop();
      user = {
        email: response.email ? response.email : "",
        given_name: response.given_name ? response.given_name : "",
        family_name: response.family_name ? response.family_name : "",
        provider: "self",
        customFields: {
          external_id: response.sub ? response.sub : ""
        }
      },
        internalSub = response.sub ? response.sub : "";
      redirectUri = environment.base_url + '/user-ui-bc/complete-register/additional-info';
      this.idvalidatorService.startIdValidation(internalSub, "registration_started", user, redirectUri);
    }).catch(() => {
      this._loader._stop();
    })
    this._loader._stop();
  }

  goWithNoIdentification() {
    this.router.navigate(['reg-without-video'], {
      relativeTo: this.route.parent,
    });
  }

  ngOnInit(): void { }
  

  backClick() {
    this.router.navigate(['/complete-register/third-step'], {
      queryParams: {
        requestId: this.requestId,
      },
    });
  }
}
