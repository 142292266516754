import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[showHidePWD]'
})
export class ShowHidePasswordDirective {

  private _shown = false;

  constructor(private el: ElementRef) {
    this.setup();
  }

  toggle(span: HTMLElement) {
    this._shown = !this._shown;
    if (this._shown) {
      this.el.nativeElement.setAttribute('type', 'text');
      span.classList.add('hidePWD');
      span.classList.remove('showPWD');
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
      span.classList.remove('hidePWD');
      span.classList.add('showPWD');
    }
  }
  setup() {    
    const parent = this.el.nativeElement.parentNode;
    const span = document.createElement('span');
    span.classList.add('showPWD');
    span.addEventListener('click', (event) => {
      this.toggle(span);
    });
    parent.appendChild(span);
  }

}
