import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../../../environments/environment';
import { IdValidatorService } from '../../../../../../../service/idvalidator.service';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.scss'],
})
export class AdditionalInfoComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private idvalidatorService: IdValidatorService
  ) { }

  public externalAccessToken: string;
  public internalAccessToken: string;

  submit() {
    this.idvalidatorService.setAccessToken(this.externalAccessToken)
    this.internalAccessToken = localStorage.getItem('access_token');
    let internalSub = this.getDecodedAccessToken(this.internalAccessToken).sub ? this.getDecodedAccessToken(this.internalAccessToken).sub : "";
    let externalSub = this.getDecodedAccessToken(this.externalAccessToken).sub ? this.getDecodedAccessToken(this.externalAccessToken).sub : "";
    this.idvalidatorService.updateUserWithNewSub(internalSub, externalSub, "registration_started").then((response) => {
      let redirectUri = environment.base_url + '/user-ui-bc/complete-register/fourth-step';
      this.idvalidatorService.invokeIdValidationCase(redirectUri)
    })
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return "";
    }
  }

  parseFragment(fragment: string): any {
    var paresedFragment = {};
    var vals = fragment.split("&");
    for (var i = 0; i < vals.length; i++) {
      var values = vals[i].split("=");
      if (values.length == 2) {
        paresedFragment[values[0]] = values[1];
      }
    }
    return paresedFragment;
  }

  findValueInFragment(fragment: string, key: string, key1: string = ""): string {
    if (fragment.charAt(0) === '?' || fragment.charAt(0) === '#') {
      fragment = fragment.substr(1);
    }
    if (this.parseFragment(fragment)[key]) {
      return this.parseFragment(fragment)[key];
    }
    else if (this.parseFragment(fragment)[key1]) {
      return this.parseFragment(fragment)[key1];
    }
    else {
      return undefined;
    }
  }

  ngOnInit(): void {
    let token_url = location.search == "" ? location.hash : location.search;
    this.externalAccessToken = this.findValueInFragment(
      token_url,
      "access_token"
    );
    this.submit();
  }
}
