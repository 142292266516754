import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-video-preview',
  templateUrl: './video-preview.component.html',
  styleUrls: ['./video-preview.component.css']
})
export class VideoPreviewComponent implements OnInit {
  public YT: any;
  public video: any;
  public player: any;
  public reframed: Boolean = false;
  videoEnded: boolean = false;
  requestId: string = '';

  isRestricted = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  constructor(private router: Router, private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params) => {
      this.requestId = params['requestId'];
    });
  }

  ngOnInit(): void {
    this.video = '1MI8XLSWZv8';
    this.init();
  }
  init() {
    // Return if Player is already created
    if (window['YT']) {
      this.startVideo();
      return;
    }

    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    /* 3. startVideo() will create an <iframe> (and YouTube player) after the API code downloads. */
    window['onYouTubeIframeAPIReady'] = () => this.startVideo();
  }
  startVideo() {
    this.reframed = false;
    this.player = new window['YT'].Player('player', {
      videoId: this.video,
      width: '100%',
      host: 'https://www.youtube.com',
      playerVars: {
        autoplay: 1,
        modestbranding: 1,
        controls: 1,
        disablekb: 1,
        rel: 0,
        showinfo: 0,
        fs: 0,
        playsinline: 1,
        playlist: this.video,
        enablejsapi: 1,
        origin: window.location.origin

      },
      events: {
        'onStateChange': this.onPlayerStateChange.bind(this),
        'onError': this.onPlayerError.bind(this),
        'onReady': this.onPlayerReady.bind(this),
      }
    });
  }
  onPlayerReady(event) {
    if (this.isRestricted) {
      event.target.mute();
      event.target.playVideo();
    } else {
      event.target.playVideo();
    }
  }

  onPlayerStateChange(event) {
    switch (event.data) {
      case window['YT'].PlayerState.ENDED:
        this.videoEnded = true;
        document.getElementById("next_btn").focus();
        break;
    };
    if (this.player.getPlayerState() == 0) {
      this.videoEnded = true;
      // this.getDisable();
      const btn = document.getElementById('next_btn') as HTMLButtonElement | null;
      const arrow = document.getElementById('arrowActive') as HTMLButtonElement | null;
      const deArrow = document.getElementById('arrowDeactive') as HTMLButtonElement | null;
      btn.disabled = false
      btn.classList.remove("transparent-btn1")
      btn.classList.add("custom_primary_btn1")
      deArrow.style.display = "none";
      arrow.style.display = "block"
      btn.focus();

    }
  };

  onPlayerError(event) {
    switch (event.data) {
      case 2:
        console.log('' + this.video)
        break;
      case 100:
        break;
      case 101 || 150:
        break;
    };
  };
  startVideoIdent() {
    this.router.navigate(['third-step'], { relativeTo: this.route.parent });
  }

  backClick() {
    this.router.navigate(['/complete-register/second-step'], {
      queryParams: {
        requestId: this.requestId,
      },
    });
  }

  getDisable() {
    if (!this.videoEnded) {
      return 'transparent-btn1';
    } else {
      return 'custom_primary_btn1';
    }
  }
}
