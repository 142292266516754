 <p>{{ "LOGOUT_SECTION.LOGOUT_MSG" | translate }}</p>
        <div class="content d-flex flex-column">
          <div class="lock-content">
            <img src="assets/Icons/lock.svg" class="mx-auto img-lock" />
          </div>
        </div>
        <p class="py-3">{{ "LOGOUT_SECTION.SEE_YOU_SOON" | translate }}</p>

        <div class="py-3 d-flex justify-content-end">
          <button type="button" class="btn btn-common px-4">
            {{ "LOGOUT_SECTION.LOGIN_AGAIN" | translate }}
          </button>
        </div>