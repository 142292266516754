// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  base_url: 'https://schwarzbc-dev.cidaas.de',
  base_url_id_validation_instance: 'https://bc-id-validation-test.cidaas.eu',
  client_id_validation_instance: 'b6d0e914-c897-4138-85fd-2b5ebe061bad',
  atlassian_url: 'https://docs-test.bildungscampus.life/plugins/servlet/oauth/auth?return_to=%2Findex.action',
  client_registration_flow: '7033c514-cf4d-4366-8bde-28a490d63b1f'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
