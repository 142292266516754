<div class="d-flex flex-column col-12 col-lg-12 mx-auto card-content px-0 pt-0" *ngIf="checkEmptyObject()">
  <div class="">
    <div id="reset-btn">
      <button class="transparent-back-btn" type="button" (click)="redirectToAtlassian()">
        <span class="fa fa-arrow-left pr-9"></span> {{ "ACCOUNT.PERSONAL.ATLASSIAN.ATLASSIAN_BTN" | translate }}
      </button>
    </div>
  </div>
  <div class="contact">
    <h1 class="header-label pt-4">{{
      "ACCOUNT.HEADER_LABEL" | translate
      }}</h1>
  </div>
  <div class="form-content">
    <form>
      <div class="heading-part">
        <span class="d-flex ft-14">
          <p class="labels ft-16">{{
            "ACCOUNT.CAMPUS_LABEL" | translate
            }}</p>
          <p class="normal-text pl-1">
            {{userDetails.customFields?.campus_id}}
          </p>
        </span>
      </div>
      <hr>
      <div class="personal-part">
        <h1 class="sub-heading">{{
          "ACCOUNT.PERSONAL.HEADING_LABEL" | translate
          }}</h1>

        <p class="normal-text">{{
          "ACCOUNT.PERSONAL.SUB_HEADING_LABEL" | translate
          }}</p>
        <div class="inline-align">
          <h1 class="ft-16 labels">{{
            "ACCOUNT.PERSONAL.GIVEN_NAME_LABLE" | translate
            }}</h1>
          <p class="normal-text"> {{userDetails.given_name}}</p>
        </div>

        <div class="inline-align">
          <h1 class="ft-16 labels">{{
            "ACCOUNT.PERSONAL.FAMILY_NAME_LABLE" | translate
            }}</h1>
          <p class="normal-text"> {{userDetails.family_name}}</p>
        </div>
        <hr>
        <div class="contact">
          <h1 class="sub-heading">{{
            "ACCOUNT.PERSONAL.CONTACT.EMAIL_HEADING" | translate
            }}</h1>
          <h1 class="ft-16 labels">{{
            "ACCOUNT.PERSONAL.CONTACT.INSTITUTE_EMAIL" | translate
            }}</h1>
          <div class="inline-align">
            <div class="mt-3">
              <div class="consent-email">
                <div class="empty-cont"></div>
                <label class="checkBox_container row justify-content">

                  <div class="col-md-7 col-sm-12 pr-0">
                    <input class="consent-check" type="checkbox" [checked]="email_checked"
                      (change)="onChangeEmail('email')" />
                    <span class="checkmark mt-2"></span>
                    <label class="normal-text ml-3 wrap-email" for="institute_email"
                      *ngIf="userDetails.customFields.secondary_email && userDetails.email;else email">{{userDetails.customFields.secondary_email}}</label>
                    <ng-template #email><label class="normal-text wrap-email ml-3"
                        for="institute_email">{{userDetails.email}}</label></ng-template>
                  </div>
                  <div class="col-md-5 col-sm-12 px-0" *ngIf="email_checked">
                    <div class="round">
                      <input class="input-checkbox" type="checkbox" id="test1" name="radio-group" [checked]="true"
                        disabled>
                      <label for="test1" class="custom-control-gender-label custom-checkbox-label"></label>
                    </div>
                    <label
                      class="custom-control-gender-label ml-5 ft-14 checkbox-label">{{'ACCOUNT.PERSONAL.CONTACT.ALTERNATIVE_EMAIL_HINT'
                      | translate}}</label>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div class="alternative-email" *ngIf="userDetails.customFields.secondary_email">
            <h1 class="ft-16 labels">{{
              "ACCOUNT.PERSONAL.CONTACT.INSTITUTE_EMAIL_HEADING" | translate
              }}</h1>
            <div class="inline-align">
              <div class="mt-3">
                <div class="consent-email">
                  <div class="empty-cont"></div>
                  <label class="checkBox_container row justify-content">
                    <div class="col-md-7 col-sm-12 pr-0">
                      <input class="consent-check" type="checkbox" [checked]="alternative_email_checked"
                        (change)="onChangeEmail('alternate')" />
                      <span class="checkmark mt-2"></span>
                      <label class="normal-text wrap-email ml-3" for="institute_email">{{userDetails.email}} <span
                          class="fa fa-trash mt-2 ml-2 trash-icon" (click)="deletAltEmail()"></span></label>
                    </div>
                    <div class="col-md-5 col-sm-12  px-0" *ngIf="alternative_email_checked">
                      <div class="round">
                        <input class="input-checkbox" type="checkbox" id="test1" name="radio-group" [checked]="true"
                          disabled>
                        <label for="test1" class="custom-control-gender-label custom-checkbox-label"></label>
                      </div>
                      <label
                        class="custom-control-gender-label ml-5 ft-14 checkbox-label">{{'ACCOUNT.PERSONAL.CONTACT.ALTERNATIVE_EMAIL_HINT'
                        | translate}}</label>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="inline-align">
            <div class="mt-3">
              <div class="consent-email">
                <div class="empty-cont"></div>
                <label class="normal-text grey" for="alternative_email">{{
                  "ACCOUNT.PERSONAL.CONTACT.ALTERNATIVE_EMAIL" | translate
                  }}</label>
              </div>
              <div class="form-group px-0 mt-3 col-12">
                <input type="text" class="form-control"
                  placeholder="{{'ACCOUNT.PERSONAL.CONTACT.ALTERNATIVE_EMAIL_PLACEHOLDER' | translate}}"
                  name="alternative_email1" (keyup)="inputAlternativeEmail($event)" [(ngModel)]="selectedEmail" />
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <div id="accordionOne">
                <div class="card">
                  <div class="accordion-header card-header">
                    <button class="accordion-button collapsed" data-toggle="collapse" href="#collapseOne">
                      {{ "ACCOUNT.PERSONAL.PROFILE.ADDITIONAL_EMAIL_REQUIREMENTS_HEADING" | translate }}
                    </button>
                  </div>
                  <div id="collapseOne" class="collapse" data-parent="#accordionOne">
                    <div class="card-body">
                      <ul class="basic-theme video-detection-hints">
                        <li>
                          <span>{{ "ACCOUNT.PERSONAL.PROFILE.ADDITIONAL_EMAIL_REQUIREMENTS_1" | translate }}</span>
                        </li>
                        <li>
                          <span>{{ "ACCOUNT.PERSONAL.PROFILE.ADDITIONAL_EMAIL_REQUIREMENTS_2" | translate }}</span>
                        </li>
                        <li>
                          <span>{{ "ACCOUNT.PERSONAL.PROFILE.ADDITIONAL_EMAIL_REQUIREMENTS_3" | translate }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="inline-align">
            <div class="">
              <h1 class="labels ft-16">{{
                "ACCOUNT.PERSONAL.CONTACT.MOBILE_NUMBER" | translate
                }}</h1>
              <div class="form-group col-12 mt-3 px-0">
                <input type="text" class="form-control"
                  placeholder="{{'ACCOUNT.PERSONAL.CONTACT.MOBILE_NUMBER_PLACEHOLDER' | translate}}" name="mobileNumber"
                  (keyup)="inputMobilenumber($event)" [(ngModel)]="mobile_number" />
              </div>
            </div>
            <p class="normal-text">{{"ACCOUNT.PERSONAL.CONTACT.MOBILE_NUMBER_HINT" | translate
              }}</p>
          </div>

        </div>
      </div>

      <div class="mt-3">
        <button class="custom-primary-btn" type="submit" (click)="savePersonal()">
          {{ "ACCOUNT.PERSONAL.CONTACT.SAVE_BTN" | translate }}
        </button>
      </div>
      <hr>
      <div class="profile-part mt-3">
        <h1 class="sub-heading">{{
          "ACCOUNT.PERSONAL.PROFILE.PROFILE_HEADING" | translate
          }}</h1>
        <div class="row d-flex pl-4">
          <div class="col-5 px-0" [style.display]="!isShowingCropper ? null : 'none'">
            <div class="profile-img">
              <div class="user-profile">
                <div class="user-profile-icon d-flex">
                  <img class="image-preview" src="{{profilePicture}}" alt="">
                </div>
              </div>
              <div class="mt-2">
                <input #myInput type="file" id="imgInput" class="profile_btn" name="imgInput" accept="image/*"
                  enctype="multipart/form-data" (change)="selectPicture($event)" />

                <label class="lbl profile_btn" for="imgInput">
                  <img class="profile-icon" src="assets/Icons/upload-img.svg" alt="">
                  <span>{{
                    isShowingCropper
                    ? ("MANUAL_PICTURE_UPLOAD.SELECT_ANOTHER_PICTURE" | translate)
                    : ("ACCOUNT.PERSONAL.PROFILE.UPLOAD_BTN_LABEL" | translate)
                    }}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-7" [style.display]="!isShowingCropper ? null : 'none'">
            <p class="normal-text">{{
              "ACCOUNT.PERSONAL.PROFILE.PROFILE_HINT" | translate
              }}</p>
          </div>
          <div class="col-12">
            <div [style.display]="isShowingCropper ? 'block'  : 'none'">
              <div class="cropper-img-container">

                <div class="cropper-container1">
                  <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                    [aspectRatio]="3 / 4" [resizeToWidth]="imgWidth"
                    [resizeToHeight]="imgHeight" format="png" (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()"></image-cropper>
                </div>
                <div class="crp-img-container">
                  <img class="img-item" [src]="croppedImage" [style.width.px]="imgWidth" [style.height.px]="imgHeight"/>
                </div>
              </div>
              <div class="input-section">

                <div class="align-btn left-btn col-4">
                  <button class="transparent-back-btn" type="button" (click)="cancelPhotoUpload()">
                    {{ "CAMPUS_CARD.CANCEL_BTN" | translate }}
                  </button>

                </div>
                <div class="align-btn right-btn">
                  <button (click)="uploadPicture()" class="custom-primary-btn">
                    {{ "MANUAL_PICTURE_UPLOAD.UPLOAD_PICTURE" | translate }}
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <div id="accordionTwo">
              <div class="card">
                <div class="accordion-header card-header">
                  <button class="accordion-button collapsed" data-toggle="collapse" href="#collapseTwo">
                    {{ "ACCOUNT.PERSONAL.PROFILE.PROFILE_PICTURE_REQUIREMENTS_HEADING" | translate }}
                  </button>
                </div>
                <div id="collapseTwo" class="collapse" data-parent="#accordionTwo">
                  <div class="card-body">
                    <ul class="basic-theme video-detection-hints">
                      <li>
                        <span>{{ "ACCOUNT.PERSONAL.PROFILE.PROFILE_PICTURE_REQUIREMENTS_1" | translate }}</span>
                      </li>
                      <li>
                        <span>{{ "ACCOUNT.PERSONAL.PROFILE.PROFILE_PICTURE_REQUIREMENTS_2" | translate }}</span>
                      </li>
                      <li>
                        <span>{{ "ACCOUNT.PERSONAL.PROFILE.PROFILE_PICTURE_REQUIREMENTS_3" | translate }}</span>
                      </li>
                      <li>
                        <span>{{ "ACCOUNT.PERSONAL.PROFILE.PROFILE_PICTURE_REQUIREMENTS_4" | translate }}</span>
                      </li>
                      <li>
                        <span>{{ "ACCOUNT.PERSONAL.PROFILE.PROFILE_PICTURE_REQUIREMENTS_5" | translate }}</span>
                      </li>
                      <li>
                        <span>{{ "ACCOUNT.PERSONAL.PROFILE.PROFILE_PICTURE_REQUIREMENTS_6" | translate }}</span>
                      </li>
                      <li>
                        <span>{{ "ACCOUNT.PERSONAL.PROFILE.PROFILE_PICTURE_REQUIREMENTS_7" | translate }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>

      <div class="reset_password">
        <h1 class="sub-heading">{{
          "ACCOUNT.PERSONAL.RESET_PASSWORD.HEADING_LABEL" | translate
          }}</h1>


        <form [formGroup]="resetForm" name="resetForm" class="form-style">

          <div class="inline-align">
            <div class="">
              <span class="normal-text grey">{{
                "ACCOUNT.PERSONAL.RESET_PASSWORD.OLD_PASSWORD" | translate
                }}</span>
              <div class="form-group col-12 px-0 mt-2">
                <input class="form-control" formControlName="old_password" [type]="togglePasswords[0].inputType"
                  placeholder="{{ 'ACCOUNT.PERSONAL.RESET_PASSWORD.PLACEHOLDER' | translate }}" />
                <span class="form-cont-feedback show-password-eye" (click)="showPassword(togglePasswords[0].id)">
                  <img class="error-icon" src="assets/img/eye-icon.svg" style="width: 20px;" alt="">
                </span>
                <div *ngIf="oldPassword.dirty && oldPassword.invalid">
                  <span class="errMsg ft-12" *ngIf="oldPassword?.errors.required">
                    {{'REQUIRED_FIELD' | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="inline-align">
            <div class="">
              <span class="normal-text grey">{{
                "ACCOUNT.PERSONAL.RESET_PASSWORD.NEW_PASSWORD" | translate
                }}</span>
              <div class="form-group col-12 px-0 mt-2">
                <input type="password" class="form-control" formControlName="new_password"
                  (keyup)="validatePassWord($event)" [type]="togglePasswords[1].inputType"
                  placeholder="{{ 'ACCOUNT.PERSONAL.RESET_PASSWORD.PLACEHOLDER' | translate }}" />
                <span class="form-cont-feedback show-password-eye" (click)="showPassword(togglePasswords[1].id)">
                  <img class="error-icon" src="assets/img/eye-icon.svg" style="width: 20px;" alt="">
                </span>
                <div *ngIf="newPassword.dirty && newPassword.invalid" class="errMsg ft-12">
                  <span class="errMsg ft-12" *ngIf="newPassword?.errors.required">
                    {{'REQUIRED_FIELD' | translate }}
                  </span>
                </div>
                <div *ngIf="
                    !newPassword.valid &&
                    newPassword.value.length > 0
                  " class="errMsg ft-12">
                  {{ "SECOND_PAGE.WRONG_PASSWORD_MESSAGE" | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="inline-align">
            <div class="">
              <span class="normal-text grey">{{
                "ACCOUNT.PERSONAL.RESET_PASSWORD.CONFIRM_PASSWORD" | translate
                }}</span>
              <div class="form-group col-12 px-0 mt-2">
                <input type="text" class="form-control" formControlName="confirm_password"
                  [type]="togglePasswords[2].inputType"
                  placeholder="{{ 'ACCOUNT.PERSONAL.RESET_PASSWORD.PLACEHOLDER' | translate }}" />
                <span class="form-cont-feedback show-password-eye" (click)="showPassword(togglePasswords[2].id)">
                  <img class="error-icon" src="assets/img/eye-icon.svg" style="width: 20px;" alt="">
                </span>
                <div *ngIf="confirmPassword.dirty && confirmPassword.invalid">
                  <span class="errMsg ft-12" *ngIf="confirmPassword?.errors.required">
                    {{'REQUIRED_FIELD' | translate }}
                  </span>
                </div>
                <span *ngIf="confirmPassword.value && confirmPassword.value != newPassword.value" class="errMsg ft-12">
                  {{'PWD-NOT-MATCH'|translate}}
                </span>
              </div>
            </div>
          </div>

          <div>
            <p class="ft-16">
              {{ "PASSWORD_SET.HINT" | translate }}
            </p>
          </div>
        </form>
        <div class="inline-align">
          <div class="">
            <div class="form-group col-12 px-0">
              <div id="reset-btn">
                <button class="custom-primary-btn left" type="button" (click)="resetPassword()">
                  {{ "ACCOUNT.PERSONAL.RESET_PASSWORD.RESET_BTN" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <hr>
  <div class="qr_code">
    <h1 class="sub-heading">{{ "ACCOUNT.PERSONAL.QR_CODE.HEADING" | translate }}</h1>
    <div style="padding: 20px 0;">
      <img *ngIf="showQR" [src]="imgSrc" alt="">
      <label *ngIf="!showQR" class="normal-text">{{ "ACCOUNT.PERSONAL.QR_CODE.HINT_NO_QR_CODE" | translate }}</label>
    </div>
    <div class="mt-3">
      <button *ngIf="showQR" class="custom-primary-btn left" type="submit" (click)="generateNewQR()">
        {{ "Get a new QR Code" | translate }}
      </button>
    </div>
  </div>
  <form role="form" name="loginAfterRegisterForm"> <input name="rememberMe" type="checkbox" checked="true" value="true"
      [style.visibility]="'hidden'" />
  </form>
</div>