import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  IClientInfo,
  IClientInfoRequest,
} from './models/client-info.interface';
import { ISDKConfig } from './models/sdk-config.interface';
import { CidaasService } from '../service/cidaas.service';
import { SharedDataService } from '../service/shared-data.service';
import { SpinnerService } from 'src/service/spinner.service';
import { environment } from '../environments/environment';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'bildungscampus-user-ui';
  logoImage = '';
  requestId = '';
  isLoading: Subject<boolean> = this.loader.isloading;
  language: string;

  // constructor
  constructor(
    private dataService: SharedDataService,
    private cidaasSDK: CidaasService,
    public translate: TranslateService,
    private loader: SpinnerService,
    private route: ActivatedRoute, public spinnerService: SpinnerService,
  ) {
    translate.addLangs(['de', 'en']);
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    // Add 'implements OnInit' to the class.
    this.init();
    this.setLanguage();
  }

  setLanguage(){
    this.language = (this.translate.getBrowserLang().includes('de') || localStorage.getItem("lang") == 'de' ? 'de' : 'en');
    this.translate.use(this.language)
    this.dataService.setLanguage(this.language);
  }

  // Component property methods
  init(): void {
    // getting base url
    this.dataService._setBaseURL(environment.base_url);
    // getting requestId
    this.route.queryParams.subscribe((params) => {
      this.requestId = params.requestId || params.requestid || null;
      if (this.requestId) {
        localStorage.setItem('requestId', this.requestId)
        this.dataService._setRequestId(this.requestId);
        this.getClientInfo({ requestId: this.requestId });
      }
    });
    // intialising cidaas sdk
    this.initCidaasSDK({ authority: environment.base_url });
    // get client info
    // get logo url
    this.getLogo();
  }

  // initializing cidaas sdk
  initCidaasSDK(options: Pick<ISDKConfig, 'authority'>): void {
    this.cidaasSDK._initSDK(options);
  }

  // get client info
  getClientInfo(options: IClientInfoRequest): void {
    this.cidaasSDK._getClientInfo(options);
  }

  // get logo url
  getLogo(): void {
    this.dataService
      ._getClientInfo()
      .subscribe((data: Pick<IClientInfo, 'logo_uri'>) => {
        if (data && data.logo_uri) {
          this.logoImage = data.logo_uri;
        } else {
          this.logoImage = 'assets/img/default-logo.png';
        }
      });
  }

}
