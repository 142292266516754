import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/service/common.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-password-set-success',
  templateUrl: './password-set-success.component.html',
  styleUrls: ['./password-set-success.component.css']
})
export class PasswordSetSuccessComponent implements OnInit {

  constructor(
    private commonFunc: CommonService,
    private router: Router
    ) { }

  ngOnInit(): void {
  }

  backToLogin() {
    this.commonFunc.getRedirectedByRequestIdWithQueryParams();
  }

}
