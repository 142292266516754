<div>
  <div *ngIf="waiting">
    <h4 class="header-label">
      {{ "FOURTH_PAGE.PLEASE_WAIT" | translate }}
    </h4>
  </div>
  <div *ngIf="snapNSmileSuccess">
    <h4 class="header-label">{{ "FOURTH_PAGE.TITLE" | translate }}</h4>
    <p class="secondary-header-label">
      {{ "FOURTH_PAGE.SUBTITLE" | translate }}
    </p>
    <div class="response-container">
      <p class="secondary-header-label">{{ "FOURTH_PAGE.INFO_TEXT" | translate }}</p>
      <br />

      <div class="tip-container">
        <div>
          <img src="assets/img/error-icon-blue.svg" alt="">
        </div>
        <div class="tip-hint">
          <p class="tip-header">{{ "MANUAL_PICTURE_UPLOAD.TIP_HEADER" | translate }}</p>
          <p class="tip-label" innerHTML="{{ 'MANUAL_PICTURE_UPLOAD.TIP_HINT' | translate }}"></p>
          <a class="tip-link" href="https://bildungscampus.cards/ppSSO">{{ "MANUAL_PICTURE_UPLOAD.TO_PAYMENT_PORTAL" | translate }}<img src="assets/img/right-arrow.svg" alt=""></a>
        </div>
      </div>
      <br />

      <div class="row">
        <div class="py-3 back-btn-align col-4">
        </div>
        <div class="py-3 back-btn-align col-8">
          <button type="submit" class="custom_primary_btn1" (click)="goToNextPage()">
            {{ "FOURTH_PAGE.BUTTON_GOON" | translate }}
            <img style="margin-top: 5px;" src="assets/img/active-arrow.svg" alt="">
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="snapNSmileFailure">
    <h4 class="header-label">{{ "FOURTH_PAGE.TITLE_FAILURE" | translate }}</h4>
    <p class="secondary-header-label">
      {{ "FOURTH_PAGE.SUBTITLE_FAILURE" | translate }}
    </p>
    <div class="response-container">
      <p class="secondary-header-label">{{ "FOURTH_PAGE.INFO_TEXT_FAILURE" | translate }}</p>
      <br />
      <div class="row bottom-btn">
        <div class="py-3 back-btn-align col-5">
          <button type="button" class="navigate-btn1" (click)=goToIdValidator()>
            <img style="margin-top: 5px;" src="assets/img/left-arrow.svg" alt="">
            {{ "FOURTH_PAGE.BUTTON_ID_VALIDATOR" | translate }}
          </button>
        </div>
        <div class="py-3 back-btn-align col-7">
          <button type="submit" class="custom_primary_btn1" (click)="goToPicutreUpload()">
            {{ "FOURTH_PAGE.BUTTON_PICTURE_UPLOAD" | translate }}
            <img style="margin-top: 5px;" src="assets/img/active-arrow.svg" alt="">
          </button>
        </div>
      </div>
    </div>
  </div>
</div>