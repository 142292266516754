import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonService } from './common.service';
import { SharedDataService } from './shared-data.service';
import { SpinnerService } from './spinner.service';
import { ToastrService } from 'ngx-toastr';
import { CidaasService } from './cidaas.service';
@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    access_token = '';
    requestId = '';
    constructor(private router: Router, private route: ActivatedRoute, private _loader: SpinnerService, private commonFunc: CommonService, private http: HttpClient, private dataService: SharedDataService, private toastr: ToastrService, private cidaasService: CidaasService) { }

    canActivate() {
        const urlParams = new URLSearchParams(window.location.hash.substring(1));
        this.access_token = urlParams.get('access_token')
        if (this.access_token == '' || this.access_token == 'undefined' || this.access_token == null) {
            this.access_token = localStorage.getItem('access_token');
        }

        this.requestId = localStorage.getItem('requestId')
        this.dataService._setRequestId(this.requestId);


        if (!this.access_token) {
            // redirect to login
            this.toastr.error('', 'Session Expired', {
                timeOut: 3000,
            })
            let options = {
                access_token: this.access_token
            }
            this.cidaasService.logout(options)
            // this.commonFunc.getRedirectedByRequestIdWithQueryParams(null, this.access_token);
            return false;
        } else {
            this._validate_token(this.access_token)
        }
    }

    _validate_token = (access_token) => {
        let options = {
            access_token: this.access_token
        }
        if (!access_token) {
            this.toastr.error('', 'Session Expired', {
                timeOut: 3000,
            });
            this._loader._stop();
            this.cidaasService.logout(options)

            // this.commonFunc.getRedirectedByRequestIdWithQueryParams();
        }
        let body = {
            token: access_token,
            token_type_hint: "access_token",
        };
        this.http
            .post(environment.base_url + "/token-srv/introspect", body)
            .subscribe(
                (data) => {
                    if (data) {
                        if (!data["active"]) {
                            this.toastr.error('', 'Session Expired', {
                                timeOut: 3000,
                            });
                            this._loader._stop();
                            this.cidaasService.logout(options)
                            // this.commonFunc.getRedirectedByRequestIdWithQueryParams();
                        } else {
                            return true
                        }
                    } else {
                        this.toastr.error('', 'Session Expired', {
                            timeOut: 3000,
                        });
                        this._loader._stop();
                        this.cidaasService.logout(options)
                        // this.commonFunc.getRedirectedByRequestIdWithQueryParams();
                    }
                },
                (err) => {
                    this.toastr.error('', 'Session Expired', {
                        timeOut: 3000,
                    });
                    this._loader._stop();
                    this.cidaasService.logout(options)
                    // this.commonFunc.getRedirectedByRequestIdWithQueryParams();
                }
            );
    };
}
