<div class="d-flex header">
  <nav
    class="navbar navbar-light navbar-expand-md d-flex justify-content-between w-100"
  >
    <div class="flex-fill">
      <img
        src="assets/Icons/bildungs-logo.svg"
        alt="img"
        class="logo"
      />
    </div>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#collapsibleNavbar"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse pl-md-5" id="collapsibleNavbar">
      <ul class="navbar-nav flex-fill flex-wrap">
        <li class="nav-item">
          <a class="nav-link cursor" (click)="_changeTab('dashboard')">{{
            "DASHBOARD.HEADER_LABEL.DASHBOARD" | translate
          }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link cursor" (click)="_changeTab('editprofile')">{{
            "DASHBOARD.HEADER_LABEL.PROFILE" | translate
          }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link cursor">{{
            "DASHBOARD.HEADER_LABEL.ACTIVITIES" | translate
          }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link cursor" (click)="_changeTab('access-details')">{{
            "DASHBOARD.HEADER_LABEL.ACCESS DETAILS" | translate
          }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link cursor" (click)="_changeTab('devices-details')">{{
            "DASHBOARD.HEADER_LABEL.DEVICES" | translate
          }}</a>
        </li>
        <li class="nav-item">
          <!-- <a class="nav-link cursor">{{
            "DASHBOARD.HEADER_LABEL.CONSENT" | translate
          }}</a> -->
          <a class="nav-link cursor" (click)="_changeTab('consent')">{{
            "DASHBOARD.HEADER_LABEL.CONSENT" | translate
          }}</a>
        </li>

        <div class="nav-item dropdown ml-md-auto d-flex">
           
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{user?.given_name}} {{user?.family_name}}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item"> {{user?.email}}</a>
              <a class="dropdown-item ">{{'DASHBOARD.HEADER_LABEL.REGISTER_AT' | translate}} {{user?.createdTime| date: 'dd.mm.yyyy'}}</a>
              <a class="dropdown-item cursor" (click)="_changeTab('logout')">{{'LOGOUT_SECTION.LOGOUT' | translate}}</a>
            </div>
        </div>
        <div class="user-circle">
          <div class="user-icon d-flex">
            <i class="fa fa-user m-auto" aria-hidden="true"></i>
          </div>
        </div>
      </ul>
    </div>
  </nav>
</div>
