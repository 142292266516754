<div>
  <h4 class="header-label">{{ "VIDEO_PREVIEW.TITLE" | translate }}</h4>

  <div class="response-container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div id="player"></div>
      </div>
    </div>
  </div>
  <div class="bottom-btn">
    <div class="back-btn-align">
      <button type="button" class="navigate-btn" (click)=backClick()>
        <img src="assets/img/left-arrow.svg" alt="">
        {{ "LOGIN.AUTHENTICATION.BACK" | translate }}
      </button>
    </div>
    <div class="back-btn-align">
      <button type="submit" [ngClass]="getDisable()" id="next_btn" (click)="startVideoIdent()" [disabled]="!videoEnded">
        {{ "VIDEO_PREVIEW.NEXT_BUTTON" | translate }}
        <img id="arrowDeactive" src="assets/img/deactive-arrow.svg" alt="">
        <img id="arrowActive" style="margin-top: 6px; display: none;" src="assets/img/active-arrow.svg" alt="">
      </button>
    </div>
  </div>
</div>