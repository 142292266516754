<div>
  <h4 class="header-label">{{ "THIRD_PAGE.TITLE" | translate }}</h4>
  <p class="secondary-header-label">
    {{ "THIRD_PAGE.SUBTITLE" | translate }}
  </p>
  <h5 class="secondary-header-label">{{ "THIRD_PAGE.VIDEO_IDENT_INFO" | translate }}</h5>
  <div class="response-container">
    <div class="row">
      <div class="col-4 col-md-4 col-sm-4">
        <img class="reg-image" src="assets/Icons/step-3-image.jpeg" alt="iconUpload" />
      </div>

      <div class="col-lg-8 col-md-8 col-sm-8">
        <h4 class="sub-header">{{'THIRD_PAGE.HINT' | translate}}:</h4>
        <ul class="video-detection-hints">
          <li innerHTML="{{'THIRD_PAGE.HINT_ONE' | translate}}"></li>
          <li innerHTML="{{'THIRD_PAGE.HINT_TWO' | translate}}"></li>
          <li innerHTML="{{'THIRD_PAGE.HINT_THREE' | translate}}"></li>
        </ul>
      </div>
    </div>
  </div>

  <div class="containers mt-3">
    <div id="accordion">
      <div class="card">
        <div class="card-header">
          <a class="card-link" data-toggle="collapse" href="#collapseOne1">
            {{ "THIRD_PAGE.ID_VAL_CONSENT_HEADER" | translate }}
          </a>
        </div>
        <div id="collapseOne1" class="collapse" data-parent="#accordion">
          <div class="card-body">
            {{ "THIRD_PAGE.ID_VAL_CONSENT" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bottom-btn">
    <div class="back-btn-align">
      <button type="button" class="navigate-btn" (click)=backClick()>
        <img src="assets/img/left-arrow.svg" alt="">
        {{ "LOGIN.AUTHENTICATION.BACK" | translate }}
      </button>
    </div>
    <div class="back-btn-align">
      <button type="submit" class="custom_primary_btn1" (click)="startVideoIdent()">
        {{ "THIRD_PAGE.IDENT_BUTTON" | translate }}
        <img style="margin-top: 6px;" src="assets/img/active-arrow.svg" alt="">
      </button>
    </div>
  </div>

  <p class="bottom_link_container">
    <span (click)="goWithNoIdentification()" class="pass-link">{{
      "THIRD_PAGE.REJECT_VIDEO_IDENT" | translate
      }}</span>
  </p>
  <form role="form" name="loginAfterRegisterForm"> <input name="rememberMe" type="checkbox" checked="true" value="true"
    [style.visibility]="'hidden'" /> </form>
</div>
