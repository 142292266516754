<h4 class="header-label">{{ "RESET.RESET_SUCCESS_HEADER" | translate }}</h4>
<p class="secondary-header-label">
  {{ "RESET.RESET_SUCCESS_HINT" | translate }}
</p>


<div class="row">
  <div class="py-3 back-btn-align col-6">
  </div>
  <div class="py-3 back-btn-align col-6">
    <button type="submit" (click)=backToLogin() class="custom_primary_btn1">
      {{ "RESET.RESET_LOGIN_BTN" | translate }}
      <img src="assets/img/active-arrow.svg" style="margin-top: 6px;" alt="">
    </button>
  </div>
</div>