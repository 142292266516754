import { Mandator } from "../../../models/mandator.model";


export const Employed: Mandator[] = [
  { value: '14', name: 'MANDATOR_OPTION1' },
  { value: '05', name: 'MANDATOR_OPTION2' },
  { value: '02', name: 'MANDATOR_OPTION12' },
  { value: '10', name: 'MANDATOR_OPTION4' },
  { value: '03', name: 'MANDATOR_OPTION6' },
  {
    value: '06',
    name: 'MANDATOR_OPTION3',
  },
  { value: '04', name: 'MANDATOR_OPTION5' },
  { value: '18', name: 'MANDATOR_OPTION7' },
  { value: '01', name: 'MANDATOR_OPTION15' },
  {
    value: '13',
    name: 'MANDATOR_OPTION8',
  },
  {
    value: '12',
    name: 'MANDATOR_OPTION9',
  },
  { value: '07', name: 'MANDATOR_OPTION10' },
  { value: '11', name: 'MANDATOR_OPTION11' },
  { value: '15', name: 'MANDATOR_OPTION16' },
  { value: '08', name: 'MANDATOR_OPTION14' },
  { value: '99', name: 'MANDATOR_OPTION18' },
  { value: '16', name: 'MANDATOR_OPTION17' },
  { value: '09', name: 'MANDATOR_OPTION13' },
  { value: '20', name: 'MANDATOR_OPTION20' }

];
export const Students: Mandator[] = [
  
  { value: '14', name: 'MANDATOR_OPTION1' },{
    value: '06',
    name: 'MANDATOR_OPTION3',
  },
  { value: '04', name: 'MANDATOR_OPTION5' },
  { value: '18', name: 'MANDATOR_OPTION7' },
  
  { value: '07', name: 'MANDATOR_OPTION10' },
  { value: '09', name: 'MANDATOR_OPTION13' },
  { value: '11', name: 'MANDATOR_OPTION11' },
  { value: '20', name: 'MANDATOR_OPTION20' }
];
