import { Component, Input, OnInit, Output, EventEmitter, OnChanges, ViewChild, ElementRef, PipeTransform, Pipe } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { CustomValidatorHelper } from './../../helpers/custom-validator.helper';
import { SharedDataServices } from './../../services/shared-data.service';

@Pipe({ name: 'searchField' })
export class SearchFieldPipe implements PipeTransform {
  transform(value, fieldKey): any {
    value.filter((x) => {
      return x.fieldKey === fieldKey;
    });
    return value.filter((x) => {
      return x.fieldKey === fieldKey;
    })[0];
  }
}

@Pipe({ name: 'removeFields' })
export class RemoveFieldsPipe implements PipeTransform {
  transform(value, fieldKeys): any {
    return value.filter((x) => {
      return !fieldKeys.includes(x.fieldKey);
    });
  }
}
@Component({
  exportAs: 'dynamicForm',
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit, OnChanges {
  @Output() onSubmit = new EventEmitter();
  @Output() onReset = new EventEmitter();
  @Output() btnId = new EventEmitter();
  @Output() formInstance = new EventEmitter();
  @Input() fields: any[] = [];
  @Input() options: any = {};
  @Input() dynamicfields: any = [];
  @Input() enableDynamicFields;
  @Input() isSubmitted: boolean = false;
  @Input() appearance: any = 'standard';
  @Input() refreshEnabled: boolean = false;
  @ViewChild('submitBtn') SubmitBtn;
  dynamicFields: any = [];
  cancelBtn_Id: number = 0;
  submitBtn_Id: number = 0;
  submitButton: any = {};
  cancelButton: any = {};
  allowedValidationTypes = [
    'required',
    'minLength',
    'maxLength',
    'pattern',
    'digitsLength',
    'specialLength',
    'lowerupperCase',
    'matchWith',
    'invalidmobile',
  ];
  form;

  get value() {
    return this.form.value;
  }

  constructor(private fb: FormBuilder, private customValidator: CustomValidatorHelper, private _sharedDataService: SharedDataServices) {
    this.form = this.fb.group({});
  }

  ngOnInit(): void {
    this.submitBtn_Id = new Date().getTime();
    this.cancelBtn_Id = new Date().getTime();
    let id = { submit_id: this.submitBtn_Id, cancel_id: this.cancelBtn_Id };
    this.btnId.emit(id);
    if (this.fields.length) {
      this.form = this._createControl(this.fields);
      this.formInstance.emit(this.form);
    }

    // this._sharedDataService._sendFormValue(this.form);

  }

  // sendFormValue() {
  //   this.formInstance.emit(this.form);
  // }

  ngOnChanges() {
    if (this.fields.length) {
      if (this.refreshEnabled) {
        this.form = this.fb.group({});
      }
      this.form = this._createControl(this.fields);
      this.formInstance.emit(this.form);
    }
    if (!this.enableDynamicFields) {
      this._removeControls();
    }
    if (this.options) {
      this.submitButton = {
        text: this.options?.btnSubmit?.text || 'Submit',
        matColor: this.options?.btnSubmit?.matColor || 'primary',
        enabled: this.options?.btnSubmit?.enabled || false,
      };
      this.cancelButton = {
        text: this.options?.btnCancel?.text || 'Cancel',
        matColor: this.options?.btnCancel?.matColor || 'default',
        enabled: this.options?.btnCancel?.enabled || false,
      };
    }
  }

  _onSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.form.valid) {
      let filledform = JSON.parse(JSON.stringify(this.form.value));
      this._updatePrefix(filledform);
      this.onSubmit.emit(filledform);
    } else {
      this._validateAllFormFields(this.form);
    }
  }

  _updatePrefix = (formvalues) => {
    let prefixes = this.fields
      .filter((x) => {
        return x.dataType === 'MOBILE';
      })
      .map((x) => {
        return x.fieldKey;
      });
    Object.keys(formvalues).map((x) => {
      if (prefixes.includes(x) && formvalues && formvalues[x]) {
        this._sharedDataService.prefix.subscribe((item) => {
          item.map((field) => {
            if (field['fieldkey'] == x) {
              formvalues[x] = field['code'] + formvalues[x];
            }
          });
        });
      }
    });
  };

  _onReset = () => {
    this.form.reset();
    this.form = this._createControl(this.fields);
    this.onReset.emit(true);
  };
  _onFormSubmit = () => {
    this.form.submit();
    this.isSubmitted = false;
  };

  _createControl = (fields, isFormArray = false) => {
    let group = isFormArray ? this.fb.group({}) : this.form;
    fields.forEach((field) => {
      if (field.dataType === 'button') return;
      let fieldKey = field.fieldKey ? field.fieldKey : field.key;
      if (!isFormArray && group.get(field.fieldKey) == null) {
        const control = this.fb.control(this._getInitialValue(field, isFormArray), this._bindValidations(field) || []);
        group.addControl(fieldKey, control);
      } else {
        const control = this.fb.control(this._getInitialValue(field, isFormArray), this._bindValidations(field) || []);
        group.addControl(fieldKey, control);
      }
    });
    return group;
  };

  _addControls = () => {
    this.dynamicFields.map((field) => {
      if (this.form.get(field.fieldKey) == null) {
        const control = this.fb.control(this._getInitialValue(field), this._bindValidations(field) || []);
        this.form.addControl(field.fieldKey || field.key, control);
      }
    });
  };

  _removeControls = () => {
    this.dynamicfields.map((field) => {
      if (this.form && this.form.get(field)) {
        this.form.removeControl(field);
      }
    });
  };

  _getInitialValue = (field: any, isFormArray = false) => {
    if (!isFormArray && field.value) {
      return field.value;
    }

    let booleanTypes = ['CHECKBOX', 'CONSENT'];
    if (booleanTypes.includes(field.dataType) || field.dataType == undefined) {
      return null;
    } else {
      return '';
    }
  };

  _bindValidations = (field: any) => {
    let validations = this._getValidations(field).map((x) => {
      return x.validator;
    });
    if (validations) return Validators.compose(validations);
    return null;
  };

  _validateAllFormFields = (formGroup: FormGroup) => {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control) control.markAsTouched({ onlySelf: true });
    });
  };

  _getValidations = (field: any) => {
    let validations: any = [];
    if (field.fieldDefinition) {
      Object.keys(field.fieldDefinition).map((key) => {
        if (this._generateValidationFormat(key, field)) {
          validations.push(this._generateValidationFormat(key, field));
        }
      });
    }
    if (field.required) {
      validations.push(this._generateValidationFormat('required', field));
    }
    field['validation'] = validations;
    return validations;
  };

  _generateValidationFormat = (type, field) => {
    let validation = { name: '', validator: {}, message: '' };
    if (this.allowedValidationTypes.includes(type)) {
      validation.message = (field.localeText !== undefined && field.localeText[type]) ? field.localeText[type] : 'REQUIRED_FIELD';
      validation.name = type.toLowerCase();
      if (type == 'minLength') {
        validation.validator = Validators.minLength(field.fieldDefinition[type]);
      }
      if (type == 'maxLength') {
        validation.validator = Validators.maxLength(field.fieldDefinition[type]);
      }
      if (type == 'pattern') {
        validation.validator = Validators.pattern(field.fieldDefinition[type]);
      }

      if (type == 'invalidmobile') {
        validation.validator = this.customValidator._validateMobile();
      }

      if (type == 'required') {
        if (field.required) {
          let customValidatorTypes = ['CHECKBOX', 'CONSENT'];
          if (customValidatorTypes.includes(field.dataType)) {
            validation.validator = this.customValidator._validateCheckbox;
          } else {
            validation.validator = Validators.required;
          }
        }
      }
      if (type == 'digitsLength') {
        if (field.fieldDefinition['digitsLength'] > 0) {
          validation.validator = this.customValidator._validateNoOfDigits(field.fieldDefinition['digitsLength']);
        }
      }

      if (type == 'specialLength') {
        if (field.fieldDefinition['specialLength'] > 0) {
          validation.validator = this.customValidator._validateNoOfSpecial(field.fieldDefinition['specialLength']);
        }
      }

      if (type == 'lowerupperCase') {
        if (field.fieldDefinition['lowerupperCase'] > 0) {
          validation.validator = this.customValidator._validateCaseLowerUpper();
        }
      }

      if (type == 'matchWith') {
        if (field.fieldDefinition['matchWith']) {
          validation.validator = this.customValidator._validateMatchField();
        }
      }
      if (type == 'invalidmobile') {
        if (field.fieldDefinition['invalidmobile']) {
          validation.validator = this.customValidator._validateMobile();
        }
      }

      return typeof (validation?.validator) == "function" ? validation : null;
      // return validation;
    }
    return null;
  };

  _getdynamicFields = () => {
    this.fields = this.fields.filter((x) => {
      if (this.dynamicfields.includes(x.fieldKey)) {
        this.dynamicFields.push(x);
      } else {
        return x;
      }
    });
  };
}
