import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { CompleteRegisterService } from './complete-register.service';
import { SpinnerService } from './spinner.service';

@Injectable({
  providedIn: 'root',
})
export class PictureUploadService {
  baseUrl = environment.base_url;

  formData = new FormData();

  selectedPicture = new BehaviorSubject<FileList>(null);
  isPictureUploaded = new BehaviorSubject<boolean>(false);
  pictureTooLarge = new BehaviorSubject<boolean>(false);
  isShowingUploadedMessage = new BehaviorSubject<boolean>(false);
  isLoading = new BehaviorSubject<boolean>(false);
  isShowingCropper = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private completeRegister: CompleteRegisterService,
    private _loader: SpinnerService
  ) { }

  onSelect(event: any) {
    this.isShowingUploadedMessage.next(false);
    this.selectedPicture.next(event.target.files[0]);
  }

  getCroppedPicture(file: any, sub: string) {
    this.formData = new FormData(document.forms[0]);
    this.formData.append("photo", file, file.name);
    this.formData.append("image_key", sub);
  }
  public getProfilePicture(sub: string): Observable<any> {
    const url = this.baseUrl + '/profile/' + sub;
    return this.http.get(url, ({ responseType: 'blob', headers: this.getupdatedPictureUploadHeader() }));
  }
  file:any;
  onUpload(file: any, send_email: boolean) {
    this.isLoading.next(true);
    this._loader._stop();
    const f = this.dataURLtoFile(file, 'picture.png');
    const pictureUploadUrl = this.baseUrl + '/image-srv/profile/upload';
    let email!: string;
    let given_name!: string;
    let family_name!: string;
    let sub!: string;
    this._loader._stop();
    this.completeRegister.getUserInfo().then((response) => {
      email = response.email;
      given_name = response.given_name;
      family_name = response.family_name;
      sub = response.sub;
      this.getCroppedPicture(f, sub);
      this.pictureTooLarge.next(false);
      this.http
        .post<any>(pictureUploadUrl, this.formData, {
          headers: this.getPictureUploadHeader(),
        })
        .subscribe(
          (response) => {
            this._loader._stop();
            if (send_email) {
              this.sendEmail(email, given_name, family_name).subscribe(
                (response) => { },
                (e) => {
                  console.log(e);
                }
              );
            }
            this.updateUserStatus(sub).subscribe(
              (response) => { },
              (e) => {
                console.log(e);
              }
            );
            this.isLoading.next(false);
            this.isShowingUploadedMessage.next(true);
            this.isShowingCropper.next(false);
            this.isPictureUploaded.next(true);
          },
          (e) => {
            if (e.status === 413) {
              this.pictureTooLarge.next(true)
            }
            this.isLoading.next(false);
            this.isShowingUploadedMessage.next(true);
            this.isPictureUploaded.next(false);
            this.isShowingCropper.next(true);
            console.log(e);
          }
        );
    });
  }

  dataURLtoFile(dataUrl: string, fileName: string) {
    var dataArr = dataUrl.split(','),
      mime = dataArr[0].match(/:(.*?);/)[1],
      decodedStr = atob(dataArr[1]),
      l = decodedStr.length,
      u8arr = new Uint8Array(l);
    while (l--) {
      u8arr[l] = decodedStr.charCodeAt(l);
    }
    return new File([u8arr],fileName, { type: mime });
  }
  sendEmail(
    email: string,
    given_name: string,
    family_name: string
  ): Observable<any> {
    const sendEmailUrl = this.baseUrl + '/notification-srv/email/send';

    let emailData!: Object;
    emailData = {
      to: [email],
      from: 'noreply@bildungscampus.life',
      fromname: 'Bildungscampus',
      templateKey: 'WELCOME_MAIL_NO_VIDEOIDENT',
      userEmailObject: [
        {
          to: email,
          emailObject: {
            content: {
              name: given_name,
              family_name,
            },
          },
        },
      ],
    };
    return this.http.post<any>(sendEmailUrl, emailData, {
      headers: this.getSendMailHeader(),
    });
  }

  updateUserStatus(sub: string): Observable<any> {
    const updateUserUrl = this.baseUrl + '/users-srv/user/profile/' + sub;

    let updatedUser!: Object;

    updatedUser = {
      customFields: {
        status: 'user_pending_image_validation',
      },
      sub: sub,
      provider: 'self',
    };
    return this.http.put<any>(updateUserUrl, updatedUser, {
      headers: this.getUpdateUserHeader(),
    });
  }

  getPictureUploadHeader() {
    return new HttpHeaders()
      .set('accept', 'application/json, text/plain, */*')
      .set('Authorization', 'Bearer ' + this.completeRegister.getAccessToken());
  }
  getupdatedPictureUploadHeader() {
    return new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.completeRegister.getAccessToken());
  }
  getSendMailHeader() {
    return new HttpHeaders()
      .set('accept', 'application/json')
      .set('Authorization', 'Bearer ' + this.completeRegister.getAccessToken());
  }

  getUpdateUserHeader() {
    return new HttpHeaders()
      .set('accept', 'application/json')
      .set('Authorization', 'Bearer ' + this.completeRegister.getAccessToken());
  }
}
