import { Component, OnInit } from '@angular/core';
import { CompleteRegisterService } from 'src/service/complete-register.service';
import jwt_decode from "jwt-decode";
import { SpinnerService } from 'src/service/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { EmailList } from '../../../util/complete-register/mandator_email_lists';

declare var $: any;
@Component({
  selector: 'app-campus-card',
  templateUrl: './campus-card.component.html',
  styleUrls: ['./campus-card.component.scss']
})
export class CampusCardComponent implements OnInit {
  sub: any;
  kost_card_lost_price: number;
  price: number;
  show_confirmation_popup: boolean = false;
  showkost_terms: boolean = false;
  kost_terms: boolean;
  card_status: string;
  profile_picture: any;
  passId: string;
  picture_upload_required: boolean = false;
  user_details: any = {};
  campus_id: string;
  access_token: string;
  grant_type: string;
  is_processed_to_lock: boolean = false;
  first_step: boolean = true;
  return_from_picture_upload: boolean = false;
  passDetails: any = {};
  readonly autoPrintProfiles = ["08C"];//"08A", "08B", "08C", "08D", "05A"
  imgSrc: string = '';
  qrResponse: any;

  constructor(
    private complete_register: CompleteRegisterService,
    public loader: SpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private _loader: SpinnerService
  ) { }

  ngOnInit(): void {
    const urlParams = new URLSearchParams(window.location.hash.substring(1));
    let token = urlParams.get('access_token')
    if (token == '' || token == 'undefined' || token == null) {
      token = localStorage.getItem('access_token');
    }
    this.access_token = token;
    this.complete_register.setAccessToken(token);
    this.sub = this.getDecodedAccessToken(token).sub ? this.getDecodedAccessToken(token).sub : "";
    this.getUserInfo();
    this.passesBySub(false);
    if (localStorage.getItem('upload_picture_from_card_lost') === 'true') {
      this.return_from_picture_upload = true;
      this.setProfilePicture();
      localStorage.removeItem('upload_picture_from_card_lost');
    }
  }
  getUserInfo() {
    this._loader._start();
    this.complete_register.getUserInfo().then(data => {
      this._loader._stop();
      if (data) {
        this.user_details = data;
      } else {
        this.toastr.error('Error Occured');
      }
    }, error => {
      this.toastr.error('Error Occured');
      this._loader._stop();
    })
  }
  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return "";
    }
  }
  passesBySub(lockCard) {
    this._loader._start();
    this.complete_register.getPassStatus(this.sub).subscribe((data: any) => {
      this._loader._stop();
      if (data && data.data.passes && data.data.passes.length > 0) {
        this.passDetails = data.data.passes.find(pass => pass.pass.productId == 'campus_card')
        this.passDetails = this.passDetails.pass;
        if (this.passDetails && Object.keys(this.passDetails).length > 0) {
          this.passId = this.passDetails.passId;
          this.card_status = this.passDetails.state;
          if (this.passDetails.customFields && this.passDetails.customFields.kost_card_lost_price) {
            this.kost_card_lost_price = parseInt(this.passDetails.customFields.kost_card_lost_price);
            this.price = Math.abs(this.kost_card_lost_price)
          }
          if (lockCard) {
            setTimeout(() => {
            this.campusCardLock(this.passId);
            }, 1000);
          }
        }
        else {
          this.toastr.error('Error Occured');
        }
      }
      else {
        this.toastr.error('Error Occured');
      }
    }, (error: any) => {
      this._loader._stop();
      if (error) {
        this.toastr.error('Error Occured');
      }
    })
  }

  isSelfPrintEnabled(campus_id) {
    if (campus_id) {
      const profile_from_campus_id = campus_id.substring(0, 3);
      const found = this.autoPrintProfiles.find((obj) => {
        return obj === profile_from_campus_id;
      });
      if (found) {
        return true;
      }
    }
    return false;
  }

  campusCardLock(passId) {
    this._loader._start();
    this.complete_register.setCampusCardLost(passId).subscribe((data: any) => {
      this._loader._stop();
      if (data && data.status == 200) {
        if (this.user_details && this.user_details.customFields!.campus_id
          && this.isSelfPrintEnabled(this.user_details.customFields.campus_id)) {
          let requestBody =
          {
            "person": {
              "pass_id": this.passDetails.passId,
              "task_id": this.passDetails.customFields.task_id,
              "campus_id": this.user_details.customFields.campus_id
            }
          }
          this.complete_register.generateNewQR(requestBody).subscribe(data => {
            this._loader._stop();
            if (data && data.data && data.success) {
              this.qrResponse = data.data.person;
              this.imgSrc = environment.base_url + "/kms-srv/qr?qr=" + this.qrResponse.qr_code + "&size=200";
            } else {
              this.toastr.error('Error Occured');
            }
          }, error => {
            this.toastr.error('Error Occured');
            this._loader._stop();
          })
        }

        if (!(Object.keys(this.user_details).length > 0)) {
          this.getUserInfo();
        }
        let selectedMandator = EmailList.find(mandator => {
          if (mandator.value === this.user_details.customFields.mandator) {
            return mandator
          }
        });

        if (selectedMandator.email != '') {
          let options: any = {
            fromEmail: this.user_details.email,
              toEmail: selectedMandator.email,
              name: this.user_details.given_name + ' ' + this.user_details.family_name,
              campus_id: this.passDetails.customFields.campus_id,
              template: 'LOST_CAMPUS_CARD'
          };
          this.complete_register.sendEmail(options).subscribe(
            (response) => { console.log("Email send successfully"); },
            (e) => {
              console.log("Email send", e);
            }
          );

          if (this.isSelfPrintEnabled(this.user_details.customFields.campus_id)) {
            let selfPrintoptions: any = {
                toEmail: this.user_details.email,
                name: this.user_details.given_name + ' ' + this.user_details.family_name,
                campus_id: this.passDetails.customFields.campus_id,
                template: 'SELF_PRINTING_LOST',
                qr_code: this.qrResponse && this.qrResponse.qr_code ? this.qrResponse.qr_code : ''
            };
            this.complete_register.sendEmail(selfPrintoptions).subscribe(
              (response) => { console.log("Email send successfully"); },
              (e) => {
                console.log("Email send", e);
              }
            );
          }
        }

        if ((this.kost_card_lost_price != 0 && this.kost_card_lost_price != null)) {
          this.show_confirmation_popup = true;
          this.is_processed_to_lock = false;
          setTimeout(function () {
          }, 1000);
        }
        else {
          if (this.kost_card_lost_price === 0 || this.kost_card_lost_price === null || this.kost_card_lost_price.toString() === "0") {
            this.setProfilePicture();
          }
          else {
            this.toastr.error('Error Occured');
            this.final_Closepopup();
          }
        }
      }
      else {
        this.toastr.error('Error Occured');
      }
    }, (error: any) => {
      this._loader._stop();
      if (error) {
        this.toastr.error('Error Occured');
      }
    });
  }
  confirmLostCard() {
    this.passesBySub(true);
  }
  cancelLostCard() {
    this.show_confirmation_popup = false;
    this.is_processed_to_lock = false;
    this.first_step = true;
  }
  confirmPay() {
    this._loader._start();
    this.complete_register.getUserInfoProfile().subscribe((data: any) => {
      this._loader._stop();
      this.profile_picture = data.data.identity.picture;
      this.campus_id = data.data.customFields.campus_id.value;
      try {
        if (data.data.customFields.kost_terms.value != null) {
          this.kost_terms = data.data.customFields.kost_terms.value;
        }
        else {
          if (data.data.customFields.kost_terms != null) {
            this.kost_terms = data.data.customFields.kost_terms;
          }
        }
      }
      catch {
        this.kost_terms = false;
      }
      if (data && data.data && data.data.customFields && data.data.customFields.kost_terms) {
        if (this.kost_terms && this.campus_id) {
          this.confirmkost_terms()
        }
        else {
          this.showkost_terms = true;
          this.show_confirmation_popup = true;
        }

      }
      else {
        this.showkost_terms = true;
        this.show_confirmation_popup = true;
      }
    }, (error: any) => {
      this._loader._stop();
      if (error) {
        this.toastr.error('Error Occured');
      }
    });
  }

  confirmkost_terms() {
    let payLoad = {
      provider: "self",
      sub: this.sub,
      customFields: {
        kost_terms: this.kost_terms
      }
    };
    this._loader._start();
    this.complete_register.updatekost_terms(this.sub, payLoad).subscribe((data: any) => {
      this._loader._stop();
      if (data.data && data.status == 200) {
        this.setProfilePicture();
      }
      this.showkost_terms = false;

    }, (error: any) => {
      this._loader._stop();
      if (error) {
        this.toastr.error('Error Occured');
      }
    });

  }
  setProfilePicture() {
    if (this.profile_picture && this.campus_id) {
      this.checkProfilePicture();
    }
    else {
      this._loader._start();
      this.complete_register.getUserInfoProfile().subscribe((data: any) => {
        this._loader._stop();
        this.profile_picture = data.data.identity.picture;
        if (data.data.customFields.campus_id) {
          this.campus_id = data.data.customFields.campus_id.value;
          this.checkProfilePicture();
        }
        else {
          this.toastr.error('Please Contact to Admin');
          this.final_Closepopup();
        }
      }, (error: any) => {
        this._loader._stop();
        if (error) {
          this.toastr.error('Error Occured');
          this.final_Closepopup();
        }
      })
    }
  }

  checkProfilePicture() {
    if (this.profile_picture) {
      if (this.kost_card_lost_price != null && this.kost_card_lost_price != 0 && this.kost_card_lost_price.toString() != "0") {
        this.postTransaction();
      }
      else {
        this.final_Closepopup();
        this.router.navigate(['/campus-card']);
      }
    }
    else {
      this.show_confirmation_popup = true;
      this.picture_upload_required = true;
      this.is_processed_to_lock = false;
    }
  }

  postTransaction() {
    let payLoad = {};
    if (this.campus_id && this.campus_id !== '' && this.campus_id !== null && this.kost_card_lost_price && this.passId) {
      payLoad = {
        campus_id: this.campus_id,
        kost_card_lost_price: this.kost_card_lost_price.toString(),
        pass_id: this.passId
      }
      this._loader._start();
      this.complete_register.saveTransaction(payLoad).subscribe((data: any) => {
        this._loader._stop();
        this.final_Closepopup();
      })
    }
    else {
      this.complete_register.getPassStatus(this.sub).subscribe((data: any) => {
        this._loader._stop();
        if (data && data.data.passes && data.data.passes[0].pass && data.data.passes[0].pass.productId == 'campus_card') {
          this.passId = data.data.passes[0].pass.passId;
          this.card_status = data.data.passes[0].pass.state;
          if (data?.data?.passes[0]?.pass?.customFields?.kost_card_lost_price && data?.data?.passes[0]?.pass?.customFields?.campus_id) {
            this.kost_card_lost_price = data?.data?.passes[0]?.pass?.customFields?.kost_card_lost_price;
            this.campus_id = data?.data?.passes[0]?.pass?.customFields?.campus_id;
            payLoad = {
              campus_id: this.campus_id,
              kost_card_lost_price: this.kost_card_lost_price.toString(),
              pass_id: this.passId
            }
            this._loader._start();
            this.complete_register.saveTransaction(payLoad).subscribe((data: any) => {
              this._loader._stop();
            })
          }
          else {
            this.toastr.error('Error Occured');
          }
        }
        else {
          this.toastr.error('Error Occured');
        }
      }, (error: any) => {
        this._loader._stop();
        if (error) {
          this.toastr.error('Error Occured');
        }
      })
    }
  }

  final_Closepopup() {
    this.getUserInfo();
    this.passesBySub(false);
    this.showkost_terms = false;
    this.show_confirmation_popup = false;
    this.picture_upload_required = false;
    this.first_step = true;
  }
  cancelkost_terms() {
    this.showkost_terms = false;
    this.show_confirmation_popup = false;
    this.toastr.error('Please Contact to Admin');
  }
  cancelPay() {
    this.showkost_terms = false;
    this.show_confirmation_popup = false;
    this.toastr.error('Please Contact to Admin');
  }
  cancelPictureUpload() {
    this.showkost_terms = false;
    this.show_confirmation_popup = false;
    this.picture_upload_required = false;
  }
  pictureUpload() {
    localStorage.setItem('campus_card', 'true')
    this.router.navigate(['/complete-register/manual-picture-upload'], {
      queryParams: {
        token_type: 'Bearer',
        access_token: this.access_token
      }
    });
  }
  redirectToAtlassian() {
    window.location.assign(
      environment.atlassian_url
    );
  }
  proceedToBlock() {
    this.is_processed_to_lock = true;
    this.show_confirmation_popup = true;
    this.first_step = false;
  }
}
