<!-- <div class="input-fields-container" [formGroup]="group">
  <label>{{field?.localeText?.name}}</label>
  <div>
    <input class="input-field" [formControlName]=" field.fieldKey" [placeholder]="field.localeText.name"
      [type]="'password'" [required]="field.required">
    <ng-container *ngFor="let item of group.get(field.fieldKey).errors | keys:1" ngProjectAs="span">
      <span class="errMsg ft-12" *ngIf="group.get(field.fieldKey).touched">
        {{field.validation | error:item.key | translate }}
      </span>
    </ng-container>
  </div>
</div> -->

<div class="mt-3" style="position: relative" [formGroup]="group">
  <div class="has-feedback">
    <p class="input-label">
      {{ field?.localeText?.name }}
    </p>
    <div class="form-field">
      <input [type]="inputType" [formControlName]="field.fieldKey" placeholder="{{'PASSWORD_SET.PLACEHOLDER_LABEL' | translate}}"
        [required]="field.required" class="form-control" (keyup)="sendValues($event)" [name]="field.fieldKey"
        [ngClass]="{'error-form-control': (group.get(field.fieldKey).invalid || !isPatternValid) && (group.get(field.fieldKey).touched || group.get(field.fieldKey).dirty)}" />
      <img class="pass-error-icon"
        *ngIf="group.get(field.fieldKey).invalid && (group.get(field.fieldKey).touched || group.get(field.fieldKey).dirty)"
        src="assets/img/icon-error.svg" style="width: 20px;" alt="">
      <button class="form-cont-feedback show-password-eye" (click)="showPassword()">
        <img src="assets/img/eye-icon.svg" style="width: 20px;" alt="">
      </button>
    </div>
    
    <div
    *ngIf="group.get(field.fieldKey).errors?.required && (group.get(field.fieldKey).touched || group.get(field.fieldKey).dirty)"
    class="errMsg ft-12">
    {{'REQUIRED_FIELD' | translate }}
  </div>

    <div *ngIf="field.fieldKey == 'password' || field.fieldKey == 'PASSWORD'">
      <div *ngIf="
      (!group.get(field.fieldKey).valid || !isPatternValid ) &&
      group.get(field.fieldKey).value.length > 0
    " class="errMsg ft-12">
        {{ "SECOND_PAGE.WRONG_PASSWORD_MESSAGE" | translate }}
      </div>
    </div>

    <div *ngIf="field.fieldKey == 'password_echo' && field.fieldKey != 'PASSWORD'">
      <span *ngIf="group.get('password_echo').value && group.get('password_echo').value != group.get('password').value" class="errMsg ft-12">
        {{'PWD-NOT-MATCH'|translate}}
      </span>
    </div>
  </div>
</div>