import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { RegexUtil } from 'src/app/helpers/regex.helper';
import { CidaasService } from 'src/service/cidaas.service';
import { CommonService } from 'src/service/common.service';
import { SharedDataService } from 'src/service/shared-data.service';
import { SpinnerService } from 'src/service/spinner.service';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  fields = [];
  provider: string = '';
  emailVerified: boolean = false;
  email: string = '';
  missingFieldsStream = new BehaviorSubject([]);
  missingFields = [];
  passwordPolicy: any;
  missingfieldsValues = { given_name: '', email: '', mobile_number: '', username: '' };
  queryParams = { userid_hint: '', userid_type: '', requestId: '', view_type: '', trackId: '' };
  dynamicFormOptions = {
    btnEnabled: false,
  };
  buttonOptions: any = {
    submit: {
      color: 'accent',
      text: 'REGISTER_ADDITIONAL_INFO.SUBMIT',
      alignment: 'right',
      type: 'proceed',
      underline: 'none',
      for: 'cidaasSubmitBtn',
      formId: 'formId',
    },
  };
  rememberMe: boolean = false;
  errorMessage: string = '';
  socialUserInfo: any;


  constructor(
    private _cidaasSDK: CidaasService,
    private _dataService: SharedDataService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _regex: RegexUtil,
    private _translate: TranslateService,
    private commonFunc: CommonService,
    private _loader: SpinnerService
  ) { }

  ngOnInit(): void {
    this.missingFieldsStream.subscribe((data) => {
      this.missingFields = data;
    });
    this._getPasswordPolicy();
    this._initQueryParams();
    this._getRegistrationFields();
    this.getLanguage();
  }

  getLanguage() {
    this._dataService.getLanguage().subscribe((data) => {
      if (data && this.queryParams.requestId) {
        this._getRegistrationFields(data);
      }
    });
  }

  _initQueryParams = (): void => {
    this._route.queryParams.subscribe((data) => {
      this.queryParams.requestId = data.requestId;
      this.queryParams.userid_hint = this._getProperString(data.userid_hint, data.userIdHint);
      this.queryParams.userid_type = data.type;
      this.queryParams.view_type = data.view_type;
      this.queryParams.trackId = data.track_id || data.track_Id || data.trackId;
    });
  };

  _getProperString(userid_hint: string, userIdHint: string) {
    if (userid_hint) {
      return userid_hint.replace(this._regex.removeSpecialChars, '');
    } else if (userIdHint) {
      return userIdHint.replace(this._regex.removeSpecialChars, '');
    } else {
      return '';
    }
  }

  _getMissingFields = (viewType: string): void => {
    let options = { requestId: this.queryParams.requestId, trackId: this.queryParams.trackId };
    this._loader._start();
    this._cidaasSDK
      ._getMissingFields(viewType, options)
      .then((response: any) => {
        this._loader._stop();
        let fields = [];
        let data = response['meta_data'] ? response['meta_data'] : response;
        this.provider = data['identity'] ? data['identity']['provider'] : data['userInfo']['provider'];
        this.email = data['identity'] ? data['identity']['email'] : data['userInfo']['email'];
        this.emailVerified = data['identity'] ? (data['identity']['email'] ? true : false) : data['userInfo']['email_verified'];
        let missingFields = data['missing_fields'] || data['missingFields'];
        Object.keys(this.missingfieldsValues).map((key) => {
          if (data && data['userInfo'] && data['userInfo'][key]) {
            this.missingfieldsValues[key] = data['userInfo'][key];
          } else {
            if (data['identity'] && data['identity'][key]) {
              this.missingfieldsValues[key] = data['identity'][key];
            }
          }
        });
        if (data && data['userInfo']) {
          this.socialUserInfo = data['userInfo'];
        }
        missingFields.map((key) => {
          this.fields.map((field) => {
            if (field['fieldKey'] == key) {
              fields.push(field);
            }
          });
        });
        if (fields.length > 0) {
          this.missingFieldsStream.next(fields);
        }
      })
      .catch((error) => {
        console.log(error);
        this._loader._stop();
      });
  };

  _addvalidations = (fields: any): any => {
    if (fields) {
      fields.forEach((field) => {
        if (field.dataType == 'EMAIL') {
          if (field.fieldDefinition) {
            field.fieldDefinition['pattern'] = this._regex.email;
          } else {
            field['fieldDefinition'] = {};
            field.fieldDefinition['pattern'] = this._regex.email;
          }
          field.localeText['pattern'] = this._translate.instant('FORM.VALIDATION.EMAIL');
        }
        if (field.dataType == 'PASSWORD' && !field.fieldDefinition.matchWith) {
          if (this.passwordPolicy) {
            this._addPasswordPolicy(field);
          }
        }
        if (field.dataType == 'MOBILE') {
          if (field['localeText']) {
            field['localeText']['invalidmobile'] = this._translate.instant('ENTER_VALID_MOBILE');
          } else {
            field['localeText'] = { invalidmobile: this._translate.instant('ENTER_VALID_MOBILE') };
          }
          if (field['fieldDefinition']) {
            field['fieldDefinition']['invalidmobile'] = this._translate.instant('ENTER_VALID_MOBILE');
          } else {
            field['fieldDefinition'] = { invalidmobile: this._translate.instant('ENTER_VALID_MOBILE') };
          }
        }
      });
      return fields;
    }
  };

  _getPasswordPolicy = (): void => {
    this._dataService._getClientInfo().subscribe((data) => {
      if (data.password_policy) {
        this.passwordPolicy = data.password_policy;
      }
      if (data && data.is_remember_me_selected) {
        this.rememberMe = true;
      }
    });
  };

  _addPasswordPolicy = (field: any) => {
    let validations = ['lowerAndUpperCase', 'maximumLength', 'minimumLength', 'noOfDigits', 'noOfSpecialChars'];
    Object.keys(this.passwordPolicy).map((key) => {
      if (validations.includes(key)) {
        if (key == 'maximumLength') {
          field.fieldDefinition['maxLength'] = this.passwordPolicy['maximumLength'] || field.fieldDefinition['maxLength'] || 0;
          this._translate
            .get('FORM.VALIDATION.PASSWORD.POLICY.CHAR_MAX', { count: field.fieldDefinition['maxLength'] })
            .subscribe((res) => {
              field.localeText['maxLength'] = res;
            });
        }
        if (key == 'minimumLength') {
          field.fieldDefinition['minLength'] = this.passwordPolicy['minimumLength'] || field.fieldDefinition['minimumLength'] || 0;
          this._translate
            .get('FORM.VALIDATION.PASSWORD.POLICY.CHAR_MIN', { count: field.fieldDefinition['minLength'] })
            .subscribe((res) => {
              field.localeText['minLength'] = res;
            });
        }
        if (key == 'noOfDigits') {
          field.fieldDefinition['digitsLength'] = this.passwordPolicy['noOfDigits'] || field.fieldDefinition['noOfDigits'] || 0;
          this._translate
            .get('FORM.VALIDATION.PASSWORD.POLICY.CHAR_DIGIT', { count: field.fieldDefinition['digitsLength'] })
            .subscribe((res) => {
              field.localeText['digitsLength'] = res;
            });
        }
        if (key == 'noOfSpecialChars') {
          field.fieldDefinition['specialLength'] =
            this.passwordPolicy['noOfSpecialChars'] || field.fieldDefinition['noOfSpecialChars'] || 0;
          this._translate
            .get('FORM.VALIDATION.PASSWORD.POLICY.CHAR_SPECIAL', { count: field.fieldDefinition['specialLength'] })
            .subscribe((res) => {
              field.localeText['specialLength'] = res;
            });
        }
        if (key == 'lowerAndUpperCase') {
          field.fieldDefinition['lowerupperCase'] = this.passwordPolicy['lowerAndUpperCase'] || false;
          this._translate.get('FORM.VALIDATION.PASSWORD.POLICY.CHAR_LOWER_UPPER').subscribe((res) => {
            field.localeText['lowerupperCase'] = res;
          });
        }
      }
    });
  };

  _getRegistrationFields = (lang?: string): void => {
    let options = {
      acceptlanguage: navigator.language,
      requestId: this.queryParams.requestId,
    };
    if (lang) {
      options.acceptlanguage = lang
    }
    this._loader._start();
    this._cidaasSDK
      ._getRegistrationSetup(options)
      .then((response: any) => {
        this._loader._stop();
        if (response && response.data) {
          let data = response.data.sort(function (a, b) {
            var keyA = a.order,
              keyB = b.order;
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
          this.fields = data;
          this.fields = this._addvalidations(data);
          this._getMissingFields(this.queryParams.view_type);
        } else {
          this.errorMessage = 'ERROR_OCCURRED';
        }
      })
      .catch((error) => {
        console.log(error);
        this._loader._stop();
      });
  };

  _onSubmit = (e) => {
    let header = {
      requestId: this.queryParams.requestId,
      acceptlanguage: navigator.language,
      trackId: this.queryParams.trackId,
    };
    let payload: any = {};
    payload = this._getFieldsValue('SYSTEM', this.missingFields, e);
    payload['customFields'] = this._getFieldsValue('CUSTOM', this.missingFields, e);
    payload['email_verified'] = this.emailVerified ? this.emailVerified : false;
    payload['provider'] = this.provider;
    Object.keys(this.missingfieldsValues).map((key) => {
      if (this.missingfieldsValues[key]) {
        payload[key] = this.missingfieldsValues[key];
      }
    });

    // update social info from social track
    try {
      if (this.socialUserInfo) {
        Object.keys(this.socialUserInfo).map((key) => {
          if (this.socialUserInfo[key]) {
            payload[key] = this.socialUserInfo[key];
          }
        });
      }
    }
    catch (err) {
      console.log(err);
    }
    this._loader._start();
    if (this.queryParams.view_type == 'login') {
      this._cidaasSDK
        ._progressiveRegister(payload, header)
        .then((response: any) => {
          this._loader._stop();
          this.errorMessage = '';
          if (response && response?.data && response['data']['trackId']) {
            let options = { track_id: response['data']['trackId'] };
            this._cidaasSDK.mfaContinue(options);
          } else {
            if (response?.error_description && response?.error_description == 'user with this details already exists') {
              this.errorMessage = 'ERROR.USER_WITH_THIS_DETAILS_ALREADY_EXISTS';
            } else {
              this.errorMessage = 'ERROR.MSG';
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this._loader._stop();
        });
    } else {
      this._cidaasSDK
        ._register(payload, header)
        .then((data) => {
          this._loader._stop();
          if (data) {
            if (data.error_code && data.error_code == 403) {
              this._router.navigate(['/error'], { queryParams: { error_hint: String(data.error).toLocaleUpperCase() }, queryParamsHandling: 'merge' });
            }
            this._handleProgressiveRedirection(data);
          }
        })
        .catch((error) => {
          console.log(error);
          this._loader._stop();
        });
    }
  };

  _getFieldsValue = (fieldType: string, fields: any, formValue: any, groupName?: string) => {
    let customFields: any = {};
    if (groupName) {
      fields.map((field) => {
        if (field['parent_group_id'] == groupName) {
          let key = field['fieldKey'];
          customFields[key] = formValue[key] || null;
        }
      });
    } else {
      fields.map((field) => {
        if (field['fieldType'] == fieldType && field['parent_group_id'] != 'DEFAULT') {
          let groupName = field['parent_group_id'];
          customFields[groupName] = this._getFieldsValue(fieldType, fields, formValue, groupName);
        } else if (field['fieldType'] == fieldType) {
          if (fieldType == 'CUSTOM') {
            let key = field['fieldKey'];
            let obj = {
              dataType: field['dataType'],
              key: field['fieldKey'],
              readOnly: field['readOnly'],
              value: formValue[key] || null,
              lastUpdateFrom: this.provider,
              internal: false,
            };
            customFields[key] = obj;
          } else {
            let key = field['fieldKey'];
            customFields[key] = formValue[key] || null;
          }
        }
      });
    }
    return customFields;
  };

  _handleProgressiveRedirection = (data: any): void => {
    if (data.suggested_action == 'DEDUPLICATION' && data.trackId) {
      this._router.navigate(['/account_deduplication'], {
        queryParams: { requestid: this.queryParams.requestId, trackId: data.trackId, provider: this.provider },
      });
    } else if (data.suggested_action == 'LOGIN' && data.trackId) {

      data.rememberMe = this.rememberMe;
      this._cidaasSDK._loginAfterRegister(data);
    } else {
      if (!this.emailVerified && this.email) {
        this._router.navigate(['/verification_init'], {
          queryParams: { requestid: this.queryParams.requestId, userStatus: data.userStatus, sub: data.sub, provider: this.provider },
        });
      } else {
        this._router.navigate(['/register_success'], { queryParams: { requestid: this.queryParams.requestId } });
      }
    }
  };

  _onReset = (e): void => {
    if (e) {
      this.commonFunc.getRedirectedByRequestIdWithQueryParams(this.queryParams.userid_hint);
    }
  };
}

