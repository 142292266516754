<div>
  <h4 class="header-label">{{ "LOGIN.WELCOME_HEADER" | translate }}</h4>
  <p class="secondary-header-label">
    {{ "COMPLETE_REGISTER.WELCOME_LABEL" | translate }}
  </p>
  <div *ngIf="!isShowError && !isShowNumberError" class="form-group mt-5" style="position: relative">
    <div *ngIf="invalidCredentials">
      <div class="red-alert">
        <img src="assets/img/error-icon.svg" alt="">
        <div class="error-hints">
          <p class="error-header"> {{ "COMPLETE_REGISTER.INVALID_CREDENTIALS" | translate }}</p>
          <p class="error-label"> {{ "COMPLETE_REGISTER.CHECK_CREDENTIALS" | translate }} </p>
        </div>
      </div>
    </div>
    <div class="form-group has-feedback">
      <div>
        <form [formGroup]="firstPageForm" (ngSubmit)="onFormSubmit()">
          <div class="form-container">
            <div class="label-field-div">
              <label for="function" class="input-label">{{
                "COMPLETE_REGISTER.YOUR_FUNCTION" | translate
                }}</label>
              <div class="form-field">
                <ng-select formControlName="function" [searchable]="false" [clearable]="false" (change)="setFunction($event)"
                placeholder="{{ 'COMPLETE_REGISTER.CHOISE_OPTION' | translate }}">
                  <ng-option *ngFor="let function of functions" [value]="function.id">{{ function.function | translate }}</ng-option>
               </ng-select>
                <img class="error-icon-select" *ngIf="f.get('function').invalid && f.get('function').touched"
                  src="assets/img/icon-error.svg" style="width: 20px;" alt="">
                <div *ngIf="f.get('function').invalid && f.get('function').touched" class="errMsg ft-12">
                  {{ "COMPLETE_REGISTER.INVALID_OPTION_MANDATORY" | translate }}
                </div>
              </div>
            </div>
            <div class="label-field-div">
              <label for="mandator" class="input-label">{{
                "COMPLETE_REGISTER.YOUR_INSTITUT" | translate
                }}</label>
              <div class="form-field">

                <ng-select formControlName="mandator" [searchable]="false" [clearable]="false" (change)="setMandator($event)"
                placeholder="{{ 'COMPLETE_REGISTER.CHOISE_OPTION' | translate }}">
                  <ng-option *ngFor="let mandator of mandatorOptions | sortOptions" [value]="mandator">{{ mandator.name | translate}}</ng-option>
               </ng-select>
                <img class="error-icon-select" *ngIf="f.get('mandator').invalid && f.get('mandator').touched"
                  src="assets/img/icon-error.svg" style="width: 20px;" alt="">
                <div *ngIf="f.get('mandator').invalid && f.get('mandator').touched" class="errMsg ft-12">
                  {{ "COMPLETE_REGISTER.INVALID_OPTION_MANDATORY" | translate }}
                </div>
              </div>
            </div>
            <div class="label-field-div">
              <label for="number" class="input-label">{{
                "COMPLETE_REGISTER.YOUR_NUMBER" | translate
                }}</label>
              <div class="form-field">
                <input class="input-field" id="number" formControlName="number" type="string" maxlength="8"
                  placeholder="{{'COMPLETE_REGISTER.NUMBER_PASSWORD_PLACEHOLDER' | translate}}"
                  [ngClass]="{'error-form-control': (f.get('number').invalid && f.get('number').touched)}" />
                <img class="error-icon" *ngIf="f.get('number').invalid && f.get('number').touched"
                  src="assets/img/icon-error.svg" style="width: 20px;" alt="">
                <span *ngIf="
                    f.get('number').invalid &&
                    f.get('number').touched &&
                    !f.get('number').errors?.maxlength
                  " class="errMsg ft-12">
                  {{ "COMPLETE_REGISTER.REQUIRED_FIELD" | translate }}
                </span>
                <span *ngIf="f.get('number').errors?.maxlength && f.get('mandator').value === '09'" class="errMsg ft-12">
                  {{ "COMPLETE_REGISTER.NUMBER_TOO_LONG_TUM" | translate }}
                </span>
                <span *ngIf="f.get('number').errors?.maxlength && f.get('mandator').value != '09'" class="errMsg ft-12">
                  {{ "COMPLETE_REGISTER.NUMBER_TOO_LONG" | translate }}
                </span>
              </div>
            </div>
            <div class="label-field-div">
              <label for="family_name" class="input-label">{{
                "COMPLETE_REGISTER.FAMILY_NAME" | translate
                }}</label>
              <div class="form-field">
                <input class="input-field" id="family_name" formControlName="family_name" type="string"
                  placeholder="{{'COMPLETE_REGISTER.NUMBER_PASSWORD_PLACEHOLDER' | translate}}"
                  [ngClass]="{'error-form-control': f.get('family_name').invalid && f.get('family_name').touched}" />
                <img class="error-icon" *ngIf="f.get('family_name').invalid && f.get('family_name').touched"
                  src="assets/img/icon-error.svg" style="width: 20px;" alt="">
                <div *ngIf="
                    f.get('family_name').invalid && f.get('family_name').touched
                  " class="errMsg ft-12">
                  {{ "COMPLETE_REGISTER.REQUIRED_FIELD" | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="btn-align">
            <button type="submit" [ngClass]="getDisable()" [disabled]="!f.valid">
              {{ "COMPLETE_REGISTER.BUTTON_GOON" | translate }}
              <img *ngIf="f.valid" src="assets/img/active-arrow.svg" alt="">
              <img *ngIf="!f.valid" src="assets/img/deactive-arrow.svg" alt="">
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div>
    <div *ngIf="isShowError || isShowNumberError" class="error-container">
      <h4 *ngIf="isShowError; else showNumberError">
        {{ "COMPLETE_REGISTER.ERROR_MESSAGE" | translate }}
      </h4>
      <ng-template #showNumberError>
        <h4>
          {{ "COMPLETE_REGISTER.ERROR_NUMBER_MESSAGE" | translate }}
        </h4>
      </ng-template>

      <span class="back-btn" (click)="onClose()">{{
        "COMPLETE_REGISTER.BACK_BTN_TEXT" | translate
        }}</span>
    </div>
  </div>
</div>