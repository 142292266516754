import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CidaasService } from 'src/service/cidaas.service';
import { CommonService } from 'src/service/common.service';
import { SharedDataService } from 'src/service/shared-data.service';
import { SharedDataServices } from '../../../dynamic-form/services/shared-data.service'
import { SpinnerService } from 'src/service/spinner.service';

@Component({
  selector: 'app-password-set',
  templateUrl: './password-set.component.html',
  styleUrls: ['./password-set.component.scss'],
})
export class PasswordSetComponent implements OnInit {
  fields: any = [];
  dynamicFields = ['password', 'password_echo'];
  formId;
  resetRequestId = '';
  requestId = '';
  exchangeId = '';
  loginSettingsId = '';
  errorMessage = '';
  passwordPolicy: any;
  isSubmitBtnClicked = false;
  dynamicFormOptions = {
    btnEnabled: false,
  };
  buttonOptions: any = {
    resetPwd: {
      color: 'accent',
      text: 'RESET_PASSWORD',
      alignment: 'right',
      type: 'proceed',
      underline: 'none',
      for: 'cidaasSubmitBtn',
      formId: 'formId'
    }
  }
  validatorsList = [];
  passwordSetForm: FormGroup;
  passwordSetFormValue: FormGroup;
  allowedValidationTypes = [
    'required',
    'minLength',
    'maxLength',
    'pattern',
    'digitsLength',
    'specialLength',
    'lowerupperCase',
    'matchWith',
    'invalidmobile',
  ];
  constructor(
    private translate: TranslateService,
    private cidaasService: CidaasService,
    private activatedRoute: ActivatedRoute,
    private dataService: SharedDataService,
    private sharedService: SharedDataServices,
    public commonFunc: CommonService,
    private formBuilder: FormBuilder,
    private _loader: SpinnerService
  ) {
    this.sharedService._getFormValue().subscribe((data) => {
      this.passwordSetFormValue = data;
    });
  }

  ngOnInit(): void {
    this.initiatePwdResetForm();
    this._getPasswordPolicy();
    this.initQueryParamValues();
    this.getLanguage();
  }
  _getFormId = (e) => {
    this.formId = e;
  };

  _getPasswordPolicy = () => {
    this.dataService._getClientInfo().subscribe((data) => {
      if (data.password_policy) {
        this.passwordPolicy = data.password_policy;
      }
    });
  };

  getLanguage() {
    this.dataService.getLanguage().subscribe((data) => {
      if (data && this.requestId) {
        this._getRegistrationFields(data);
      }
    });
  }

  // initialize query params
  initQueryParamValues() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.requestId = params.requestId || params.requestid;
      this.resetRequestId = params.rprq;
      this.exchangeId = params.exchangeid || params.exchangeId;
      this.loginSettingsId = params.lsid;
      if (this.loginSettingsId) {
        this.initiatePwdResetForm();
        this.passwordSetForm.addControl('password_old', new FormControl('', Validators.required))
      }
      if (params.error) {
        this.translate.get('RESET.OLD_PWD_INCORRECT').subscribe((translationForOldPasswordError) => {
          this.errorMessage = translationForOldPasswordError;
        });
      }
    });
  }

  _getRegistrationFields = (lang?: string) => {
    let options = {
      acceptlanguage: navigator.language,
      requestId: this.requestId,
    };

    if (lang) {
      options.acceptlanguage = lang
    }
    this._loader._start();
    this.cidaasService
      ._getRegistrationSetup(options)
      .then((response: any) => {
        this._loader._stop();
        if (response && response.data) {
          let data = response.data.sort(function (a, b) {
            var keyA = a.order,
              keyB = b.order;
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
          this.fields = data.filter((registerField) => registerField.dataType === 'PASSWORD');
          this.fields.forEach(field => {
            this.translate.get("PASSWORD_SET."+field.fieldKey.toUpperCase()).subscribe((res) => {
              field.localeText.name = res;
            });
          });
          this.fields = this.addValidationForPassword(this.fields);
        } else {
          this.errorMessage = 'ERROR_OCCURRED';
        }
      })
      .catch((error) => {
        console.error(error);
        this._loader._stop();
      });
  };

  initiatePwdResetForm() {
    this.passwordSetForm = this.formBuilder.group({
      password: new FormControl('',
      [
        Validators.required,
        Validators.pattern(
          /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
        ),
        Validators.minLength(8),
      ],),
      password_echo: new FormControl('', Validators.required)
    });
  }

  addValidationForPassword = (fields: any) => {
    if (!fields) {
      this.errorMessage += 'No registration fields were found.\n';
      return;
    }
    const foundPassField = fields.find((field) => !field.fieldDefinition.matchWith);
    if (!foundPassField) {
      this.errorMessage += 'The registration field for password was not found.\n';
      return;
    }
    if (this.passwordPolicy) {
      this._addPasswordPolicy(foundPassField);
    }
    if (this.loginSettingsId) {
      const oldPasswordField = JSON.parse(
        '{"dataType":"PASSWORD","parent_group_id":"DEFAULT","is_group":false,"fieldKey":"password_old","fieldType":"SYSTEM","order":22,"readOnly":false,"required":true,"localeText":{"locale":"en-us","name":"Old Password","required":"Password is required.","language":"en"}}'
      );
      fields.splice(0, 0, oldPasswordField);
    }
    return fields;
  };

  _addPasswordPolicy = (field: any) => {
    let validations = ['lowerAndUpperCase', 'maximumLength', 'minimumLength', 'noOfDigits', 'noOfSpecialChars'];
    Object.keys(this.passwordPolicy).map((key) => {
      if (validations.includes(key)) {
        if (key == 'maximumLength') {
          field.fieldDefinition['maxLength'] = this.passwordPolicy['maximumLength'] || field.fieldDefinition['maxLength'] || 0;
          this.translate
            .get('FORM.VALIDATION.PASSWORD.POLICY.CHAR_MAX', { count: field.fieldDefinition['maxLength'] })
            .subscribe((res) => {
              field.localeText['maxLength'] = res;
            });
        }
        if (key == 'minimumLength') {
          field.fieldDefinition['minLength'] = this.passwordPolicy['minimumLength'] || field.fieldDefinition['minimumLength'] || 0;
          this.translate
            .get('FORM.VALIDATION.PASSWORD.POLICY.CHAR_MIN', { count: field.fieldDefinition['minLength'] })
            .subscribe((res) => {
              field.localeText['minLength'] = res;
            });
        }
        if (key == 'noOfDigits') {
          field.fieldDefinition['digitsLength'] = this.passwordPolicy['noOfDigits'] || field.fieldDefinition['noOfDigits'] || 0;
          this.translate
            .get('FORM.VALIDATION.PASSWORD.POLICY.CHAR_DIGIT', { count: field.fieldDefinition['digitsLength'] })
            .subscribe((res) => {
              field.localeText['digitsLength'] = res;
            });
        }
        if (key == 'noOfSpecialChars') {
          field.fieldDefinition['specialLength'] =
            this.passwordPolicy['noOfSpecialChars'] || field.fieldDefinition['noOfSpecialChars'] || 0;
          this.translate
            .get('FORM.VALIDATION.PASSWORD.POLICY.CHAR_SPECIAL', { count: field.fieldDefinition['specialLength'] })
            .subscribe((res) => {
              field.localeText['specialLength'] = res;
            });
        }
        if (key == 'lowerAndUpperCase') {
          field.fieldDefinition['lowerupperCase'] = this.passwordPolicy['lowerAndUpperCase'] || false;
          this.translate.get('FORM.VALIDATION.PASSWORD.POLICY.CHAR_LOWER_UPPER').subscribe((res) => {
            field.localeText['lowerupperCase'] = res;
          });
        }
      }
    });
  };

  _triggerFormSubmit = () => {
    this.isSubmitBtnClicked = true;
    setTimeout(() => {
      this.isSubmitBtnClicked = false;
    });
  };

  // call reset password with password and confir, password
  sendResetPassword(e) {
    let resetPayload: any = {};
    if (this.loginSettingsId) {
      this.callCidaasFirstTimeChangePassword(resetPayload, e);
    } else {
      this.callCidaasResetPassword(resetPayload, e);
    }
  }

  // call first time reset password with new, old and confirm pwd
  callCidaasFirstTimeChangePassword(resetPayload: any, e: any) {
    resetPayload = {
      loginSettingsId: this.loginSettingsId,
      new_password: this.passwordSetFormValue.get('password').value,
      old_password: this.passwordSetFormValue.get('password_old').value,
      confirm_password: this.passwordSetFormValue.get('password_echo').value,
    };
    this.cidaasService.firstTimeChangePassword(resetPayload);
  }

  public callCidaasResetPassword(resetPayload: any, e: any) {
    resetPayload = {
      resetRequestId: this.resetRequestId,
      exchangeId: this.exchangeId,
      password: this.passwordSetFormValue.get('password').value,
      confirmPassword: this.passwordSetFormValue.get('password_echo').value,
    };
    this.cidaasService.resetPassword(resetPayload);
  }

  getDisable() {
    if (!this.passwordSetFormValue.valid) {
      return 'transparent-btn1';
    } else {
      return 'custom_primary_btn1';
    }
  }

}
