<div>
  <h4 class="header-label">{{ "LOGIN.WELCOME_HEADER" | translate }}</h4>
  <p class="secondary-header-label">
    {{ "LOGIN.WELCOME_LABEL" | translate }}
  </p>

  <p class="secondary-header-label">
    <strong>{{ "LOGIN.REGISTRATION.NOTE_LABEL" | translate }}</strong>
    {{ "LOGIN.REGISTRATION.NOTE_HINT" | translate }}
  </p>
  <div class="label-field-div">
    <app-input-group [options]="inputGroupOption" (btnEvent)="_onSubmit()" [btnType]="'submit'" [inputType]="'text'"
      (updateUser)="_updateUsername($event)">
    </app-input-group>
  </div>
  <div class="btn-align">
    <div>
      <button type="submit" (click)=_onSubmit() [ngClass]="getDisable()"
        [disabled]="(!isSubmitted && inputGroupOption.input.value == '')">
        {{ "LOGIN.BUTTON_GOON" | translate }}
        <img *ngIf="(!isSubmitted && inputGroupOption.input.value)" src="assets/img/active-arrow.svg" alt="">
        <img *ngIf="!(!isSubmitted && inputGroupOption.input.value)" src="assets/img/deactive-arrow.svg" alt="">
      </button>
    </div>

  </div>
  <hr>
  <div class="password-part">
    <p class="secondary-part-header-label">
      {{ "LOGIN.REGISTRATION.HEADING" | translate }}
    </p>

    <p class="secondary-header-label">
      {{ "LOGIN.TO_REGISTRATION" | translate }}
    </p>
    <div class="btn-align">
      <div>
        <button class="custom-primary-btn" type="button" (click)=_openRegistration($event)>
          {{ "LOGIN.REGISTRATION.BUTTON_REGISTRATION" | translate }}

          <img src="assets/img/active-arrow.svg" alt="">
        </button>
      </div>

    </div>
  </div>
</div>
