<div>
    <h4 class="header-label">
        {{'REGISTER_ADDITIONAL_INFO.HEADER' | translate}}</h4>
    <p class="secondary-header-label">{{'REGISTER_ADDITIONAL_INFO.SUB_HEADER' | translate}}</p>
    <dynamic-form [fields]=missingFields [options]=dynamicFormOptions [appearance]="'standard'"
        (onSubmit)="_onSubmit($event)" (onReset)="_onReset($event)"></dynamic-form>

    <div class="text-danger">{{errorMessage | translate}} </div>

    <div class="input-fields-container" id="submit-container">
        <button type="submit" class="submit-btn"><label
                for="cidaasSubmitBtn">{{"REGISTER_ADDITIONAL_INFO.SUBMIT"| translate}}
            </label></button>
    </div>
</div>