import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CompleteRegisterService } from 'src/service/complete-register.service';
import { SpinnerService } from 'src/service/spinner.service';

@Component({
  selector: 'app-manage-login',
  templateUrl: './manage-login.component.html',
  styleUrls: ['./manage-login.component.scss']
})
export class ManageLoginComponent implements OnInit {

  kost_terms: boolean = true;
  gingco_terms: boolean = true;
  bildungscampus_terms: boolean = true;
  userDetails: any;
  isAgree: boolean = false;
  isAgreeGingcoTerms: boolean = false;
  isContinueBtnDisabled: boolean = true;
  isContinueGingcoBtnDisabled: boolean = true;
  isrevokeConsentContinueBtnDisabled: boolean = true;
  isrevokeGingcoConsentContinueBtnDisabled: boolean = true;
  constructor(private complete_register: CompleteRegisterService, private _loader: SpinnerService) { }

  async ngOnInit() {
    const urlParams = new URLSearchParams(window.location.hash.substring(1));
    let token = urlParams.get('access_token')
    if (token == '' || token == 'undefined' || token == null) {
      token = localStorage.getItem('access_token');
    }
    this.complete_register.setAccessToken(token);
    this.getUserInfo();
  }

  handleChange(value: boolean) {
    this.kost_terms = value;
    this.isAgree = true;
  }

  handleGingcoTermsChange(value: boolean) {
    this.gingco_terms = value;
    this.isAgreeGingcoTerms = true;
  }

  onConsentClick(value) {
    if (value.target.checked == true) {
      this.isContinueBtnDisabled = false;
    }
    else {
      this.isContinueBtnDisabled = true;
    }
  }

  onGingcoTermsConsentClick(value) {
    if (value.target.checked == true) {
      this.isContinueGingcoBtnDisabled = false;
    }
    else {
      this.isContinueGingcoBtnDisabled = true;
    }
  }

  onRevokeConsentClick(value, type) {
    if (type == 'gingco_terms') {
      this.isrevokeGingcoConsentContinueBtnDisabled = !value.target.checked;
    } else {
      this.isrevokeConsentContinueBtnDisabled = !value.target.checked;
    }
  }

  setConsent(value: boolean, terms: boolean, type: string) {
    this._loader._start();
    if (value) {
      let consentObject: any = {};
      consentObject.customFields = {}
      consentObject.provider = 'self';
      consentObject.sub = this.userDetails.sub
      consentObject.customFields[type] = terms;
      this.complete_register.updateProfile(consentObject).subscribe(data => {
        this._loader._stop();
        if (type == 'gingco_terms') {
          this.isAgreeGingcoTerms = false;
          this.isContinueGingcoBtnDisabled = true;
          this.isrevokeGingcoConsentContinueBtnDisabled = true
          if (data) {
            if (!this.gingco_terms) {
              this.revokeKostTerm();
            }
          }
        } else {
          this.isAgree = false;
          this.isContinueBtnDisabled = true;
          this.isrevokeConsentContinueBtnDisabled = true;
          if (data) {
            if (!this.kost_terms) {
              this.revokeKostTerm();
            }
          }
        }
        this.getUserInfo();
      })
    } else {
      if (type == 'gingco_terms') {
        this.isContinueGingcoBtnDisabled = true;
        this.isrevokeGingcoConsentContinueBtnDisabled = true
        this.isAgreeGingcoTerms = false;
      } else {
        this.isContinueBtnDisabled = true;
        this.isrevokeConsentContinueBtnDisabled = true;
        this.isAgree = false;
      }
      this.getUserInfo();
    }
    this._loader._stop();
  }

  revokeKostTerm() {
    let object: any = {};
    object.sub = this.userDetails.sub
    this.complete_register.revokeKostTerm(object).subscribe(data => {
      if (data.status != 200) {
      }
    })
  }

  checkTerms(event) {
    console.log("event", event)
  }

  getUserInfo() {
    this._loader._start();
    this.complete_register.getUserInfo().then((data: any) => {
      this._loader._stop();
      if (data) {
        this.userDetails = data;
        if (this.userDetails && this.userDetails?.customFields) {
          if (Object.keys(this.userDetails.customFields?.kost_terms)) {
            this.kost_terms = this.userDetails.customFields.kost_terms
          }
          if (this.userDetails.customFields && this.userDetails.customFields.hasOwnProperty('gingco_terms')) {
            this.gingco_terms = this.userDetails.customFields.gingco_terms
          }
          if (Object.keys(this.userDetails.customFields?.bildungscampus_terms)) {
            this.bildungscampus_terms = this.userDetails.customFields.bildungscampus_terms;
          }
        }
      }
    }, error => {
      this._loader._stop();
    })
  }

  redirectToAtlassian() {
    window.location.assign(
      environment.atlassian_url
    );
  }
}
